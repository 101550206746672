import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { Typography, Box } from "@mui/material";
import throttle from "lodash/throttle";
import { UserService } from "../services/UserServices";
import MuiTextField from "./MuiTextField";
import { HorseService } from "../services/HorseServices";
import { Button, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { RacesService } from "../services/RecesServices";
function DevAutoCompleteUser(props) {
  const {
    role,
    ownership,
    updateformik,
    placeholder,
    owners,
    raceOwners,
    disabled,
    currentSyndicateId,
    assistantTrainer,
    validateField,
  } = props;
  const [persentValue, setPersentValue] = React.useState(null);

  const [value, setValue] = React.useState("");
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const handelAddOwner = () => {
    if (raceOwners) {
      if (value && value.id > 0) {
        let newowners = [...[{ ...value, procent: persentValue }], ...owners];
        updateformik("owners", newowners);
        setValue(null);
      }
    } else {
      if (value && value.id > 0 && parseInt(persentValue) > 0) {
        //&& (parseInt(persentValue) + totalPersent <= 100)
        let newowners = [...[{ ...value, procent: persentValue }], ...owners];
        updateformik("owners", newowners);
        updateformik(
          "totalShareHolding",
          newowners.reduce((a, b) => +a + +b.procent, 0)
        );

        setValue(null);
        setPersentValue(0);
      }
    }
  };
  const updatePersentage = (value, item) => {
    const index = owners.findIndex((emp) => emp.id === item.id);
    let newOwners = [...owners];
    newOwners[index] = { ...item, procent: parseInt(value) };
    updateformik("owners", newOwners);
    updateformik(
      "totalShareHolding",
      newOwners.reduce((a, b) => +a + +b.procent, 0)
    );
  };
  const handleRemoveOwner = (item) => {
    let newOwners = owners.filter((i) => ![item].find((f) => f.id === i.id));
    updateformik("owners", newOwners);
    updateformik(
      "totalShareHolding",
      newOwners.reduce((a, b) => +a + +b.procent, 0)
    );
  };

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        role !== "owner"
          ? role === "ownerSelect"
            ? HorseService.getAllOwnersByName(request, callback)
            : role === "horse"
            ? RacesService.getHorseByName(request, callback)
            : UserService.getUserListByName(request, callback)
          : HorseService.getAllOwnersByName(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    if (props.driver && props.driver?.id > 0) {
      setValue(props.driver);
    }
    if (props.trainer && props.trainer?.id > 0) {
      setValue(props.trainer);
    }
    if (props.breeder && props.breeder?.id > 0) {
      setValue(props.breeder);
    }
    if (props.ownerSelect && props.ownerSelect?.id > 0) {
      setValue(props.ownerSelect);
    }
    if (props.horse && props.horse?.id > 0) {
      setValue(props.horse);
    }
  }, [
    props.driver,
    props.trainer,
    props.breeder,
    props.ownerSelect,
    props.horse,
  ]);

  React.useEffect(() => {
    let active = true;

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch(
      role !== "owner"
        ? role === "ownerSelect"
          ? "?term=" + inputValue
          : role === "horse"
          ? "?name=" + inputValue
          : "?term=" + inputValue + "&role=" + role
        : "?term=" + inputValue,
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results.success) {
            let filterarry = results.data.filter(
              (i) => !owners?.find((f) => f.id === i.id)
            );
            if (props.source === 2 && currentSyndicateId > 0) {
              var index = filterarry.find(
                (x) => x.id === currentSyndicateId && x.type === "Syndicate"
              );
              filterarry = filterarry.filter((i) => i.id !== index?.id);
            }
            newOptions = [...newOptions, ...filterarry];
          }
          if (assistantTrainer) {
            validateField(assistantTrainer);
          }
          if (role === "horse") {
            setOptions(
              newOptions &&
                newOptions.map((item, i) => {
                  return { id: item?.horse?.id, name: item?.horse?.name };
                })
            );
          } else {
            setOptions(newOptions);
          }
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <>
      {role === "owner" && (
        <Grid item container xs={12} spacing={2}>
          {owners &&
            owners.map((ownerItem, i) => (
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                justifyItems={"center"}
                item
                xl={props.source === 3 && 5}
                lg={raceOwners ? 12 : 6}
                md={raceOwners ? 12 : 6}
                xs={12}
                spacing={2}
                key={ownerItem.id}
              >
                <Grid item xs={raceOwners ? 10 : 6} className="fullWidth">
                  <Typography sx={{ minWidth: 250, width: "100%" }}>
                    {ownerItem.name}
                  </Typography>
                </Grid>
                {!props.raceOwners && (
                  <Grid item xs={3} className="fullWidth">
                    {props.source !== 1 ? (
                      <MuiTextField
                        sx={{ width: 100 }}
                        value={ownerItem.procent}
                        InputProps={{ inputProps: { min: 0, max: 100 } }}
                        type="number"
                        onChange={(e, newvalue) => {
                          var value = parseInt(e.target.value, 0);

                          //setPersentValue(value < 0 && value > 100 ? value : 0);
                          updatePersentage(
                            Number.isInteger(value) ? value : "",
                            ownerItem
                          );
                        }}
                        unittext={"%"}
                      />
                    ) : (
                      ownerItem.procent + "%"
                    )}
                  </Grid>
                )}
                {props.source !== 1 && (
                  <Grid
                    item
                    sx={raceOwners ? 2 : 1}
                    sm={raceOwners ? 2 : 1}
                    md={raceOwners ? 2 : 1}
                    className="fullWidth"
                  >
                    <IconButton onClick={(e) => handleRemoveOwner(ownerItem)}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>
      )}

      {props.source !== 1 && (
        <Grid
          item
          xs={props.addOwner ? 12 : props.raceOwners ? 10 : 3}
          className="fullWidth"
        >
          {props.source !== 1 && ownership === "ownership" && (
            <Typography>Owner*</Typography>
          )}
          <Autocomplete
            size="small"
            id="UsersAutocomplete"
            sx={{ minWidth: props.width ? props.width : 250, width: "100%" }}
            getOptionLabel={(option) =>
              typeof option === "string" ? option : option.name
            }
            filterOptions={(x) => x}
            options={options}
            disabled={disabled}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
              setOptions(newValue ? [newValue, ...options] : options);
              setValue(newValue);
              if (assistantTrainer) {
                updateformik(
                  assistantTrainer,
                  newValue && newValue ? newValue : ""
                );
              } else {
                role !== "owner" && updateformik(role, newValue);
                role !== "owner" && !newValue && updateformik(role + ".id", 0);
              }
            }}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            renderInput={(params) => (
              <MuiTextField {...params} fullWidth placeholder={placeholder} />
            )}
          />
        </Grid>
      )}
      {props.source !== 1 && role === "owner" && (
        <>
          {!props.raceOwners && (
            <Grid item className="fullWidth">
              <Box sx={{ mt: ownership === "ownership" ? 3 : "" }}>
                <MuiTextField
                  sx={{ width: 100 }}
                  value={persentValue}
                  onChange={(e, newvalue) => {
                    var value = parseInt(e.target.value, 0);
                    setPersentValue(Number.isInteger(value) ? value : "");
                  }}
                  type="number"
                  unittext={"%"}
                />
              </Box>
            </Grid>
          )}

          <Grid item className="fullWidth">
            <Box sx={{ mt: ownership === "ownership" ? 3 : "" }}>
              <Button onClick={handelAddOwner} variant="outlined">
                Add
              </Button>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
}
export default DevAutoCompleteUser;
