import {
  Fade,
  Grid,
  IconButton,
  Modal,
  Avatar,
  Typography
} from '@mui/material';
import { useRef, useState } from 'react';
import endpoints from '../constants/endpoints';
import { allowedExtensions } from '../helpers/util';
import { DownloadIcon } from './icons/DownloadIcon';
import { UploadIcon } from './icons/UploadIcon';
import { makeStyles } from '@mui/styles';
import ToastConfig from './toast/toast';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'right'
  },
  imgDiv: {
    height: '400px',
    width: '401px',
    '& img': {
      objectFit: 'cover'
    }
  }
}));
function DevFileOptions(props) {
  const {
    updateFormik,
    item,
    fieldValue,
    drivre,
    fileType,
    lableLeftRight,
    labelText,
    disabled
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
console.log(disabled)
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageRef = useRef();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState();

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };
  const uploadFile = (e) => {
    if (!allowedExtensions.exec(e.target.value)) {
      return ToastConfig.error('Selected file type not supported');
    }
    e.preventDefault();
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);

      let data = new FormData();
      data.append('fileName', e.target.files[0].name);
      data.append('FormFile', e.target.files[0]);
      data.append('fileType', fileType);

      fetch(endpoints.UPLOAD.SAVE, {
        method: 'post',
        body: data
      })
        .then((response) => response.text())
        .then((result) => {
          !lableLeftRight
            ? updateFormik(fieldValue, result)
            : updateFormik(
                !drivre
                  ? item.userType + '.licenseUrl'
                  : item.userType + '.coloursUrl',
                result
              );
        })
        .catch((error) => console.log('error', error));
    }
  };

  const getDataFromURL = (url) => {
    const filename = url.split('/').pop();
    return fetch(url, {
      headers: new Headers({
        Accept: 'application/octet-stream'
      })
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
      });
  };

  return (
    <>
      {lableLeftRight ? (
        <>
          <Grid
            item
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            spacing={2}
            xs={12}
          >
            <Grid
              item
              container
              alignItems='center'
              justifyContent='flex-start'
              spacing={2}
              xs={4}
            >
              <Grid item xs={12}>
                {' '}
                {props.drivre ? 'Driver Colour*' : 'Licence'}{' '}
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems='center'
              justifyContent='space-evenly'
              spacing={2}
              xs={8}
            >
              <Grid item xs={2}>
                <IconButton onClick={handleOpen}>
                  {props.drivre
                    ? item.coloursUrl && (
                        <Avatar src={item.coloursUrl} variant='square'></Avatar>
                      )
                    : item.licenseUrl && (
                        <Avatar src={item.licenseUrl} variant='square'></Avatar>
                      )}
                </IconButton>
              </Grid>
              <Grid item xs={2}>
                <IconButton  disabled={disabled} onClick={showOpenFileDialog}>
                  <UploadIcon />
                </IconButton>
                <input
                  ref={imageRef}
                  type='file'
                  style={{ display: 'none' }}
                  accept='image/png,image/jpeg,application/pdf'
                  onChange={uploadFile}
                />
              </Grid>
              <Grid item xs={2}>
                {!drivre && item.licenseUrl && (
                  <IconButton onClick={(e) => getDataFromURL(item.licenseUrl)}>
                    <DownloadIcon />
                  </IconButton>
                )}
                {drivre && item.coloursUrl && (
                  <IconButton onClick={(e) => getDataFromURL(item.coloursUrl)}>
                    <DownloadIcon />
                  </IconButton>
                )}
              </Grid>
            </Grid>
            <Modal
              aria-labelledby='transition-modal-title'
              aria-describedby='transition-modal-description'
              className={classes.modal}
              open={open}
              onClose={handleClose}
              closeAfterTransition
              sx={{ maxHeight: '50vh', top: '50px' }}
            >
              <Fade in={open}>
                <div className={classes.paper}>
                  <Grid item xs={12}>
                    <Typography
                      sx={{ cursor: 'pointer' }}
                      onClick={handleClose}
                    >
                      <CloseIcon />
                    </Typography>
                  </Grid>
                  <div className={classes.imgDiv}>
                    <img
                      width={'100%'}
                      height={'100%'}
                      src={drivre ? item.coloursUrl : item.licenseUrl}
                    />
                  </div>
                </div>
              </Fade>
            </Modal>
          </Grid>
        </>
      ) : (
        <>
          {labelText === 'DOWNLOAD RACE CARD' ? labelText : ''}
          <IconButton onClick={showOpenFileDialog}>
            <UploadIcon />
          </IconButton>{' '}
          {item && (
            <IconButton onClick={(e) => getDataFromURL(item)}>
              {' '}
              <DownloadIcon />
            </IconButton>
          )}
          {props.textRight && props.textRight && <>{labelText ? labelText : ''}</>}
          <input
            ref={imageRef}
            type='file'
            style={{ display: 'none' }}
            accept='image/png,image/jpeg,application/pdf'
            onChange={uploadFile}
          />
        </>
      )}
    </>
  );
}

export default DevFileOptions;
