import React from 'react';
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import Geocode from 'react-geocode';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Box } from '@mui/material';
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
Geocode.enableDebug();
const options = {
  disableDefaultUI: true,
  zoomControl: true
};
const useStyles = makeStyles((theme) => ({
  autocomplete: {
    backgroundColor: '#fff',
    fontSize: '15px',
    padding: '0 11px 0 13px',
    textOverflow: 'ellipsis',
    width: '50%',
    maxWidth: '500px',
    borderRadius: '4px',
    zIndex: '999',
    position: 'absolute',
    left: '18px',
    top: '60px'
  },
  searchInput: {
    backgroundColor: '#fff',
    height: '40px',
    fontSize: '15px',
    padding: '0 11px 0 13px',
    textOverflow: 'ellipsis',
    width: '50%',
    maxWidth: '500px',
    borderRadius: '4px',
    zIndex: '999',
    position: 'absolute',
    left: '15px',
    top: '15px'
  }
}));
function MapModel(props) {
  const { handelMapClose, updateformik } = props;
  const [mapPosition, setMapPosition] = React.useState({
    lat: 54.237933,
    lng: -2.36967
  });
  const [markerPosition, setMarkerPosition] = React.useState({
    lat: 54.237933,
    lng: -2.36967
  });
  const [pos, setPos] = React.useState({
    lat: 54.237933,
    lng: -2.36967
  });
  const classes = useStyles();
  const [address, setAddress] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [country, setCountry] = React.useState('');
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });
  React.useEffect(() => {
    let pos = { lat: 54.237933, lng: -2.36967 };
    if (pos.lat) {
      pos = { lat: 54.237933, lng: -2.36967 };
      setMapPosition(pos);
      setMarkerPosition(pos);
    }
    if (pos.lat) {
      Geocode.fromLatLng(pos.lat, pos.lng).then(
        (response) => {
          let country = '';
          let postalCode = '';
          const address = response.results[0].formatted_address;
          const addressArray = response.results[0].address_components;
          for (let i = 0; i < addressArray.length; i++) {
            if (
              addressArray[i].types[0] &&
              'country' === addressArray[i].types[0]
            ) {
              country = addressArray[i].long_name;
            }
          }
          for (let i = 0; i < addressArray.length; i++) {
            if (
              addressArray[i].types[0] &&
              'postal_code' === addressArray[i].types[0]
            ) {
              postalCode = addressArray[i].long_name;
            }
          }
          setMapPosition({ lat: pos.lat, lng: pos.lng });
          setMarkerPosition({ lat: pos.lat, lng: pos.lng });
          setAddress(address ? address : '');
          setPostalCode(postalCode ? postalCode : '');
          setCountry(country ? country : '');
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [pos]);
  const onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        let country = '';
        let postalCode = '';
        const address = response.results[0].formatted_address;
        const addressArray = response.results[0].address_components;
        for (let i = 0; i < addressArray.length; i++) {
          if (
            addressArray[i].types[0] &&
            'country' === addressArray[i].types[0]
          ) {
            country = addressArray[i].long_name;
          }
        }
        for (let i = 0; i < addressArray.length; i++) {
          if (
            addressArray[i].types[0] &&
            'postal_code' === addressArray[i].types[0]
          ) {
            postalCode = addressArray[i].long_name;
          }
        }
        setMapPosition({ lat: newLat, lng: newLng });
        setMarkerPosition({ lat: newLat, lng: newLng });
        setAddress(address ? address : '');
        setPostalCode(postalCode ? postalCode : '');
        setCountry(country ? country : '');
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleSelect = (address) => {
    setAddress(address);
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        console.log('Success', latLng);

        Geocode.fromLatLng(latLng.lat, latLng.lng).then(
          (response) => {
            let country = '';
            let postalCode = '';
            const address = response.results[0].formatted_address;
            const addressArray = response.results[0].address_components;
            for (let i = 0; i < addressArray.length; i++) {
              if (
                addressArray[i].types[0] &&
                'country' === addressArray[i].types[0]
              ) {
                country = addressArray[i].long_name;
              }
            }
            for (let i = 0; i < addressArray.length; i++) {
              if (
                addressArray[i].types[0] &&
                'postal_code' === addressArray[i].types[0]
              ) {
                postalCode = addressArray[i].long_name;
              }
            }
            setAddress(address ? address : '');
            setPostalCode(postalCode ? postalCode : '');
            setCountry(country ? country : '');
          },
          (error) => {
            console.error(error);
          }
        );
        setMapPosition(latLng);
        setMarkerPosition(latLng);
      })
      .catch((error) => console.error('Error', error));
  };
  const handleChange = (address) => {
    setAddress(address);
  };

  //... Location Update API ...//
  const updateLatitudeAndLongitude = () => {
    console.log(markerPosition.lat, markerPosition.lng);
    updateformik('lat', markerPosition.lat);
    updateformik('lng', markerPosition.lng);
    updateformik('address', address);
    updateformik('country', country);
    updateformik('postalCode', postalCode);

    handelMapClose();
  };
  return isLoaded ? (
    <div className='map-container'>
      <Grid
        constainer
        direction={'column'}
        style={{ display: 'flex', height: '100%', width: '100%' }}
        spacing={2}
      >
        <Box className={'dummy-auto'} sx={{ position: 'relative' }}>
          <PlacesAutocomplete
            value={address}
            onChange={handleChange}
            onSelect={handleSelect}
            className='map-input'
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <div>
                <input
                  {...getInputProps({
                    placeholder: 'Search Places ...',
                    className: classes.searchInput
                  })}
                />
                <div className={classes.autocomplete}>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    const style = suggestion.active
                      ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                      : { backgroundColor: '#ffffff', cursor: 'pointer' };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
        </Box>
        <GoogleMap
          mapContainerStyle={{
            height: '350px',
            width: '100%'
          }}
          options={options}
          zoom={5}
          center={{
            lat: mapPosition.lat,
            lng: mapPosition.lng
          }}
        >
          <Marker
            draggable={true}
            onDragEnd={onMarkerDragEnd}
            position={{
              lat: markerPosition.lat,
              lng: markerPosition.lng
            }}
          />
        </GoogleMap>
        <Button sx={{ mt: 3 }} onClick={updateLatitudeAndLongitude}>
          Select
        </Button>
      </Grid>
    </div>
  ) : (
    <></>
  );
}

export default MapModel;
