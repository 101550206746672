import React, { useEffect, useState } from 'react';
import {
  Button,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery
} from '@mui/material';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiTextField from '../../components/MuiTextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import endpoints from '../../constants/endpoints';
import ToastConfig from '../../components/toast/toast';
import { TrackService } from '../../services/TrackServices';
import { useDropzone } from 'react-dropzone';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import MapModel from '../../components/MapModel';
import CloseIcon from '@mui/icons-material/Close';
import paths from '../../routing/paths';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import DevFileOptions from '../../components/DevFileOptions';
import { styled } from '@mui/system';
import { PlayIcon } from '../../components/icons/PlayIcon';
import { useAuth } from '../../context/UserContext';

const Container = styled('div')({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '2px',
  borderRadius: '2px',
  borderColor: '${props => getColor(props)}',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
});
const TrackSurface = ['Hard', 'Grass', 'All Weather'];

const TrackDetails = (props) => {
  const { handleClose } = props;
  const [track, setTrack] = useState(null);
  const { currentItem, id } = props;
  const history = useHistory();
  const { SearchName } = useAuth();
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down('sm') ?? '600'
  );
  const {
    acceptedFiles,
    isDragActive,
    isDragAccept,
    isDragReject,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    accept: 'image/jpeg, image/png'
  });
  const handelSeeMeetings = () => {
    if (currentItem && currentItem.name) {
      SearchName(currentItem.name);
      history.push(paths.MEETING_LIST);
    }
  };
  const [openMapDialog, setOpenMapDialog] = useState(false);

  const handelMapOpen = (e) => {
    setOpenMapDialog(true);
  };
  const handelMapClose = (e) => {
    setOpenMapDialog(false);
  };

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      let data = new FormData();
      data.append('fileName', acceptedFiles[0].name);
      data.append('FormFile', acceptedFiles[0]);
      data.append('fileType', 5);
      let uploadRequest = fetch(endpoints.UPLOAD.SAVE, {
        method: 'post',
        body: data
      })
        .then((response) => response.text())
        .then((result) => {
          setFieldValue('pictureUrl', result);
        })
        .catch((error) => console.log('error', error));
    }
  }, [acceptedFiles]);
  useEffect(() => {
    console.log(currentItem);
    console.log(id);
    loadData(props.id);

    return () => {
      resetForm();
    };
  }, [currentItem, id]);
  useEffect(() => {
    if (track) {
      setFieldValue('id', track?.id ? track?.id : 0);
      setFieldValue('name', track?.name ? track?.name : '');
      setFieldValue('details', track?.details ? track?.details : '');
      setFieldValue('address', track?.address ? track?.address : '');
      setFieldValue('lat', track?.lat ? track?.lat : '');
      setFieldValue('lng', track?.lng ? track?.lng : '');
      setFieldValue(
        'abbrevitation',
        track?.abbrevitation ? track?.abbrevitation : ''
      );
      setFieldValue('country', track?.country ? track?.country : '');
      setFieldValue('postalCode', track?.postalCode ? track?.postalCode : '');
      setFieldValue('surface', track?.surface ? track?.surface : '');
      setFieldValue(
        'circumference',
        track?.circumference ? track?.circumference : 0
      );
      setFieldValue('maxOnGate', track?.maxOnGate ? track?.maxOnGate : 0);
      setFieldValue('pictureUrl', track?.pictureUrl ? track?.pictureUrl : '');
      setFieldValue(
        'contactPersonName',
        track?.contactPersonName ? track?.contactPersonName : ''
      );
      setFieldValue(
        'contactPersonEmail',
        track?.contactPersonEmail ? track?.contactPersonEmail : ''
      );
      setFieldValue(
        'contactPersonPhone',
        track?.contactPersonPhone ? track?.contactPersonPhone : ''
      );
      setFieldValue(
        'documentUrl',
        track?.documentUrl ? track?.documentUrl : ''
      );
    }
  }, [track]);

  const loadData = (id) => {
    TrackService.getById(id).then((response) => {
      if (response.success) {
        setTrack(response.data);
      } else {
        props.id !== 0 && ToastConfig.error('Could Not get Track Details');
      }
    });
  };

  const validationSchema = yup.object({
    address: yup
      .string('Enter your Address')
      .min(3, 'Address must be at least 3 characters long')
      .required('Address is required'),
    name: yup
      .string('Enter Name')
      .min(3, 'Name must be at least 3 characters long')
      .required('Name is required'),
    details: yup
      .string('Enter Details')
      .min(3, 'Details must be at least 3 characters long')
      .required('Details are required'),
    abbrevitation: yup
      .string('Enter Abbrevitation')
      .required('Abbrevitation is required'),
    country: yup.string('Enter Country').required('Country is required'),
    postalCode: yup.string('Enter PostCode').required('PostCode is required'),
    surface: yup.string('Enter Surface').required('Surface is required'),
    lat: yup.string('Enter Latatude').required('Coordinates are  required'),
    lng: yup.string('Enter Longitude').required('Coordinates are required'),
    circumference: yup
      .number('Enter Circumference')
      .min(1, 'Circumference must be at least 1')
      .required('Circumference is required'),
    maxOnGate: yup
      .number('Enter MaxOnGate')
      .min(1, 'Max on Gate must be at least 1')
      .required('MaxOnGate is required'),
    contactPersonName: yup
      .string('Enter Contact Name')
      .min(3, 'Contact Name must be at least 3 characters long')
      .required('Contact Name is required'),
    contactPersonEmail: yup
      .string('Enter your email address')
      .email('Please enter a valid email')
      .required('Email is required'),
    contactPersonPhone: yup
      .string('Enter Phone Number')
      .required('Phone Number is required')
      .matches(
        '^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{2,6}$',
        'Please enter a valid phone number'
      )
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    resetForm,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: 0,
      name: '',
      details: '',
      address: '',
      abbrevitation: '',
      lat: '',
      lng: '',
      country: '',
      postalCode: '',
      surface: '',
      circumference: 0,
      maxOnGate: 0,
      documentUrl: '',
      pictureUrl: '',

      contactPersonName: '',
      contactPersonEmail: '',
      contactPersonPhone: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values);
      let data = values;
      TrackService.save(data)
        .then((response) => {
          if (response.success) {
            ToastConfig.success('Track successfully saved');
            history.push(paths.TRACK_LIST);
            handleClose();
          } else {
            let statuscode = response.statusCode;
            ToastConfig.error(statuscode);
          }
        })
        .catch((error) => {
          console.error(error.message);
          ToastConfig.error(error);
        });
    }
  });

  return (
    <>
      <form onSubmit={handleSubmit} id='TrackAddEditForm'>
        <Grid
          container
          direction='row'
          justifyContent='center'
          alignItems='center'
          spacing={2}
        >
          <Grid item md={6} spacing={2}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                className='fullWidth'
              >
                {' '}
                <Typography>
                  <b>DETAILS</b>{' '}
                </Typography>
              </Grid>
              <Grid item xs={6} className='fullWidth'>
              {props.tabValue === 1 ? (
                ''
              ) : (
                <>
                  {currentItem && currentItem.name && (
                    <Typography
                      onClick={handelSeeMeetings}
                      sx={{
                        cursor: 'pointer',
                        position: 'relative',
                        textAlign: props.matches ? 'left' : 'right',
                        marginRight: '25px'
                      }}
                    >
                      {' '}
                      See the Meetings{' '}
                      <span
                        style={{
                          position: 'absolute',
                          padding: '2px 5px'
                        }}
                      >
                        <PlayIcon />
                      </span>
                    </Typography>
                  )}
                </>
              )}
              </Grid>

              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Name'}*</Typography>
                <MuiTextField
                  name='name'
                  id='name'
                  placeholder={'Albany'}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Details'}*</Typography>
                <MuiTextField
                  name='details'
                  id='details'
                  placeholder={'234124888785WQAQKK'}
                  value={values.details}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.details && Boolean(errors.details)}
                  helperText={touched.details && errors.details}
                />
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Abbrevitation'}*</Typography>
                <MuiTextField
                  name='abbrevitation'
                  id='abbrevitation'
                  placeholder={'AL'}
                  value={values.abbrevitation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.abbrevitation && Boolean(errors.abbrevitation)}
                  helperText={touched.abbrevitation && errors.abbrevitation}
                />
              </Grid>

              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Surface'}*</Typography>
                <FormControl sx={{ width: '100%' }}>
                  <Select
                    autoWidth={true}
                    displayEmpty
                    size='small'
                    id='surface'
                    name='surface'
                    value={values.surface}
                    onChange={(e) => {
                      setFieldValue('surface', e.target.value);
                    }}
                    input={<OutlinedInput />}
                    renderValue={(selected) => {
                      return selected ? selected : 'Please Select';
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value=''>
                      <em>Please Select</em>
                    </MenuItem>
                    {TrackSurface.map((item, i) => (
                      <MenuItem key={i} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormHelperText
                  error={true}
                  style={{ display: 'inline-block', float: 'right' }}
                >
                  {errors.surface && touched.surface && errors.surface}
                </FormHelperText>
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Circumference'}*</Typography>
                <MuiTextField
                  name='circumference'
                  type={'number'}
                  id='circumference'
                  placeholder={'810.00'}
                  unittext={
                    process.env.REACT_APP_ISO === 'IE' ? 'Meters' : 'Yards'
                  }
                  value={values.circumference}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.circumference && Boolean(errors.circumference)}
                  helperText={touched.circumference && errors.circumference}
                />
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Max on Gate'}*</Typography>
                <MuiTextField
                  name='maxOnGate'
                  id='maxOnGate'
                  type={'number'}
                  placeholder={'4'}
                  value={values.maxOnGate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.maxOnGate && Boolean(errors.maxOnGate)}
                  helperText={touched.maxOnGate && errors.maxOnGate}
                />
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{''}</Typography>
                <Typography>
                  <DevFileOptions
                    updateFormik={setFieldValue}
                    item={values.documentUrl}
                    fieldValue={'documentUrl'}
                    fileType={5}
                    lableLeftRight={false}
                    labelText={'Add contract or doc'}
                    textRight={true}
                  />
                </Typography>
                <FormHelperText
                  error={true}
                  style={{ display: 'inline-block', float: 'right' }}
                >
                  {errors.documentUrl && errors.documentUrl}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>LOCATION DETAILS</b>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography>{'Address'}*</Typography>
                <MuiTextField
                  name='address'
                  id='address'
                  placeholder={'Lancaster Road, Albany WA 6330'}
                  value={values.address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.address && Boolean(errors.address)}
                  helperText={touched.address && errors.address}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>{'Coordinates'}*</Typography>
                <Grid container direction='row' item xs={12} spacing={2}>
                  <Grid item xs={4} className='fullWidth'>
                    <MuiTextField
                      name='lat'
                      id='lat'
                      placeholder={'-34.98670602727136'}
                      value={values.lat}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lat && Boolean(errors.lat)}
                      helperText={touched.lat && errors.lat}
                    />
                  </Grid>
                  <Grid item xs={4} className='fullWidth'>
                    <MuiTextField
                      name='lng'
                      id='lng'
                      placeholder={'-34.98670602727136'}
                      value={values.lng}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.lng && Boolean(errors.lng)}
                      helperText={touched.lng && errors.lng}
                    />
                  </Grid>
                  <Grid item xs={4} className='fullWidth'>
                    <Button
                      sx={{
                        bgcolor: 'primary.main',
                        textTransform: 'none',
                        minWidth: '100%',
                        color: '#fff',
                        float: 'right',
                        ':hover': {
                          bgcolor: 'primary.dark',
                          color: 'white'
                        }
                      }}
                      onClick={(e) => {
                        handelMapOpen(e);
                      }}
                    >
                      Pick from Map <LocationOnIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Country'}*</Typography>
                <MuiTextField
                  name='country'
                  id='country'
                  placeholder={'United Kingdom'}
                  value={values.country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.country && Boolean(errors.country)}
                  helperText={touched.country && errors.country}
                />
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'PostCode'}*</Typography>
                <MuiTextField
                  name='postalCode'
                  id='postalCode'
                  placeholder={'302214'}
                  value={values.postalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.postalCode && Boolean(errors.postalCode)}
                  helperText={touched.postalCode && errors.postalCode}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>
                  <b>CONTACT PERSON</b>
                </Typography>
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Name'}*</Typography>
                <MuiTextField
                  name='contactPersonName'
                  id='contactPersonName'
                  placeholder={'Austin Robertson'}
                  value={values.contactPersonName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.contactPersonName &&
                    Boolean(errors.contactPersonName)
                  }
                  helperText={
                    touched.contactPersonName && errors.contactPersonName
                  }
                />
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Email'}*</Typography>
                <MuiTextField
                  name='contactPersonEmail'
                  id='contactPersonEmail'
                  placeholder={'administrator@albany.com'}
                  value={values.contactPersonEmail}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.contactPersonEmail &&
                    Boolean(errors.contactPersonEmail)
                  }
                  helperText={
                    touched.contactPersonEmail && errors.contactPersonEmail
                  }
                />
              </Grid>
              <Grid item xs={6} className='fullWidth'>
                <Typography>{'Phone'}*</Typography>
                <MuiTextField
                  name='contactPersonPhone'
                  id='contactPersonPhone'
                  placeholder={'0777777777'}
                  value={values.contactPersonPhone}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.contactPersonPhone &&
                    Boolean(errors.contactPersonPhone)
                  }
                  helperText={
                    touched.contactPersonPhone && errors.contactPersonPhone
                  }
                />
              </Grid>

              <Grid container item xs={12}>
                <Grid sx={{ float: 'left' }}>
                  <Button
                    sx={{
                      bgcolor: 'primary.main',
                      textTransform: 'capitalize',
                      minWidth: '130px',
                      color: '#fff',
                      float: 'right',
                      ':hover': {
                        bgcolor: 'primary.dark',
                        color: 'white'
                      }
                    }}
                    type='submit'
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={6} sx={{ minHeight: '100%' }}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'
              spacing={2}
            >
              <Grid item xs={10}>
                {values.pictureUrl && (
                  <img
                    width='100%'
                    height='425px'
                    src={`${values.pictureUrl}`}
                    srcSet={`${values.pictureUrl}`}
                    alt={'Track'}
                    loading='lazy'
                  />
                )}
              </Grid>
              <Grid item xs={2}>
                <div className='container' style={{ cursor: 'pointer' }}>
                  <Container
                    {...getRootProps({
                      isDragActive,
                      isDragAccept,
                      isDragReject
                    })}
                  >
                    <input {...getInputProps()} />
                    <AttachFileIcon />
                    <p>Drag or select photo of a map</p>
                  </Container>
                  <aside>
                    <h4>File</h4>
                    <ul>
                      {acceptedFiles.map((file) => (
                        <li key={file.path}>
                          {file.path} - {file.size} bytes
                        </li>
                      ))}
                    </ul>
                  </aside>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Dialog
          maxWidth={'lg'}
          open={openMapDialog}
          PaperProps={{
            sx: {
              minWidth: matches ? '100%' : '',
              height: matches ? 'auto' : '500px'
            }
          }}
          onClose={handelMapClose}
        >
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography style={{ float: 'right' }} onClick={handelMapClose}>
                  <IconButton>
                    <CloseIcon />
                  </IconButton>
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ height: '55vh', width: '50vw' }}>
                <MapModel
                  handelMapClose={handelMapClose}
                  updateformik={setFieldValue}
                  lat={values.lat}
                  lng={values.lng}
                  address={values.address}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions></DialogActions>
        </Dialog>
      </form>
    </>
  );
};

export default TrackDetails;
