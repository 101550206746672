import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  matchPath,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import paths from "../routing/paths";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import { HorseMenuIcon } from "./icons/HorseMenuIcon";
import { TrackesMenuIcon } from "./icons/TrackesMenuIcon";
import { MeetingsMenuIcon } from "./icons/MeetingsMenuIcon";
import AssessmentIcon from "@mui/icons-material/Assessment";
import GroupsIcon from "@mui/icons-material/Groups";
import { makeStyles } from "@mui/styles";
import { useAuth } from "../context/UserContext";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const useStyles = makeStyles((theme) => ({
  sidebarSelected: {
    "&:hover": {
      backgroundColor: "#FFF",
    },
    "&:active": {
      backgroundColor: "#FFF !important",
    },
    "&.Mui-selected": {
      backgroundColor: "#FFF !important",
      background: "#FFF !important",
      color: theme.palette.primary.main,
      borderLeft: `5px solid  ${theme.palette.primary.main}`,
      "& .MuiListItemIcon-root": {
        marginLeft: "-5px",
        color: theme.palette.primary.main,
      },
    },
  },
}));
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));
const menu = [
  {
    name: "People",
    path: paths.USER_LIST,
    index: 0,
    icon: PeopleAltIcon,
    isParrent: true,
    hasChild: false,
    isVisible: true,
  },
  {
    name: "Syndicates",
    path: paths.SYNDICATE_LIST,
    icon: GroupsIcon,
    index: 1,
    isParrent: true,
    hasChild: false,
    isVisible: true,
  },
  {
    name: "Horses",
    path: paths.HORSE_LIST,
    index: 2,
    icon: HorseMenuIcon,
    isParrent: true,
    hasChild: false,
    isVisible: true,
  },
  {
    name: "Tracks",
    path: paths.TRACK_LIST,
    index: 3,
    icon: TrackesMenuIcon,
    isParrent: true,
    hasChild: false,
    isVisible: true,
  },
  {
    name: "Meetings",
    path: paths.MEETING_LIST,
    index: 4,
    icon: MeetingsMenuIcon,
    isParrent: true,
    hasChild: false,
    isVisible: true,
  },
  {
    name: "Races",
    path: paths.RACE_LIST,
    index: 4,
    icon: MeetingsMenuIcon,
    isParrent: true,
    hasChild: false,
    isVisible: false,
  },
  {
    name: "Reports",
    path: paths.REPORT,
    index: 6,
    icon: AssessmentIcon,
    isParrent: true,
    hasChild: false,
    isVisible: true,
    sideMenu: true,
  },
  {
    name: "History",
    path: paths.HISTORY,
    index: 7,
    icon: AssessmentIcon,
    isParrent: false,
    hasChild: true,
    isVisible: true,
  },
  {
    name: "Microchip",
    path: paths.MICROCHIP,
    index: 8,
    icon: AssessmentIcon,
    isParrent: false,
    hasChild: true,
    isVisible: true,
  },
];

export default function SideBar(props) {
  const location = useLocation();
  const history = useHistory();
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const classes = useStyles();
  const handleListItemClick = (event, path, index) => {
    history.push(path);
    navigationToggle();
  };
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const { SideBarToggle } = useAuth();
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  React.useEffect(() => {
    if (location) {
      const currentRoute = menu.find((route) =>
        matchPath(location.pathname, route)
      );
      if (currentRoute) {
        setSelectedIndex(currentRoute.index);
      }
    }
  }, [location]);
  const navigationToggle = () => {
    SideBarToggle(false);
    console.log("test");
  };
  return (
    <Box justifyItems="center" alignItems="center">
      {matches && (
        <Box
          sx={{
            justifyContent: "end",
            display: "flex",
            margin: "15px",
            cursor: "pointer",
          }}
        >
          <CloseIcon onClick={() => navigationToggle()} />
        </Box>
      )}
      <List
        component="nav"
        aria-label=""
        style={{
          paddingTop: matches ? "40px" : " 110px",
        }}
      >
        {menu
          .filter((x) => x.isParrent === true)
          .map((item) => (
            <>
              {item.isVisible === true && (
                <>
                  <ListItem
                    key={item.index}
                    className={classes.sidebarSelected}
                    button
                    selected={selectedIndex === item.index}
                    onClick={(event) => {
                      item.sideMenu
                        ? handleExpandClick()
                        : handleListItemClick(event, item.path, item.index);
                    }}
                  >
                    <ListItemIcon>
                      {item.icon &&  <item.icon />}
                    </ListItemIcon>
                    <ListItemText
                      key={item.index}
                      primary={item.name}
                    ></ListItemText>
                    {item.icon && item.sideMenu && (
                      <ListItemIcon>
                        <ExpandMore
                          expand={expanded}
                          onClick={handleExpandClick}
                          aria-expanded={expanded}
                          aria-label="show more"
                        >
                          <ExpandMoreIcon />
                        </ExpandMore>
                      </ListItemIcon>
                    )}
                  </ListItem>
                </>
              )}
            </>
          ))}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          {menu
            .filter((x) => x.hasChild === true)
            .map((item) => (
              <>
                {item.isVisible === true && (
                  <>
                    <ListItem
                      key={item.index}
                      className={classes.sidebarSelected}
                      button
                      selected={selectedIndex === item.index}
                      onClick={(event) =>
                        handleListItemClick(event, item.path, item.index)
                      }
                    >
                      {
                        <Collapse
                          in={expanded}
                          timeout="auto"
                          unmountOnExit
                        ></Collapse>
                      }
                      <ListItemIcon>
                        {item.icon && item.hasChild ? "" : <item.icon />}
                      </ListItemIcon>
                      <ListItemText
                        key={item.index}
                        primary={item.name}
                      ></ListItemText>
                    </ListItem>
                  </>
                )}
              </>
            ))}
        </Collapse>
      </List>
    </Box>
  );
}
