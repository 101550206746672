import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
export const DownloadIcon = () => {
  const theme = useTheme();

  return (
    <Box sx={{ mr: '12px' }} >
      <svg
        width="18"
        height="12"
        viewBox="0 0 18 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.75 6.75L9 10.5L5.25 6.75H7.5V3.75H10.5V6.75H12.75ZM14.5125 4.5225C14.0025 1.9425 11.73 0 9 0C6.8325 0 4.95 1.23 4.0125 3.0225C1.755 3.27 0 5.175 0 7.5C0 8.69347 0.474106 9.83807 1.31802 10.682C2.16193 11.5259 3.30653 12 4.5 12H14.25C14.7425 12 15.2301 11.903 15.6851 11.7145C16.14 11.5261 16.5534 11.2499 16.9016 10.9017C17.2499 10.5534 17.5261 10.14 17.7145 9.68506C17.903 9.23009 18 8.74246 18 8.25C18 6.27 16.4625 4.665 14.5125 4.5225Z"
          fill={theme.palette.primary.main}
        />
      </svg>
    </Box>
  );
};
