import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import routes from "./routes";
import PrivateRoute from "./PrivateRoute";

const MainRouter = (props) => {
  return (
    <Router>
      <Switch>
        <Route
          path={routes.SIGN_IN.route}
          component={routes.SIGN_IN.component}
          exact
        />
        <Route
          path={routes.DEFAULT.route}
          component={routes.DEFAULT.component}
          exact
        />
        <PrivateRoute
          path={routes.HISTORY.route}
          component={routes.HISTORY.component}
        />

        <PrivateRoute
          path={routes.MICROCHIP.route}
          component={routes.MICROCHIP.component}
        />
        <PrivateRoute
          path={routes.USER_DETAILS.route}
          component={routes.USER_DETAILS.component}
          exact
        />

        <PrivateRoute
          path={routes.USER_LIST.route}
          component={routes.USER_LIST.component}
        />
        <PrivateRoute
          path={routes.HORSE_DETAILS.route}
          component={routes.HORSE_DETAILS.component}
          exact
        />
        <PrivateRoute
          path={routes.HORSE_LIST.route}
          component={routes.HORSE_LIST.component}
        />
        <PrivateRoute
          path={routes.TRACK_DETAILS.route}
          component={routes.TRACK_DETAILS.component}
          exact
        />
        <PrivateRoute
          path={routes.TRACK_LIST.route}
          component={routes.TRACK_LIST.component}
        />
        <PrivateRoute
          path={routes.REPORT.route}
          component={routes.REPORT.component}
        />
        <PrivateRoute
          path={routes.MEETING_DETAILS.route}
          component={routes.MEETING_DETAILS.component}
          exact
        />
        <PrivateRoute
          path={routes.MEETING_LIST.route}
          component={routes.MEETING_LIST.component}
        />
        <PrivateRoute
          path={routes.RACE_RESULTS.route}
          component={routes.RACE_RESULTS.component}
          exact
        />
        <PrivateRoute
          path={routes.RACE_DETAILS.route}
          component={routes.RACE_DETAILS.component}
          exact
        />
        <PrivateRoute
          path={routes.RACE_LIST.route}
          component={routes.RACE_LIST.component}
        />
        <PrivateRoute
          path={routes.SYNDICATE_DETAILS.route}
          component={routes.SYNDICATE_DETAILS.component}
          exact
        />
        <PrivateRoute
          path={routes.SYNDICATE_LIST.route}
          component={routes.SYNDICATE_LIST.component}
        />
      </Switch>
    </Router>
  );
};
export default MainRouter;
