
import { useTheme } from "@mui/styles";
import React from "react";

export const BackPageIcon = () => {
    const theme = useTheme();

    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="-0.5" width="31.378" height="31" rx="7.5" transform="matrix(1 0 0 -1 0 31)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} />
            <path fillRule="evenodd" clipRule="evenodd" d="M14.5843 16L18.9279 20.2929C19.3231 20.6834 19.3231 21.3166 18.9279 21.7071C18.5328 22.0976 17.8921 22.0976 17.497 21.7071L12.438 16.7071C12.0428 16.3166 12.0428 15.6834 12.438 15.2929L17.497 10.2929C17.8921 9.90237 18.5328 9.90237 18.9279 10.2929C19.3231 10.6834 19.3231 11.3166 18.9279 11.7071L14.5843 16Z" fill="white" />
        </svg>

    );
};