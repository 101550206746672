import endpoints from '../constants/endpoints';
import { fetchWrapper } from '../helpers/fetchWrapper';

export const MeetingService = {
  getPage,
  getById,
  save,
  delete: _delete
};

function getById(id) {
  return fetchWrapper.get(endpoints.MEETING.GET_BY_ID + id);
}
function getPage(params) {
  return fetchWrapper.post(endpoints.MEETING.GET, params);
}
function save(params) {
  return fetchWrapper.post(endpoints.MEETING.SAVE, params);
}
function _delete(params) {
  return fetchWrapper.post(endpoints.MEETING.SET_STATUS, params);
}
