import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  FormHelperText,
  Grid,
  Typography,
  MenuItem,
  Select,
  OutlinedInput,
  FormControl,
  Box,
  Paper,
  useMediaQuery,
  IconButton,
  CardContent,
  Drawer,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../context/UserContext";
import { makeStyles } from "@mui/styles";
import * as yup from "yup";
import { useFormik } from "formik";
import MuiTextField from "../../components/MuiTextField";
import ToastConfig from "../../components/toast/toast";
import { RacesService } from "../../services/RecesServices";
import paths from "../../routing/paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DevRacesResults from "../../components/DevRacesResults";
import { MeetingService } from "../../services/MeetingService";
import { PageLoading } from "../../components/icons/PageLoading";
import RaceCard from "./RaceCard/RaceCard";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { DownloadIcon } from "../../components/icons/DownloadIcon";
import { SeeMeetingsIcon } from "../../components/icons/SeeMeetingsIcon";
const unitValue = process.env.REACT_APP_ISO === "IE" ? "Meters" : "Yards";
const currency = process.env.REACT_APP_ISO === "IE" ? "€" : "£";
const RaceYards = ["Miles", "Km", unitValue];
const useStyles = makeStyles((theme) => ({
  section: {
    "& .fullWidth": {
      maxWidth: "100% !important",
      minWidth: "100%",
      width: "100% !important",
    },
  },
}));
const addItem = (setFieldValue, values, setErrorPrize) => {
  let prize = {
    value: "",
    prize: "",
  };
  const _prizes = [...(values.racePrize ?? []), prize];
  setFieldValue("racePrize", _prizes);
  setErrorPrize("");
};
const RaceResults = (props) => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const { SearchName } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [races, setReces] = useState(null);
  const [racesDetails, setRacesDetails] = useState({});
  const classes = useStyles();
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const [meeting, setMeeting] = useState(null);

  const history = useHistory();
  const [totalPurse, setTotalPurse] = useState(0);
  const [errprMessagePrize, setErrorPrize] = React.useState("");

  const remove = (index, setFieldValue, values) => {
    let prize = values.racePrize[index];
    let _prizes = values.racePrize.filter((item) => item !== prize);
    if (_prizes.length > 1) {
      setFieldValue("racePrize", _prizes);
      setTotalPurse(_prizes.reduce((a, b) => +a + +b.prize, 0));
      setErrorPrize("");
    } else {
      setErrorPrize("You cannot remove the last 2 prize money");
    }
  };

  useEffect(() => {
    if (props.match.params.meetingId) {
      meetingData(props.match.params.meetingId);
    }
  }, [props.match.params.meetingId]);

  const meetingData = (id) => {
    MeetingService.getById(id).then((response) => {
      if (response.success) {
        setMeeting(response.data);
      } else {
        props.match.params.meetingId !== 0 && ToastConfig.error("Server Error");
      }
    });
  };

  const onClickNavigation = (navigation) => {
    if (navigation === "meeting") {
      history.push(paths.MEETING_LIST);
    } else {
      history.push(
        paths.RACE_LIST.replace(":id", props.match.params.meetingId)
      );
    }
  };
  const onClickNavigationName = (navigation) => {
    let name =
      navigation === "meeting" ? meeting && meeting.name : races && races.name;
    SearchName(name);
    if (navigation === "meeting") {
      history.push(paths.MEETING_LIST);
    } else {
      history.push(
        paths.RACE_LIST.replace(":id", props.match.params.meetingId)
      );
    }
  };
  const handelEdit = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };



  const raceCardData = (id) => {
    RacesService.getRaceCard(id).then((response) => {
      if (response.success) {
        setRacesDetails(response.data);
      } else {
        props.match.params.id !== 0 &&
          ToastConfig.error("Could Not get Race Card");
      }
    });
  };
  useEffect(() => {

    loadData(props.match.params.id);

    raceCardData(props.match.params.id);



  }, [props.match.params.id]);

  useEffect(() => {
    if (races) {
      let newPrizes = races?.racePrize ? races?.racePrize : null;
      if (newPrizes) {
        setFieldValue("racePrize", races?.racePrize ? races?.racePrize : []);
        setTotalPurse(newPrizes.reduce((a, b) => +a + +b.prize, 0));
      }
      let newRaceHorses = races?.raceHorses ? races?.raceHorses : [];
      if (newRaceHorses && newRaceHorses) {
        let tempIdgenrated = "";
        console.log(newRaceHorses);
        newRaceHorses = newRaceHorses.map((item, index) => {
          let timeCount = "";
          let timeNumber = item.time ? item.time.split(":") : [];
          timeNumber = timeNumber.map((item) => {
            timeCount = timeCount + item;
            console.log(timeCount);
          });
          return {
            ...item,
            timeCount: +timeCount,
            name: item.horse && item.horse ? item.horse.name : "",
            horseName: item.horse && item.horse ? item.horse.name : "",
            horseId: item.horse && item.horse ? item.horse.id : "",
            trainer: item.trainer && item.trainer ? item.trainer.name : "",
            trainerDetails: item.trainer ? item.trainer : {},
            mare: item.mare && item.mare === false ? "No" : "Yes",
            driver: item.driver && item.driver ? item.driver.name : "",
            driverDetails: item.driver ? item.driver : {},
            position: item.position ? item.position : "",
            rank: item.rank ? item.rank : 0,
            raceId: item.raceId,
            hcp: item.handicap,
            time: item.time ? item.time : "00:00.0",
            rowBR: item.rowBR ? item.rowBR : "",
            prize: item.prize ? item.prize : "",
            allOwners: item.owners ? item.owners : [{ name: "" }, { name: "" }],
          };
        });
        newRaceHorses.sort((a, b) => {
          if (b.rank === 0) {
            return -1;
          }
          if (b.time === null) {
            return -1;
          }
          if (b.time === "00:00.0") {
            return -1;
          }
          if (a.status - b.status) {
            return 1;
          }
          if (a === b) {
            return 0;
          }
        });
        setFieldValue("raceHorses", newRaceHorses ? newRaceHorses : []);
      }
      setFieldValue("id", races?.id ? races?.id : 0);
      setFieldValue("name", races?.name ? races?.name : "");
      setFieldValue(
        "documentUrl",
        races?.documentUrl ? races?.documentUrl : ""
      );
      setFieldValue(
        "information",
        races?.information ? races?.information : ""
      );
      setFieldValue(
        "startDate",
        races?.startDate ? races?.startDate : new Date()
      );
      setFieldValue("units", races?.units ? races?.units : "");
      setFieldValue("distance", races?.distance ? races?.distance : 0);
      setFieldValue("entryFee", races?.entryFee ? races?.entryFee : 0);
      setFieldValue(
        "maresAllowanceAllowed",
        races?.maresAllowanceAllowed === true ? 0 : 1
      );
    }
  }, [races]);

  const loadData = (id) => {
    setIsLoading(true);

    RacesService.getById(id).then((response) => {
      if (response.success) {
        setReces(response.data);
      } else {
        props.match.params.id !== 0 &&
          ToastConfig.error("Could Not get Races Details");
      }
      setIsLoading(false);
    });
  };

  const validationSchema = yup.object({
    name: yup
      .string("Enter Name")
      .min(3, "Name must be at least 3 characters long")
      .required("Name is required"),
    information: yup
      .string("Enter Race Info")
      .required("Race Info is required"),
    units: yup.string("Enter Units").required("Units is required"),
    distance: yup
      .number("Enter Distance")
      .min(1, "Distance must be at least 1")
      .required("Distance is required"),
    startDate: yup.date().nullable().required("Start Date & Time is required"),
    entryFee: yup
      .number("Enter Entry Fee")
      .min(1, "Entry Fee must be at least 1")
      .required("Entry Fee is required"),
    racePrize: yup.array().of(
      yup.object().shape({
        prize: yup
          .number("Enter Prize")
          .min(1, "Prize must be at least 1")
          .required("Prize is required"),
      })
    ),
    raceHorses: yup.array().of(
      yup.object().shape({
        time: yup
          .string("Enter Time")
          .required("Time is required")
          .min(6, "Time must contain at least 5 length"),
        offGate: yup.string("Enter Trail").required("Trail is required"),
        position: yup
          .number("Enter Draw")
          .min(1, "Draw must be at least 1")
          .required("Draw is required"),
        trainer: yup.string().required("Trainer  is required"),
        driver: yup.string().required("Driver  is required"),
        allOwners: yup.array().of(
          yup.object().shape({
            name: yup.string().required("Owners  is required"),
          })
        ),
      })
    ),
  });
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      id: 0,
      name: "",
      information: "",
      units: "",
      distance: "",
      meetingId: "",
      startDate: "",
      maresAllowanceAllowed: 0,
      entryFee: "",
      racePrize: [
        { rank: 1, prize: "" },
        { rank: 2, prize: "" },
      ],
      raceHorses: [
        {
          value: "",
          tempId: "1",
          position: 1,
          offGate: "",
          trainer: "",
          driver: "",
          allOwners: [{ name: "" }, { name: "" }],
        },
        {
          value: "",
          tempId: "2",
          position: 2,
          offGate: "",
          trainer: "",
          driver: "",
          allOwners: [{ name: "" }, { name: "" }],
        },
      ],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let raceHorseFinal = [];
      let finalPrizes = [];
      let data = values;
      data?.racePrize.map((item, index) => {
        if (item && item.prize) {
          finalPrizes.push({
            prize: item.prize,
            rank: index + 1,
          });
        }
        return item;
      });
      data.raceHorses.map((item) => {
        let syndicateIds =
          item.allOwners &&
          item.allOwners.filter((i) => i.type === "Syndicate");
        let ownerIds =
          item.allOwners && item.allOwners.filter((i) => i.type === "User");
        if (item && item.horseId) {
          raceHorseFinal.push({
            raceId: item.raceId ? item.raceId : 0,
            horseId: item.horseId,
            time: item.time ? item.time : "",
            rowBR: item.rowBR ? item.rowBR : "",
            prize: item.prize ? item.prize : "",
            stewardComment: item.stewardComment ? item.stewardComment : "",
            status: item.status ? item.status : 0,
            offGate: item.offGate,
            position: item.position ? item.position : "",
            rank: item.rank ? item.rank : 0,
            syndicateIds: syndicateIds ? syndicateIds.map((s) => s.id) : null,
            ownerIds: ownerIds ? ownerIds.map((s) => s.id) : null,
            trainerId:
              item.trainerDetails &&
              item.trainerDetails.id &&
              item.trainerDetails.id,
            driverId:
              item.driverDetails &&
              item.driverDetails.id &&
              item.driverDetails.id,
          });
        }
        return item;
      });
      data.maresAllowanceAllowed =
        values.maresAllowanceAllowed === 0 ? true : false;
      data.racePrize = finalPrizes;
      data.meetingId = props.match.params.meetingId;
      data.raceHorses = raceHorseFinal;
      data.documentUrl = values.documentUrl;
      console.log(data);
      RacesService.saveResults(data)
        .then((response) => {
          if (response.success) {
            ToastConfig.success("Races successfully saved");
            loadData(props.match.params.id);
            raceCardData(props.match.params.id);

          } else {
            let statuscode = response.statusCode;
            ToastConfig.error(statuscode);
          }
        })
        .catch((error) => {
          console.error(error.message);
          ToastConfig.error(error);
        });
    },
  });

  return (
    <>
      {!isLoading ? (
        <>
          <Fragment>
            <Grid item xs={12}>
              <Typography variant="subtitle1" sx={{ pt: 6, pb: 6 }}>
                {races?.id > 0 ? (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickNavigation("meeting")}
                    >
                      Meetings
                    </span>
                    /{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickNavigationName("meeting")}
                    >
                      {meeting && meeting.name ? meeting.name : ""}
                    </span>{" "}
                    /
                    <span
                      onClick={() => onClickNavigation("races")}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Races
                    </span>{" "}
                    /{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickNavigationName("races")}
                    >
                      {races ? races.name : ""}
                    </span>{" "}
                    {/* /<b>Results</b> */}
                  </>
                ) : (
                  <>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickNavigation("meeting")}
                    >
                      Meetings
                    </span>
                    /{" "}
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => onClickNavigationName("meeting")}
                    >
                      {meeting && meeting.name ? meeting.name : ""}
                    </span>{" "}
                    /
                    <span
                      onClick={() => onClickNavigation("races")}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      Races
                    </span>{" "}
                    /{" "}
                    <span
                      onClick={() => onClickNavigationName("races")}
                      style={{ cursor: "pointer" }}
                    >
                      {races ? races.name : ""}
                    </span>{" "}
                    {/* /<b>Results</b> */}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid
              container
              background="#E5E5E5"
              spacing={6}
              className={matches ? classes.section : " "}
            >
              <Grid item xs={12}>
                <Paper sx={{ borderRadius: "15px", boxShadow: "none" }}>
                  <Box
                    borderColor="#D7DEEC"
                    sx={{
                      background: "#FFFFFF",
                      border: "1px solid #FFFFFF",
                      boxSizing: "borderBox",
                      borderRadius: "15px",
                    }}
                  >
                    {/* Race Card Start */}
                    <Drawer
                      open={openDialog}
                      onClose={handleDialogClose}
                      anchor="right"
                      sx={{
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: {
                          width: matches ? "100%" : "87%",
                          boxSizing: "border-box",
                        },
                      }}
                    >
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography variant="h5">
                              <span>Race Card</span>
                            </Typography>
                          </Grid>

                          <Grid item xs={6}>
                            <Typography
                              style={{ float: "right" }}
                              onClick={handleDialogClose}
                            >
                              <IconButton>
                                <CloseIcon />
                              </IconButton>
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                      <RaceCard
                        racesDetails={racesDetails}
                        isLoading={isLoading}
                        pdfMode={false}
                      />
                    </Drawer>
                    {/* Race Card End */}

                    <Box sx={{ m: 2 }}>
                      <form onSubmit={handleSubmit} id="RaceAddEditForm">
                        <Grid
                          container
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item md={12} spacing={2}>
                            <Grid container spacing={2}>
                              <Grid item xs={4} className="fullWidth">
                                <Typography>{"Name *"}</Typography>
                                <MuiTextField
                                  name="name"
                                  id="name"
                                  placeholder={"Albany"}
                                  value={values.name}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.name && Boolean(errors.name)}
                                  helperText={touched.name && errors.name}
                                />
                              </Grid>

                              <Grid item xs={2} className="fullWidth">
                                <Typography>{"Distance *"}</Typography>
                                <MuiTextField
                                  name="distance"
                                  id="distance"
                                  type="number"
                                  placeholder={"Distance"}
                                  value={values.distance}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.distance && Boolean(errors.distance)
                                  }
                                  helperText={
                                    touched.distance && errors.distance
                                  }
                                />
                              </Grid>
                              <Grid item xs={2} className="fullWidth">
                                <Typography>{"Units *"}</Typography>
                                <FormControl sx={{ width: "100%" }}>
                                  <Select
                                    autoWidth={true}
                                    displayEmpty
                                    size="small"
                                    id="units"
                                    name="units"
                                    value={values.units}
                                    onChange={(e) => {
                                      setFieldValue("units", e.target.value);
                                    }}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                      return selected
                                        ? selected
                                        : "Please Select";
                                    }}
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    <MenuItem value="">
                                      <em>Please Select</em>
                                    </MenuItem>
                                    {RaceYards.map((item, i) => (
                                      <MenuItem key={i} value={item}>
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <FormHelperText
                                  error={true}
                                  style={{
                                    display: "inline-block",
                                    float: "right",
                                  }}
                                >
                                  {errors.units &&
                                    touched.units &&
                                    errors.units}
                                </FormHelperText>
                              </Grid>

                              <Grid item xs={4} className="fullWidth">
                                <Typography>{"Start Date & Time *"}</Typography>
                                {meeting && (
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DateTimePicker
                                      name="startDate"
                                      id="startDate"
                                      inputFormat="dd/MM/yyyy h:mm a"
                                      placeholder={"Start Date & Time"}
                                      value={
                                        values.startDate
                                          ? values.startDate
                                          : meeting.startDate
                                            ? new Date(meeting.startDate)
                                            : ""
                                      }
                                      minDate={
                                        meeting.startDate
                                          ? new Date(meeting.startDate)
                                          : ""
                                      }
                                      defaultValue={
                                        meeting.startDate
                                          ? new Date(meeting.startDate)
                                          : ""
                                      }
                                      maxDate={
                                        meeting.endDate
                                          ? new Date(meeting.endDate)
                                          : ""
                                      }
                                      onChange={(newValue) => {
                                        setFieldValue(
                                          "startDate",
                                          newValue ? newValue : new Date()
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <React.Fragment>
                                          <MuiTextField
                                            {...params}
                                            inputProps={{
                                              ...params.inputProps,
                                              placeholder: "dd/MM/yyyy h:mm a",
                                            }}
                                          />
                                          <FormHelperText
                                            error={true}
                                            style={{
                                              display: "inline-block",
                                              float: "right",
                                            }}
                                          >
                                            {errors.startDate &&
                                              touched.startDate &&
                                              errors.startDate}
                                          </FormHelperText>
                                        </React.Fragment>
                                      )}
                                    />
                                  </LocalizationProvider>
                                )}
                              </Grid>

                              <Grid item xs={4} className="fullWidth">
                                <Typography>{"Race Info *"}</Typography>
                                <MuiTextField
                                  name="information"
                                  id="information"
                                  placeholder={"Race Info"}
                                  value={values.information}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.information &&
                                    Boolean(errors.information)
                                  }
                                  helperText={
                                    touched.information && errors.information
                                  }
                                />
                              </Grid>
                              <Grid item xs={2} className="fullWidth">
                                <Typography>{"Mares Allowance *"}</Typography>

                                <RadioGroup
                                  row
                                  aria-label="maresAllowed"
                                  name="maresAllowanceAllowed"
                                  defaultValue={0}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.maresAllowanceAllowed}
                                >
                                  <FormControlLabel
                                    value={0}
                                    control={<Radio />}
                                    label="Yes"
                                    labelPlacement="start"
                                  />
                                  <FormControlLabel
                                    value={1}
                                    control={<Radio />}
                                    label="No"
                                    labelPlacement="start"
                                  />
                                </RadioGroup>
                              </Grid>
                              <Grid item xs={2} className="fullWidth">
                                <Typography>{"Entry Fee *"}</Typography>
                                <MuiTextField
                                  name="entryFee"
                                  id="entryFee"
                                  type="number"
                                  placeholder={"Entry Fee"}
                                  value={values.entryFee}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  prize={true}
                                  error={
                                    touched.entryFee && Boolean(errors.entryFee)
                                  }
                                  helperText={
                                    touched.entryFee && errors.entryFee
                                  }
                                />
                              </Grid>
                              <Grid item xs={4} className="fullWidth">
                                <Typography sx={{ mt: 3 }}>
                                  DOWNLOAD RACE CARD
                                  <PDFDownloadLink
                                    document={
                                      <RaceCard
                                        racesDetails={racesDetails}
                                        isLoading={isLoading}
                                        pdfMode={true}
                                      />
                                    }
                                    fileName="raceCard"
                                    aria-label="Save PDF"
                                  >
                                    <IconButton>
                                      <DownloadIcon />
                                    </IconButton>
                                  </PDFDownloadLink>
                                  <IconButton onClick={handelEdit}>
                                    <SeeMeetingsIcon />
                                  </IconButton>
                                </Typography>
                              </Grid>

                              <Grid item xs={12}>
                                <Typography>{"Prizes *"}</Typography>

                                <Typography sx={{ mt: 1 }}>
                                  Total Purse: {currency}
                                  {totalPurse}
                                </Typography>
                                <Grid container spacing={2}>
                                  {values.racePrize &&
                                    values.racePrize.map((item, i) => {
                                      const prizeErrors =
                                        (errors.racePrize?.length &&
                                          errors.racePrize[i]) ||
                                        {};
                                      const prizeTouched =
                                        (touched.racePrize?.length &&
                                          touched.racePrize[i]) ||
                                        {};
                                      return (
                                        <>
                                          <Grid
                                            item
                                            xs={3}
                                            className="fullWidth"
                                          >
                                            <Grid
                                              container
                                              spacing={2}
                                              sx={{ alignItems: "center" }}
                                            >
                                              <Grid item xs={4}>
                                                <Typography>
                                                  {i + 1}
                                                  {" Place"}
                                                </Typography>
                                              </Grid>
                                              <Grid item xs={5}>
                                                <MuiTextField
                                                  id="prize"
                                                  type="number"
                                                  prize={true}
                                                  name={`racePrize.${i}.prize`}
                                                  value={
                                                    values.racePrize[`${i}`]
                                                      .prize
                                                  }
                                                  onChange={(e) => {
                                                    let eventPrize =
                                                      values.racePrize[`${i}`];
                                                    eventPrize.prize =
                                                      e.target.value;
                                                    setFieldValue(
                                                      `racePrize.${i}`,
                                                      eventPrize
                                                    );
                                                    setTotalPurse(
                                                      values.racePrize.reduce(
                                                        (a, b) => +a + +b.prize,
                                                        0
                                                      )
                                                    );
                                                  }}
                                                  error={
                                                    prizeTouched &&
                                                    prizeTouched.prize &&
                                                    Boolean(
                                                      prizeErrors &&
                                                      prizeErrors.prize
                                                    )
                                                  }
                                                  helperText={
                                                    prizeTouched &&
                                                    prizeTouched.prize &&
                                                    prizeErrors &&
                                                    prizeErrors.prize
                                                  }
                                                />
                                              </Grid>
                                              <Grid item xs={3}>
                                                <IconButton
                                                  onClick={(e) =>
                                                    remove(
                                                      i,
                                                      setFieldValue,
                                                      values
                                                    )
                                                  }
                                                >
                                                  <CloseIcon />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </>
                                      );
                                    })}
                                </Grid>
                                {errprMessagePrize && (
                                  <FormHelperText
                                    sx={{ my: 1, mt: "15px" }}
                                    error={true}
                                  >
                                    {errprMessagePrize}
                                  </FormHelperText>
                                )}
                                <Button
                                  sx={{
                                    mt: 1,
                                    float: "left",
                                    minWidth: "inherit",
                                    ":hover": {
                                      color: "main",
                                    },
                                  }}
                                  onClick={(e) =>
                                    addItem(
                                      setFieldValue,
                                      values,
                                      setErrorPrize
                                    )
                                  }
                                >
                                  <AddIcon /> Add
                                </Button>
                              </Grid>

                              <Grid item xs={12} sx={{ my: 4 }}>
                                <DevRacesResults
                                  raceHorses={values.raceHorses}
                                  horse={values.horse}
                                  Id={props.match.params.id}
                                  loadData={loadData}
                                  updateFormik={setFieldValue}
                                  errors={errors}
                                  touched={touched}
                                  handleBlur={handleBlur}
                                />
                              </Grid>
                              <Grid
                                container
                                item
                                xs={12}
                                sx={{ justifyContent: "flex-end" }}
                              >
                                <Grid sx={{ float: "righ" }}>
                                  <Button
                                    sx={{
                                      bgcolor: "primary.main",
                                      minWidth: "170px",
                                      textTransform: "capitalize",
                                      color: "#fff",
                                      float: "right",
                                      ":hover": {
                                        bgcolor: "primary.dark",
                                        color: "white",
                                      },
                                    }}
                                    type="submit"
                                  >
                                    Save
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </form>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Fragment>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default RaceResults;
