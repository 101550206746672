import React from 'react';
import { Typography } from '@mui/material';
import { Text as PdfText } from '@react-pdf/renderer';

const TextView = ({ className, pdfMode,span, children }) => {
  return (
    <>
      {pdfMode ? (
        <PdfText style={className}>{children}</PdfText>
      ) : (
        <Typography style={className} sx={{display:span ? 'inline-block' : 'block'}}>{children}</Typography>
      )}
    </>
  );
};

export default TextView;
