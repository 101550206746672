import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {SearchIcon} from './icons/SearchIcon'
const currency = process.env.REACT_APP_ISO === 'IE' ? '€' : '£';

const useHelperTextStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    bottom: '-20px',
    right: '-15px'
  },
  numberField: {
    '& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: '0'
    },
    '& input[type=number]': {
      mozAppearance: 'textfield'
    }
  }
}));
const useStyles = makeStyles(() => ({
  textBox: {
    margin: '10px 0px 10px 0px'
  },
  resize: {
    fontSize: '1rem'
  },
  root: {
    error: {
      position: 'absolute',
      bottom: '-20px',
      right: '-15px'
    }
  }
}));

export default function MuiTextField({
  HeaderText,
  value,
  id,
  label,
  type,
  onChange,
  onKeyDown,
  name,
  customClassName,
  unittext,
  isProtected,
  helperText,
  search,
  prize,
  ...rest
}) {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const classes = useStyles();
  const toggleEyeIcon = () => {
    setIsShowPassword(!isShowPassword);
  };
  const helperTextStyles = useHelperTextStyles();

  return (
    <>
      <Typography>{HeaderText}</Typography>
      <TextField
        sx={{ background: '#fff', borderRadius: '10px' }}
        id={id}
        helperText={helperText ? helperText : ' '}
        label={label}
        name={name}
        type={isProtected ? (isShowPassword ? 'string' : 'password') : type}
        onChange={onChange}
        onKeyDown={onKeyDown}
        placeholder={HeaderText}
        value={value}
        variant='outlined'
        fullWidth
        size='small'
        className={`${customClassName} input-custom ${helperTextStyles.numberField}`}
        color='primary'
        InputProps={{
          classes: {
            input: classes.resize
          },
          startAdornment: search ? (
            <InputAdornment position='start'>
              <SearchIcon />
            </InputAdornment>
          ):(
            prize && (
              <InputAdornment position='start'>{currency}</InputAdornment>
            )
          ),
          endAdornment: unittext ? (
            <InputAdornment position='end'>{unittext}</InputAdornment>
          ) : (
            isProtected && (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => toggleEyeIcon()}
                  edge='end'
                >
                  {isShowPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )
          )
        }}
        FormHelperTextProps={{
          classes: {
            root: helperTextStyles.root
          }
        }}
        {...rest}
      />
    </>
  );
}
