import React from 'react';
import { Page as PdfPage } from '@react-pdf/renderer';

const Page = ({ className, pdfMode, children }) => {
  return (
    <>
      {pdfMode ? (
        <PdfPage size='A3' style={className} wrap>
          {children}
        </PdfPage>
      ) : (
        <div 
        style={className}>{children}</div>
      )}
    </>
  );
};

export default Page;
