import React, { useEffect, useState } from "react";
import {
  Button,
  FormHelperText,
  Grid,
  Typography,
  MenuItem,
  Select,
  OutlinedInput,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  IconButton,
  Drawer,
  CardContent,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DevFileOptions from "../../components/DevFileOptions";
import * as yup from "yup";
import { useFormik } from "formik";
import AddIcon from "@mui/icons-material/Add";
import MuiTextField from "../../components/MuiTextField";
import ToastConfig from "../../components/toast/toast";
import { RacesService } from "../../services/RecesServices";
import paths from "../../routing/paths";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { DateTimePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import RacesDragDropList from "../../components/RacesDragDropList";
import { PageLoading } from "../../components/icons/PageLoading";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { DownloadIcon } from "../../components/icons/DownloadIcon";
import { SeeMeetingsIcon } from "../../components/icons/SeeMeetingsIcon";
import RaceCard from "./RaceCard/RaceCard";

const unitValue = process.env.REACT_APP_ISO === "IE" ? "Meters" : "Yards";
const currency = process.env.REACT_APP_ISO === "IE" ? "€" : "£";

const RaceYards = ["Miles", "Km", unitValue];

const addItem = (setFieldValue, values, setErrorPrize) => {
  let prize = {
    value: "",
    prize: "",
  };
  const _prizes = [...(values.racePrize ?? []), prize];
  setFieldValue("racePrize", _prizes);
  setErrorPrize("");
};

const RaceDetails = (props) => {
  const { currentItem, id, meetingId, handleClose } = props;
  const [races, setReces] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [racesDetails, setRacesDetails] = useState({});
  const [totalPurse, setTotalPurse] = useState(0);
  const [errprMessage, setError] = React.useState("");
  const [errprMessagePrize, setErrorPrize] = React.useState("");
  const history = useHistory();
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const remove = (index, setFieldValue, values) => {
    let prize = values.racePrize[index];
    let _prizes = values.racePrize.filter((item) => item !== prize);
    if (_prizes.length > 1) {
      setFieldValue(`racePrize`, _prizes);
      setTotalPurse(_prizes.reduce((a, b) => +a + +b.prize, 0));
      setErrorPrize("");
    } else {
      setErrorPrize("You cannot remove the last 2 prize money");
    }
  };
  useEffect(() => {
    loadData(props.id);
    return () => {
      resetForm();
    };
  }, [currentItem, id, meetingId]);
  const handelEdit = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
  };
  useEffect(() => {
    raceCardData(props.id);
  }, [props.id]);
  const raceCardData = (id) => {
    setIsLoading(true);
    RacesService.getRaceCard(id).then((response) => {
      if (response.success) {
        setRacesDetails(response.data);
      } else {
        props.id !== 0 && ToastConfig.error("Could Not get Race Card");
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    setFieldValue(
      "startDate",
      props.meeting.startDate ? props.meeting.startDate : new Date()
    );
    if (races) {
      let newPrizes = races?.racePrize ? races?.racePrize : null;
      if (newPrizes) {
        setFieldValue("racePrize", races?.racePrize ? races?.racePrize : []);
        setTotalPurse(newPrizes.reduce((a, b) => +a + +b.prize, 0));
      }
      let newRaceHorses = races?.raceHorses ? races?.raceHorses : [];
      if (newRaceHorses && newRaceHorses) {
        let tempIdgenrated = "";
        console.log(newRaceHorses);
        setFieldValue(
          "raceHorses",
          newRaceHorses
            ? newRaceHorses.map((item, index) => {
                tempIdgenrated = JSON.stringify(
                  Math.floor(Math.random() * 1000000)
                );
                return {
                  ...item,
                  name: item.horse && item.horse ? item.horse.name : "",
                  horseName: item.horse && item.horse ? item.horse.name : "",
                  horseId: item.horse && item.horse ? item.horse.id : "",
                  trainer:
                    item.trainer && item.trainer ? item.trainer.name : "",
                  trainerDetails: item.trainer ? item.trainer : {},
                  mare: item.mare && item.mare === true ? "Yes" : "No",
                  driver: item.driver && item.driver ? item.driver.name : "",
                  driverDetails: item.driver ? item.driver : {},

                  position: item.position ? item.position : index + 1,
                  rank: item.rank ? item.rank : index + 1,
                  raceId: item.raceId,
                  hcp: item.handicap,
                  tempId: tempIdgenrated,
                  offGate: item.offGate ? item.offGate : "",
                  allOwners: item.owners ? item.owners : [{name:''},{name:''}],
                };
              })
            : []
        );
      }
      setFieldValue("id", races?.id ? races?.id : 0);
      setFieldValue("name", races?.name ? races?.name : "");
      setFieldValue(
        "information",
        races?.information ? races?.information : ""
      );
      setFieldValue(
        "startDate",
        races?.startDate ? races?.startDate : new Date()
      );
      setFieldValue("units", races?.units ? races?.units : "");
      setFieldValue("distance", races?.distance ? races?.distance : 0);
      setFieldValue("entryFee", races?.entryFee ? races?.entryFee : 0);
      setFieldValue(
        "maresAllowanceAllowed",
        races?.maresAllowanceAllowed === true ? 0 : 1
      );
      setFieldValue(
        "documentUrl",
        races?.documentUrl ? races?.documentUrl : ""
      );
    }
  }, [races]);

  const loadData = (id) => {
    setIsLoading(true);
    RacesService.getById(id).then((response) => {
      if (response.success) {
        setReces(response.data);
      } else {
        props.id !== 0 && ToastConfig.error("Could Not get Races Details");
      }
      setIsLoading(false);
    });
  };

  const validationSchema = yup.object({
    name: yup
      .string("Enter Name")
      .min(3, "Name must be at least 3 characters long")
      .required("Name is required"),
    information: yup
      .string("Enter Race Info")
      .required("Race Info is required"),
    units: yup.string("Enter Units").required("Units is required"),
    distance: yup
      .number("Enter Distance")
      .min(1, "Distance must be at least 1")
      .required("Distance is required"),
    startDate: yup.date().nullable().required("Start Date & Time is required"),
    entryFee: yup
      .number("Enter Entry Fee")
      .min(1, "Entry Fee must be at least 1")
      .required("Entry Fee is required"),
    raceHorses: yup.array().of(
      yup.object().shape({
        offGate: yup.string("Enter Trail").required("Trail is required"),
        horseName: yup
          .string("Enter Horse ")
          .min(1, "Horse  must be at least 1")
          .required("Horse  is required"),
        trainer: yup.string().required("Trainer  is required"),
        driver: yup.string().required("Driver  is required"),
        allOwners: yup.array().of(
          yup.object().shape({
            name: yup.string().required("Owners  is required"),
          })
        ),
      })
    ),
    racePrize: yup.array().of(
      yup.object().shape({
        prize: yup
          .number("Enter Prize")
          .min(1, "Prize must be at least 1")
          .required("Prize is required"),
      })
    ),
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    resetForm,
    setFieldValue,
  } = useFormik({
    initialValues: {
      id: 0,
      name: "",
      information: "",
      units: "",
      distance: "",
      meetingId: "",
      startDate: "",
      maresAllowanceAllowed: 0,
      entryFee: "",
      racePrize: [
        { rank: 1, prize: "" },
        { rank: 2, prize: "" },
      ],
      raceHorses: [
        {
          value: "",
          tempId: "1",
          position: 1,
          offGate: "",
          horseName: "",
          trainer: "",
          driver: "",
          allOwners:[{name:''},{name:''}]
        },
        {
          value: "",
          tempId: "2",
          position: 2,
          offGate: "",
          horseName: "",
          trainer: "",
          driver: "",
          allOwners:[{name:''},{name:''}]
        },
      ],
    },
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      let raceHorseFinal = [];
      let finalPrizes = [];
      let data = values;
      data.documentUrl = values.documentUrl;
      data?.racePrize.map((item, index) => {
        if (item && item.prize) {
          finalPrizes.push({
            prize: item.prize,
            rank: index + 1,
          });
        }
        return item;
      });
      data.raceHorses.map((item, index) => {
        let syndicateIds =
          item.allOwners &&
          item.allOwners.filter((i) => i.type === "Syndicate");
        let ownerIds =
          item.allOwners && item.allOwners.filter((i) => i.type === "User");
        if (item && item.horseId) {
          raceHorseFinal.push({
            // ...item,
            raceId: item.raceId ? item.raceId : 0,
            horseId: item.horseId,
            position: index + 1,
            rank: index + 1,
            trainer: item.trainer,
            trainerId:
              item.trainerDetails &&
              item.trainerDetails.id &&
              item.trainerDetails.id,
            driver: item.driver,
            driverId:
              item.driverDetails &&
              item.driverDetails.id &&
              item.driverDetails.id,
            offGate: item.offGate ? item.offGate : "",
            horse: item.horse,
            syndicateIds: syndicateIds ? syndicateIds.map((s) => s.id) : null,
            ownerIds: ownerIds ? ownerIds.map((s) => s.id) : null,
          });
        }
        return item;
      });
      data.maresAllowanceAllowed =
        values.maresAllowanceAllowed === 0 ? true : false;
      data.meetingId = meetingId;
      data.racePrize = finalPrizes;
      data.raceHorses = raceHorseFinal;
      if (raceHorseFinal && raceHorseFinal.length > 1) {
        setIsLoading(true);
        setError("");
        RacesService.save(data)
          .then((response) => {
            if (response.success) {
              ToastConfig.success("Races successfully saved");
              history.push(paths.RACE_LIST.replace(":id", meetingId));
              setIsLoading(false);
              handleClose();
            } else {
              let statuscode = response.statusCode;
              ToastConfig.error(statuscode);
              setIsLoading(false);
              handleClose();
            }
          })
          .catch((error) => {
            console.error(error.message);
            ToastConfig.error(error);
          });
      } else {
        setError("Note: A race needs to have at least 2 horses.");
      }
    },
  });

  return (
    <>
      {!isLoading ? (
        <>
          {/* Race Card Start */}
          <Drawer
            open={openDialog}
            onClose={handleDialogClose}
            anchor="right"
            sx={{
              flexShrink: 0,
              [`& .MuiDrawer-paper`]: {
                width: matches ? "100%" : "90%",
                boxSizing: "border-box",
              },
            }}
          >
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="h5">
                    <span>Race Card</span>
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <Typography
                    style={{ float: "right" }}
                    onClick={handleDialogClose}
                  >
                    <IconButton>
                      <CloseIcon />
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <RaceCard
              racesDetails={racesDetails}
              isLoading={isLoading}
              pdfMode={false}
            />
          </Drawer>
          {/* Race Card End */}

          <form onSubmit={handleSubmit} id="RaceAddEditForm">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item md={12} spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={4} className="fullWidth">
                    <Typography>{"Name *"}</Typography>
                    <MuiTextField
                      name="name"
                      id="name"
                      placeholder={"Albany"}
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.name && Boolean(errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>

                  <Grid item xs={2} className="fullWidth">
                    <Typography>{"Distance *"}</Typography>
                    <MuiTextField
                      name="distance"
                      id="distance"
                      type="number"
                      placeholder={"Distance"}
                      value={values.distance}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.distance && Boolean(errors.distance)}
                      helperText={touched.distance && errors.distance}
                    />
                  </Grid>
                  <Grid item xs={2} className="fullWidth">
                    <Typography>{"Units *"}</Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        autoWidth={true}
                        displayEmpty
                        size="small"
                        id="units"
                        name="units"
                        value={values.units}
                        onChange={(e) => {
                          setFieldValue("units", e.target.value);
                        }}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          return selected ? selected : "Please Select";
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="">
                          <em>Please Select</em>
                        </MenuItem>
                        {RaceYards.map((item, i) => (
                          <MenuItem key={i} value={item}>
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormHelperText
                      error={true}
                      style={{ display: "inline-block", float: "right" }}
                    >
                      {errors.units && touched.units && errors.units}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={4} className="fullWidth">
                    <Typography>{"Start Date & Time *"}</Typography>
                    {props.meeting && (
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          name="startDate"
                          id="startDate"
                          inputFormat="dd/MM/yyyy h:mm a"
                          placeholder={"Start Date & Time"}
                          value={values.startDate}
                          minDate={
                            props.meeting.startDate
                              ? new Date(props.meeting.startDate)
                              : ""
                          }
                          defaultValue={
                            props.meeting.startDate
                              ? new Date(props.meeting.startDate)
                              : ""
                          }
                          maxDate={
                            props.meeting.endDate
                              ? new Date(props.meeting.endDate)
                              : ""
                          }
                          onChange={(newValue) => {
                            setFieldValue(
                              "startDate",
                              newValue ? newValue : new Date()
                            );
                          }}
                          renderInput={(params) => (
                            <React.Fragment>
                              <MuiTextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "dd/MM/yyyy h:mm a",
                                }}
                              />
                              <FormHelperText
                                error={true}
                                style={{
                                  display: "inline-block",
                                  float: "right",
                                }}
                              >
                                {errors.startDate &&
                                  touched.startDate &&
                                  errors.startDate}
                              </FormHelperText>
                            </React.Fragment>
                          )}
                        />
                      </LocalizationProvider>
                    )}
                  </Grid>

                  <Grid item xs={4} className="fullWidth">
                    <Typography>{"Race Info *"}</Typography>
                    <MuiTextField
                      name="information"
                      id="information"
                      placeholder={"Race Info"}
                      value={values.information}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.information && Boolean(errors.information)}
                      helperText={touched.information && errors.information}
                    />
                  </Grid>
                  <Grid item xs={2} className="fullWidth">
                    <Typography>{"Mares Allowance *"}</Typography>

                    <RadioGroup
                      row
                      aria-label="maresAllowed"
                      name="maresAllowanceAllowed"
                      defaultValue={0}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.maresAllowanceAllowed}
                    >
                      <FormControlLabel
                        value={0}
                        control={<Radio />}
                        label="Yes"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value={1}
                        control={<Radio />}
                        label="No"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </Grid>
                  <Grid item xs={2} className="fullWidth">
                    <Typography>{"Entry Fee *"}</Typography>
                    <MuiTextField
                      name="entryFee"
                      id="entryFee"
                      type="number"
                      placeholder={"Entry Fee"}
                      value={values.entryFee}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      prize={true}
                      error={touched.entryFee && Boolean(errors.entryFee)}
                      helperText={touched.entryFee && errors.entryFee}
                    />
                  </Grid>
                  {props.id > 0 && (
                    <Grid item xs={4} className="fullWidth">
                      <Typography sx={{ mt: 3 }}>
                        DOWNLOAD RACE CARD
                        <PDFDownloadLink
                          document={
                            <RaceCard
                              racesDetails={racesDetails}
                              isLoading={isLoading}
                              pdfMode={true}
                            />
                          }
                          fileName="raceCard"
                          aria-label="Save PDF"
                        >
                          <IconButton>
                            <DownloadIcon />
                          </IconButton>
                        </PDFDownloadLink>
                        <IconButton onClick={handelEdit}>
                          <SeeMeetingsIcon />
                        </IconButton>
                      </Typography>
                    </Grid>
                  )}

                  <Grid item xs={12}>
                    <Typography>{"Prizes *"}</Typography>

                    <Typography sx={{ mt: 1 }}>
                      Total Purse: {currency}
                      {totalPurse}
                    </Typography>
                    <Grid container spacing={2}>
                      {values.racePrize &&
                        values.racePrize.map((item, i) => {
                          const prizeErrors =
                            (errors.racePrize?.length && errors.racePrize[i]) ||
                            {};
                          const prizeTouched =
                            (touched.racePrize?.length &&
                              touched.racePrize[i]) ||
                            {};
                          return (
                            <>
                              <Grid item xs={3} className="fullWidth">
                                <Grid
                                  container
                                  spacing={2}
                                  sx={{ alignItems: "center" }}
                                >
                                  <Grid item xs={4}>
                                    <Typography>
                                      {i + 1}
                                      {" Place"}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={5}>
                                    <MuiTextField
                                      id="prize"
                                      type="number"
                                      prize={true}
                                      name={`racePrize.${i}.prize`}
                                      value={values.racePrize[`${i}`].prize}
                                      onChange={(e) => {
                                        let eventPrize =
                                          values.racePrize[`${i}`];
                                        eventPrize.prize = e.target.value;
                                        setFieldValue(
                                          `racePrize.${i}`,
                                          eventPrize
                                        );
                                        setTotalPurse(
                                          values.racePrize.reduce(
                                            (a, b) => +a + +b.prize,
                                            0
                                          )
                                        );
                                      }}
                                      error={
                                        prizeTouched &&
                                        prizeTouched.prize &&
                                        Boolean(
                                          prizeErrors && prizeErrors.prize
                                        )
                                      }
                                      helperText={
                                        prizeTouched &&
                                        prizeTouched.prize &&
                                        prizeErrors &&
                                        prizeErrors.prize
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={3}>
                                    <IconButton
                                      onClick={(e) =>
                                        remove(i, setFieldValue, values)
                                      }
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </>
                          );
                        })}
                    </Grid>
                    {errprMessagePrize && (
                      <FormHelperText sx={{ my: 1, mt: "15px" }} error={true}>
                        {errprMessagePrize}
                      </FormHelperText>
                    )}
                    <Button
                      sx={{
                        float: "left",
                        minWidth: "inherit",
                        mt: 1,
                        ":hover": {
                          color: "main",
                        },
                      }}
                      onClick={(e) =>
                        addItem(setFieldValue, values, setErrorPrize)
                      }
                    >
                      <AddIcon /> Add
                    </Button>
                  </Grid>
                  <Grid item xs={12} sx={{ my: 4 }}>
                    <RacesDragDropList
                      raceHorses={values.raceHorses}
                      horse={values.horse}
                      setError={setError}
                      errprMessage={errprMessage}
                      updateFormik={setFieldValue}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  </Grid>

                  <Grid
                    container
                    item
                    xs={12}
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <Grid sx={{ float: "righ" }}>
                      <Button
                        sx={{
                          bgcolor: "primary.main",
                          minWidth: "170px",
                          textTransform: "capitalize",
                          color: "#fff",
                          float: "right",
                          ":hover": {
                            bgcolor: "primary.dark",
                            color: "white",
                          },
                        }}
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default RaceDetails;
