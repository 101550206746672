
import { useTheme } from "@mui/styles";
import React from "react";

export const NextPageIcon = () => {
    const theme = useTheme();

    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="32.2554" y="31.5" width="31.378" height="31" rx="7.5" transform="rotate(-180 32.2554 31.5)" fill={theme.palette.primary.main} stroke={theme.palette.primary.main} />
            <path fillRule="evenodd" clipRule="evenodd" d="M18.1712 16L13.8276 11.7071C13.4325 11.3166 13.4325 10.6834 13.8276 10.2929C14.2227 9.90237 14.8634 9.90237 15.2585 10.2929L20.3176 15.2929C20.7127 15.6834 20.7127 16.3166 20.3176 16.7071L15.2585 21.7071C14.8634 22.0976 14.2227 22.0976 13.8276 21.7071C13.4325 21.3166 13.4325 20.6834 13.8276 20.2929L18.1712 16Z" fill="white" />
        </svg>

    );
};