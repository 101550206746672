
import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";


export const InActivateIcon = () => {
    const theme = useTheme();

    return (
        <Box sx={{ mr: '12px' }} >

        <svg width="18" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.68421 5.68421C7.25447 5.68421 8.52632 4.41237 8.52632 2.84211C8.52632 1.27184 7.25447 0 5.68421 0C4.11395 0 2.84211 1.27184 2.84211 2.84211C2.84211 4.41237 4.11395 5.68421 5.68421 5.68421ZM5.68421 1.42105C6.4729 1.42105 7.10526 2.06053 7.10526 2.84211C7.10526 3.62368 6.4729 4.26316 5.68421 4.26316C4.89553 4.26316 4.26316 3.63079 4.26316 2.84211C4.26316 2.05342 4.90263 1.42105 5.68421 1.42105ZM5.16553 11.3684H0V9.23684C0 7.33974 3.78711 6.39474 5.68421 6.39474C6.42316 6.39474 7.46053 6.54395 8.42684 6.82816C7.81579 7.06974 7.24737 7.40368 6.75 7.81579C6.39474 7.77316 6.03947 7.74474 5.68421 7.74474C3.57395 7.74474 1.35 8.7821 1.35 9.23684V10.0184H5.13C5.11579 10.0539 5.09447 10.0895 5.07316 10.125L4.86711 10.6579L5.07316 11.1908C5.10158 11.2476 5.13711 11.3045 5.16553 11.3684ZM10.6579 9.94737C11.0558 9.94737 11.3684 10.26 11.3684 10.6579C11.3684 11.0558 11.0558 11.3684 10.6579 11.3684C10.26 11.3684 9.94737 11.0558 9.94737 10.6579C9.94737 10.26 10.26 9.94737 10.6579 9.94737ZM10.6579 7.81579C8.71816 7.81579 7.06263 8.99526 6.39474 10.6579C7.06263 12.3205 8.71816 13.5 10.6579 13.5C12.5976 13.5 14.2532 12.3205 14.9211 10.6579C14.2532 8.99526 12.5976 7.81579 10.6579 7.81579ZM10.6579 12.4342C9.67737 12.4342 8.88158 11.6384 8.88158 10.6579C8.88158 9.67737 9.67737 8.88158 10.6579 8.88158C11.6384 8.88158 12.4342 9.67737 12.4342 10.6579C12.4342 11.6384 11.6384 12.4342 10.6579 12.4342Z" fill={theme.palette.primary.main} />
        </svg>
        </Box>
    )
}