import endpoints from '../constants/endpoints';
import { fetchWrapper } from '../helpers/fetchWrapper';

export const RacesService = {
  getPage,
  getById,
  save,
  delete: _delete,
  getHorseByName,
  saveStewardsComment,
  saveResults,
  withdraw,
  getRaceCard
};

function getById(id) {
  return fetchWrapper.get(endpoints.RACES.GET_BY_ID + id);
}
function getPage(params) {
  return fetchWrapper.post(endpoints.RACES.GET, params);
}
function save(params) {
  return fetchWrapper.post(endpoints.RACES.SAVE, params);
}
function _delete(params) {
  return fetchWrapper.post(endpoints.RACES.SET_STATUS, params);
}
function getHorseByName(params, callback) {
  return fetchWrapper
    .get(endpoints.RACES.GET_HORSE_BY_NAME + params)
    .then((response) => {
      callback(response);
    });
}
function saveStewardsComment(params) {
  return fetchWrapper.post(endpoints.RACES.SAVE_STEWARDS_COMMENT, params);
}
function saveResults(params) {
  return fetchWrapper.post(endpoints.RACES.SAVE_RESULTS, params);
}
function withdraw(params) {
  return fetchWrapper.post(endpoints.RACES.WITHDRAW, params);
}
function getRaceCard(id) {
  return fetchWrapper.get(endpoints.RACES.GET_RACE_CARD + id);
}
