import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

export const PlusIcon = () => {
  const theme = useTheme();

  return (
    <Box sx={{ mr: '12px' }}>
      <svg
        width={18}
        height={14}
        viewBox='0 0 10 10'
        fill={theme.palette.primary.main}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M9.28571 3.92857H6.07143V0.714286C6.07143 0.319866 5.75156 0 5.35714 0H4.64286C4.24844 0 3.92857 0.319866 3.92857 0.714286V3.92857H0.714286C0.319866 3.92857 0 4.24844 0 4.64286V5.35714C0 5.75156 0.319866 6.07143 0.714286 6.07143H3.92857V9.28571C3.92857 9.68013 4.24844 10 4.64286 10H5.35714C5.75156 10 6.07143 9.68013 6.07143 9.28571V6.07143H9.28571C9.68013 6.07143 10 5.75156 10 5.35714V4.64286C10 4.24844 9.68013 3.92857 9.28571 3.92857Z'
          fill='#012169'
        />
      </svg>
    </Box>
  );
};
