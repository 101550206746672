import React, { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Grid,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Box,
  Button,
  IconButton,
  Typography,
  Menu,
  Drawer,
  CardContent,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery
} from '@mui/material';
import paths from '../../routing/paths';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NoData } from '../../components/icons/NoData';
import { useHistory } from 'react-router';
import DevConfirmDialog from '../../components/dialog/DevConfirmDialog';
import MuiTextField from '../../components/MuiTextField';
import { alpha, styled } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import DevPagination from '../../components/DevPagination';
import CloseIcon from '@mui/icons-material/Close';
import { RacesService } from '../../services/RecesServices';
import RaceDetails from './RaceDetails';
import { MeetingService } from '../../services/MeetingService';
import ToastConfig from '../../components/toast/toast';
import { dateTimeAMPMFormat, dateMonthFormat } from '../../helpers/util';
import MeetingDetails from '../Meetngs/MeetingDetails';
import { makeStyles } from '@mui/styles';
import { PageLoading } from '../../components/icons/PageLoading';
import { useAuth } from '../../context/UserContext';
const headCells = [
  { id: 'name ', label: 'NAME' },
  { id: 'raceInfo', label: ' RACE INFO' },
  { id: 'startRacingDateTime', label: 'START RACING DATE TIME' },
  { id: 'distance', label: 'DISTANCE' },
  {
    id: 'maresAllowed',
    label: 'MARES  ALLOWED'
  },
  { id: 'action', label: 'ACTIONS' }
];
const useStyles = makeStyles((theme) => ({
  thead: {
    '& th:first-child': {
      borderRadius: '5px 0 0 5px'
    },
    '& th:last-child': {
      borderRadius: '0 5px 5px 0'
    },
    '& th': {
      borderBottom: '0px'
    }
  },
  tableRow: {
    '&  td': {
      cursor: 'pointer'
    }
  },
  tableMobile: {
    '& table': {
      border: 0
    },
    ' & table caption': {
      fontSize: '1.3em'
    },
    '& table thead': {
      border: 'none',
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: '1px'
    },

    '& table tr': {
      borderBottom: '3px solid #ddd',
      display: 'block',
      marginBottom: '.625em',
      border: '1px solid #ddd',
      borderRadius: '04px'
    },

    '& table td': {
      borderBottom: '1px solid #ddd',
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right'
    },

    '& table td::before': {
      content: 'attr(data-label)',
      float: 'left',
      fontWeight: 500
    },

    '& table td:last-child': {
      borderBottom: 0
    },
    '& .avatar': {
      display: 'flex',
      justifyContent: 'end'
    },
    '& .roles': {
      display: 'flex',
      justifyContent: 'end'
    },
    '& .action': {
      textAlign: 'left',
      padding: ' 0px 0px 0px 5px'
    }
  },
  topButton: {
    '& .fullWidth': {
      maxWidth: '100% !important',
      minWidth: '100%',
      width: '100% !important'
    }
  }
}));
const StyledMenu = styled((props) => (
  <Menu
    disableScrollLock={true}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));
const RaceList = (props) => {
  const searchName = useAuth();
  const { SearchName } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const classes = useStyles();
  const [races, setRaces] = useState([]);
  const [search, setSearch] = useState('');
  const [term, setTerm] = useState('');
  const [meeting, setMeeting] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageElements, setTotalPageElements] = useState(0);
  const [takeCount, setTakeCount] = useState(10);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const deleteStatus = 1;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: ''
  });
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down('sm') ?? '600'
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [openMeetingDialog, setOpenMeetingDialog] = React.useState(false);
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const handleChange = (event, value) => {
    setPage(value);
    setTakeCount(10);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
  };

  const handelEdit = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const onClickhandelEdit = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };
  const handelAdd = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(null);
    loadData(tabValue);
  };
  const racesResult = () => {
    history.push(
      paths.RACE_RESULTS.replace(':id', selectedItem.id).replace(
        ':meetingId',
        meeting.id
      )
    );
  };
  const onClickRacesResult = (id) => {
    history.push(
      paths.RACE_RESULTS.replace(':id', id).replace(':meetingId', meeting.id)
    );
  };
  const onClickNavigation = () => {
    history.push(paths.MEETING_LIST);
  };

  const onClickNavigationName = () => {
    SearchName(meeting.name);
    history.push(paths.MEETING_LIST);
  };
  const handelMeetingEdit = () => {
    setOpenMeetingDialog(true);
    setAnchorEl(null);
  };
  const handleMeetingDialogClose = () => {
    setOpenMeetingDialog(false);
  };
  const handleSetStatus = (statusType) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    //Delete service call
    var raw = {
      id: selectedItem.id,
      status: statusType
    };
    RacesService.delete(raw).then((response) => {
      loadData(tabValue);
    });
    handleClose();
  };
  useEffect(() => {
    if (props.match.params.id) {
      meetingData(props.match.params.id);
    }
  }, [props.match.params.id]);
  const meetingData = (id) => {
    MeetingService.getById(id).then((response) => {
      if (response.success) {
        setMeeting(response.data);
      } else {
        props.match.params.id !== 0 && ToastConfig.error('Server Error');
      }
    });
  };
  useEffect(() => {
    loadData(tabValue);
  }, [page, tabValue, term, takeCount]);

  useEffect(() => {
    if (searchName && searchName.searchName) {
      const search = searchName && searchName.searchName;
      setTabValue(1);
      setSearch(search.trim());
      setTerm(search.trim());
      SearchName(null);
    }
  }, []);

  const loadData = (activeIndex) => {
    setIsLoading(true);
    var raw = {
      Page: page - 1,
      TakeCount: takeCount,
      ActiveIndex: activeIndex,
      UserId: 0,
      Term: term,
      meetingId: props.match.params.id,
      orderDescending: true
    };
    RacesService.getPage(raw).then((response) => {
      if (activeIndex === tabValue) {
        if (response.success) {
          setRaces(response.data.data);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
          setTotalPageElements(response.data.totalElementsCount);
        }
      }
      setIsLoading(false);
    });
  };

  return (
    <>
      {!isLoading ? (
        <>
          <Grid item xs={12}>
            <Typography variant='subtitle1' sx={{ pt: 6, pb: 6 }}>
              {meeting && meeting.name ? (
                <>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={(e)=>onClickNavigation(e)}
                  >
                    Meetings
                  </span>{' '}
                  / {meeting.name}
                </>
              ) : (
                <>Meetings</>
              )}
            </Typography>
          </Grid>
          <Grid
            container
            background='#E5E5E5'
            spacing={6}
            className={matches ? classes.topButton : ' '}
          >
            <Grid item xs={12}>
              <Paper sx={{ borderRadius: '15px', boxShadow: 'none' }}>
                <Box
                  borderColor='#D7DEEC'
                  sx={{
                    background: '#FFFFFF',
                    border: '1px solid #FFFFFF',
                    boxSizing: 'borderBox',
                    borderRadius: '15px'
                  }}
                >
                  <Box sx={{ m: 1 }}>
                    <Grid item xs={12} sx={{ my: 4 }}>
                      <Grid container spacing={2} sx={{ px: 2 }}>
                        <Grid item xs={5} className='fullWidth'>
                          <Grid container spacing={2} className='fullWidth'>
                            <Grid item xs={8}>
                              <Typography variant='subtitle1'>
                                <b>
                                  {meeting && meeting.name ? meeting.name : ''}
                                </b>
                              </Typography>
                              {meeting && meeting.trackName && (
                                <Typography
                                  variant='subtitle1'
                                  sx={{ pt: 1, pb: 1 }}
                                >
                                  Track : {meeting.trackName}
                                </Typography>
                              )}
                            </Grid>
                            <Grid item xs={3}>
                              <MenuItem
                                disableRipple
                                onClick={handelMeetingEdit}
                              >
                                <EditIcon /> Edit
                              </MenuItem>
                            </Grid>
                          </Grid>
                          {meeting && meeting.startDate && (
                            <>
                              <Typography sx={{ pt: 1 }}>
                                START DATE -{''}{' '}
                                {dateMonthFormat(meeting.startDate)}
                              </Typography>
                            </>
                          )}
                          {meeting && meeting.endDate && (
                            <>
                              <Typography>
                                END DATE -{''}{' '}
                                {dateMonthFormat(meeting.endDate)}
                              </Typography>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant='subtitle1' sx={{ px: 2, pb: 2 }}>
                        <b>Races</b>
                      </Typography>
                      <Grid
                        container
                        spacing={2}
                        sx={{ px: 2 }}
                        className={matches ? classes.topButton : ' '}
                      >
                        <Grid item xs={5} className='fullWidth'>
                          <MuiTextField
                            placeholder='Search'
                            search={true}
                            id='search'
                            value={search}
                            onChange={(e) => setSearch(e.target.value)}
                            onKeyPress={(e) => {
                              if (e.key === 'Enter') {
                                setTerm(search);
                              }
                            }}
                          />
                        </Grid>
                        <Grid item xs={1} className='fullWidth'>
                          <Button
                            sx={{
                              bgcolor: 'primary.main',
                              textTransform: 'capitalize',
                              color: '#fff',
                              width: '100%',
                              ':hover': {
                                bgcolor: 'primary.dark',
                                color: 'white'
                              }
                            }}
                            onClick={(e) => setTerm(search)}
                          >
                            Submit
                          </Button>
                        </Grid>
                        <Grid item xs={1} className='fullWidth'>
                          <Button
                            sx={{
                              width: '100%',
                              background: '#FFF',
                              border: '1px solid #F1F1F5',
                              textTransform: 'capitalize'
                            }}
                            onClick={(e) => {
                              setSearch('');
                              setTerm('');
                            }}
                            variant='outlined'
                          >
                            Clear
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container spacing={2}>
                        <Grid item xs={6} className='fullWidth'>
                          <Tabs
                            onChange={handleTabChange}
                            value={tabValue}
                            aria-label='Users Tabs'
                            selectionFollowsFocus
                            sx={{ pb: 2 }}
                          >
                            <Tab
                              sx={{
                                letterSpacing: '0em',
                                textTransform: 'capitalize'
                              }}
                              label={'upcoming'}
                            />
                            <Tab
                              sx={{
                                letterSpacing: '0em',
                                textTransform: 'capitalize'
                              }}
                              label={'Finished'}
                            />
                          </Tabs>
                        </Grid>
                        <Grid
                          item
                          xs={6}
                          sx={{
                            pb: 2
                          }}
                          className='fullWidth'
                        >
                          <Button
                            sx={{
                              bgcolor: 'primary.main',
                              textTransform: 'capitalize',
                              color: '#fff',
                              float: 'right',
                              ':hover': {
                                bgcolor: 'primary.dark',
                                color: 'white'
                              }
                            }}
                            onClick={handelAdd}
                          >
                            <AddIcon /> Add A Race
                          </Button>

                          <Dialog
                            maxWidth={'lg'}
                            open={openMeetingDialog}
                            onClose={handleMeetingDialogClose}
                            PaperProps={{
                              sx: { minWidth: matches ? '100%' : '' }
                            }}
                          >
                            <DialogContent sx={{ maxWidth: '590px' }}>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography variant='h5' sx={{ pb: 6 }}>
                                    {' '}
                                    {meeting?.id > 0
                                      ? 'Edit Meeting Details'
                                      : 'Meeting Details'}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    style={{ float: 'right' }}
                                    onClick={handleMeetingDialogClose}
                                  >
                                    <IconButton>
                                      <CloseIcon />
                                    </IconButton>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <MeetingDetails
                                    id={meeting?.id ? meeting?.id : 0}
                                    currentItem={meeting ? meeting : ''}
                                    source={2}
                                    meetingData={meetingData}
                                    handleClose={handleMeetingDialogClose}
                                    meetingUpdate={true}
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions></DialogActions>
                          </Dialog>

                          {/* Add/Edit Race Start  */}
                          <Drawer
                            open={openDialog}
                            onClose={handleDialogClose}
                            anchor='right'
                            sx={{
                              flexShrink: 0,
                              [`& .MuiDrawer-paper`]: {
                                width: matches ? '100%' : '87%',
                                boxSizing: 'border-box'
                              }
                            }}
                          >
                            <CardContent
                              className={matches ? classes.topButton : ' '}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography variant='h5' sx={{ pb: 6 }}>
                                    {' '}
                                    {selectedItem?.id > 0 ? (
                                      <>
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e)=>onClickNavigation(e)}
                                        >
                                          Meetings
                                        </span>{' '}
                                        /
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => onClickNavigationName(e)}
                                        >
                                          {meeting && meeting.name
                                            ? meeting.name
                                            : ''}
                                        </span>{' '}
                                        /<b>Edit A Race</b>
                                      </>
                                    ) : (
                                      <>
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => onClickNavigation(e)}
                                        >
                                          Meetings
                                        </span>{' '}
                                        /
                                        <span
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) => onClickNavigationName(e)}
                                        >
                                          {meeting && meeting.name
                                            ? meeting.name
                                            : ''}
                                        </span>{' '}
                                        /<b>Add A Race</b>
                                      </>
                                    )}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography
                                    style={{ float: 'right' }}
                                    onClick={handleDialogClose}
                                  >
                                    <IconButton>
                                      <CloseIcon />
                                    </IconButton>
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <RaceDetails
                                    id={selectedItem?.id ? selectedItem?.id : 0}
                                    currentItem={selectedItem}
                                    meeting={meeting ? meeting : ''}
                                    source={2}
                                    handleClose={handleDialogClose}
                                    meetingId={meeting?.id ? meeting?.id : 0}
                                  />
                                </Grid>
                              </Grid>
                            </CardContent>
                          </Drawer>
                          {/* Add Edit Race End */}
                        </Grid>
                      </Grid>
                    </Grid>

                    {races.length > 0 ? (
                      <>
                        <TableContainer
                          className={matches ? classes.tableMobile : ' '}
                        >
                          <Table
                            sx={{
                              minWidth: matches ? '100%' : '650',
                              pt: 6,
                              pb: 6
                            }}
                            aria-label='simple table'
                            size='small'
                          >
                            <TableHead
                              sx={{ bgcolor: 'tableHeader' }}
                              className={classes.thead}
                            >
                              {headCells.map((headCell) => (
                                <TableCell key={headCell.id}>
                                  {headCell.disableSorting ? (
                                    headCell.label
                                  ) : (
                                    <>{headCell.label}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableHead>
                            <TableBody>
                              {races &&
                                races.map((item, i) => (
                                  <TableRow
                                    key={i}
                                    className={classes.tableRow}
                                  >
                                    <TableCell
                                      data-label='Name'
                                      onClick={
                                        tabValue === 1
                                          ? () => onClickRacesResult(item.id)
                                          : () => onClickhandelEdit(item)
                                      }
                                    >
                                      {item.name}
                                    </TableCell>
                                    <TableCell
                                      data-label='Race Info'
                                      onClick={
                                        tabValue === 1
                                          ? () => onClickRacesResult(item.id)
                                          : () => onClickhandelEdit(item)
                                      }
                                    >
                                      {item.information}
                                    </TableCell>
                                    <TableCell
                                      data-label='Start Racing Date Time'
                                      onClick={
                                        tabValue === 1
                                          ? () => onClickRacesResult(item.id)
                                          : () => onClickhandelEdit(item)
                                      }
                                    >
                                      {item.startDate && (
                                        <>
                                          {dateTimeAMPMFormat(item.startDate)}
                                        </>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      data-label='Distance'
                                      onClick={
                                        tabValue === 1
                                          ? () => onClickRacesResult(item.id)
                                          : () => onClickhandelEdit(item)
                                      }
                                    >
                                      {item.distance}
                                    </TableCell>
                                    <TableCell
                                      data-label='Mares Allowed'
                                      onClick={
                                        tabValue === 1
                                          ? () => onClickRacesResult(item.id)
                                          : () => onClickhandelEdit(item)
                                      }
                                    >
                                      {item.maresAllowanceAllowed === true
                                        ? 'Yes'
                                        : 'No'}
                                    </TableCell>
                                    <TableCell className='action'>
                                      <IconButton
                                        id='demo-customized-button'
                                        aria-controls='demo-customized-menu'
                                        aria-haspopup='true'
                                        aria-expanded={
                                          openAction ? 'true' : undefined
                                        }
                                        variant='contained'
                                        onClick={(e) => {
                                          handleClick(e, item);
                                        }}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <StyledMenu
                                        id='demo-customized-menu'
                                        MenuListProps={{
                                          'aria-labelledby':
                                            'demo-customized-button'
                                        }}
                                        anchorEl={anchorEl}
                                        open={openAction}
                                        onClose={handleClose}
                                      >
                                        {tabValue === 1 ? (
                                          <MenuItem
                                            onClick={racesResult}
                                            disableRipple
                                          >
                                            <EditIcon />
                                            View / Edit
                                          </MenuItem>
                                        ) : (
                                          <MenuItem
                                            onClick={handelEdit}
                                            disableRipple
                                          >
                                            <EditIcon />
                                            View / Edit
                                          </MenuItem>
                                        )}

                                        <MenuItem
                                          onClick={(e) => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                'Are you sure to delete this record?',
                                              subTitle:
                                                "You can't undo this operation",
                                              onConfirm: () => {
                                                handleSetStatus(deleteStatus);
                                              }
                                            });
                                            handleClose();
                                          }}
                                          disableRipple
                                        >
                                          <DeleteIcon />
                                          Delete
                                        </MenuItem>
                                      </StyledMenu>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <DevPagination
                          count={totalPages}
                          setPage={setPage}
                          currentPage={currentPage}
                          pageElements={totalPageElements}
                          takeCount={takeCount}
                          setTakeCount={setTakeCount}
                          page={page}
                          onChange={handleChange}
                          shape='rounded'
                        />
                      </>
                    ) : (
                      <NoData />
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </Grid>

          <DevConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default RaceList;
