import endpoints from "../constants/endpoints";
import { fetchWrapper } from "../helpers/fetchWrapper";

export const UserService = {
  getPage,
  getById,
  save,
  delete: _delete,
  getUserListByName,
};

function getById(id) {
  return fetchWrapper.get(endpoints.USERS.GET_BY_ID + id);
}
function getPage(params) {
  return fetchWrapper.post(endpoints.USERS.GET, params);
}
function save(params) {
  return fetchWrapper.post(endpoints.USERS.SAVE, params);
}
function _delete(params) {
  return fetchWrapper.post(endpoints.USERS.SET_STATUS, params);
}
function getUserListByName(params, callback) {
  return fetchWrapper.get(endpoints.USERS.GET_BY_NAME_ROLE + params).then(response => {
    callback(response)
  });
}