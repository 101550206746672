import React from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";

export const SeeMeetingsIcon = () => {
    const theme = useTheme();

    return (
        <Box sx={{ mr: '12px' }} >
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0C4.90909 0 1.39091 2.53636 0 6.13636C1.39091 9.73636 4.90909 12.2727 9 12.2727H9.9C9.81818 12.0273 9.81818 11.7818 9.81818 11.4545C9.81818 10.9636 9.9 10.5545 9.98182 10.0636C9.65454 10.1455 9.32727 10.2273 9 10.2273C6.70909 10.2273 4.90909 8.42727 4.90909 6.13636C4.90909 3.84545 6.70909 2.04545 9 2.04545C11.2909 2.04545 13.0909 3.84545 13.0909 6.13636C13.0909 6.38182 13.0909 6.62727 13.0091 6.87273C13.5818 6.70909 14.1545 6.54545 14.7273 6.54545C15.7091 6.54545 16.6091 6.79091 17.4273 7.36364C17.6727 6.95455 17.8364 6.54545 18 6.13636C16.6091 2.53636 13.0909 0 9 0ZM9 3.68182C7.60909 3.68182 6.54545 4.74545 6.54545 6.13636C6.54545 7.52727 7.60909 8.59091 9 8.59091C10.3909 8.59091 11.4545 7.52727 11.4545 6.13636C11.4545 4.74545 10.3909 3.68182 9 3.68182ZM14.7273 13.5V11.8636H11.4545V10.2273H14.7273V8.59091L17.1818 11.0455L14.7273 13.5Z" fill={theme.palette.primary.main} />
        </svg>
        </Box>
    );
};