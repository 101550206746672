import { createTheme } from '@mui/material/styles';


export const theme = createTheme({
    palette: {
        primary: {
            light: '#021746',
            main: '#012169',
            dark: '#021746',
            contrastText: '#FFF',
            disabled:'#7C84AB'
        },
        secondary: {
            light: '#012169',
            main: '#012169',
            dark: '#012169',
            contrastText: '#FFF',
        },
        tableHeader: "#E5E7ED",
        sideBar: "#E5E7ED",
        typography: {
            fontFamily: 'Poppins',
            style: 'normal'
        },
        shape: {
            borderRadius: '20'
        },
        body:'#f8f8f8'
    },
});
export const irtheme = createTheme({
    palette: {
        primary: {
            light: '#029A47',
            main: '#029A47',
            dark: '#02A023',
            contrastText: '#FFF',
            disabled:'#E8F0EC'
        },
        secondary: {
            light: '#FF7901',
            main: '#FF7901',
            dark: '#FF7901',
            contrastText: '#FFF',
        },
        tableHeader: "#E8F0EC",
        sideBar: "#E8F0EC",
        typography: {
            fontFamily: 'Poppins',
            style: 'normal'
        },
        shape: {
            borderRadius: '20'
        }
    },
});

