import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { getUserDetails, Logout } from '../helpers/util';
import { useHistory } from 'react-router';
import { useMediaQuery } from '@mui/material';
import { useTheme, withStyles } from '@mui/styles';
import paths from '../routing/paths';
import GBLogo from '../components/logo/Harness.png';
import IELogo from '../components/logo/IHRA_Logo.png';

import { useAuth } from '../context/UserContext';
import DehazeIcon from '@mui/icons-material/Dehaze';
const Logo = process.env.REACT_APP_ISO === 'IE' ? IELogo : GBLogo;

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5'
  }
})((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center'
    }}
    disableScrollLock={true}
    {...props}
  />
));

export default function MenuAppBar(props) {
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down('sm') ?? '600'
  );
  const { SideBarToggle } = useAuth();
  const toggle = useAuth();
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const history = useHistory();
  const theme = useTheme();

  const user = getUserDetails();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setAnchorEl(null);
    Logout();
    history.push(paths.DEFAULT);
  };
  const navigationToggle = () => {
    SideBarToggle(!toggle.toggle);
  };
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position='fixed' sx={{ bgcolor: theme.primary, color: '#fff' }}>
        <Toolbar
          sx={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            {matches && (
              <Box>
                <DehazeIcon
                  sx={{ cursor: 'pointer' }}
                  onClick={() => navigationToggle()}
                />
              </Box>
            )}
            <Box sx={{ mt: '4px' }}>
              <img width={'100%'} height={'70px'} src={Logo} alt='logo' />
            </Box>
          </Box>
          <Box>
            {auth && (
              <div>
                <IconButton
                  aria-controls='header-menu'
                  aria-haspopup='true'
                  onClick={handleClick}
                  color='primary'
                  style={{
                    width: matches ? '185px' : ' '
                  }}
                >
                  <AccountCircleIcon
                    style={{ color: '#ffffff', marginRight: '5px' }}
                  />
                  <Typography
                    style={{
                      color: '#fff',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    {user?.Name}
                  </Typography>
                </IconButton>
                <StyledMenu
                  id='header-menu'
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleLogout}>
                    <Typography color='primary'> Logout </Typography>
                  </MenuItem>
                </StyledMenu>
              </div>
            )}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
