import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  MenuItem,
  Menu,
  IconButton,
  Grid,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  Stack,
  Box,
  FormHelperText,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DevConfirmDialog from "../components/dialog/DevConfirmDialog";
import EditIcon from "@mui/icons-material/Edit";
import MuiTextField from "./MuiTextField";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { PlusIcon } from "./icons/PlusIcon";
import { CancelIcon } from "./icons/CancelIcon";
import InputMask from "react-input-mask";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { alpha, styled } from "@mui/system";
import StewardsCommentModel from "./StewardsCommentModel";
import { RacesService } from "../services/RecesServices";
import { makeStyles } from "@mui/styles";
import DevRaceOwnerHorsesTrainerDriverModel from "./DevRaceOwnerHorsesTrainerDriverModel";
import DevOnMousePopover from "./DevOnMousePopover";
const currency = process.env.REACT_APP_ISO === "IE" ? "€" : "£";

const headCells = [
  { id: "pi", label: "Finish", width: "3%" },
  { id: "time ", label: "Time", width: "10%" },
  { id: "horse ", label: "Horse", width: "9%" },
  { id: "prize ", label: "Prize", width: "10%" },
  { id: "position", label: "Draw", width: "5%" },
  { id: "offGate", label: "Trail", width: "14%" },
  { id: "Owner", label: "Owner(s)", width: "11%" },
  { id: "trainer", label: "Trainer", width: "11%" },
  { id: "driver", label: "Driver", width: "11%" },
  { id: "Edit", label: "Edit", width: "3%" },
  { id: "stewardComment", label: "Stewards’ Comments", width: "23%" },
  { id: "action", label: "ACTION", width: "5%" },
];

const useStyles = makeStyles((theme) => ({
  thead: {
    "& th:first-child": {
      borderRadius: "5px 0 0 5px",
    },
    "& th:last-child": {
      borderRadius: "0 5px 5px 0",
    },
    "& th": {
      borderBottom: "0px",
      textTransform: "uppercase",
    },
  },
  tdStyle: {
    background: "#fff",
    padding: "5px 10px 5px 10px",
    borderRadius: "5px",
  },
  tableMobile: {
    "& table": {
      border: 0,
      minWidth: "100%",
    },
    " & table caption": {
      fontSize: "1.3em",
    },
    "& table thead": {
      border: "none",
      clip: "rect(0 0 0 0)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
    },

    "& table tr": {
      borderBottom: "3px solid #ddd",
      display: "block",
      marginBottom: ".625em",
      border: "1px solid #ddd",
      borderRadius: "04px",
    },

    "& table td": {
      borderBottom: "1px solid #ddd",
      display: "block",
      fontSize: ".8em",
      textAlign: "right",
    },

    "& table td::before": {
      content: "attr(data-label)",
      float: "left",
      fontWeight: 500,
    },

    "& table td:last-child": {
      borderBottom: 0,
    },
    "& .avatar": {
      display: "flex",
      justifyContent: "end",
    },
    "& .roles": {
      display: "flex",
      justifyContent: "end",
    },
    "& .action": {
      textAlign: "left",
      padding: " 0px 0px 0px 5px",
    },
    "& .maskInputMobile": {
      width: "100% !important",
    },
  },
  maskInput: {
    borderRadius: "10px",
    height: "36px",
    border: "1px solid #333;",
    borderStyle: "solid",
    borderWidth: "1px",
    borderColor: "rgba(0, 0, 0, 0.23)",
    width: "100%",
    ":hover": {
      borderRadius: "10px",
      height: "36px",
      border: "1px solid #333;",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    ":focus": {
      borderRadius: "10px",
      height: "36px",
      border: "1px solid #333;",
      borderStyle: "solid",
      borderWidth: "1px",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
  },
}));
const StyledMenu = styled((props) => (
  <Menu
    disableScrollLock={true}
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));
export default function DevRacesResults(props) {
  const { raceHorses, updateFormik, errors, touched, handleBlur } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const [openDialogModel, setOpenDialogModel] = React.useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [horseIndex, setHorseIndex] = React.useState("");
  const [selectedHorse, setSelectedHorse] = React.useState({});
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const withdrawStatus = 1;
  const withdrawMessage = "Are you sure to withdraw this record?";

  const disqualifiedStatus = 2;
  const disqualifiedMessage = "Are you sure to disqualified this record?";
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const handleSetStatus = (statusType) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    var raw = {
      horseId: selectedItem.horseId,
      raceId: selectedItem.raceId,
      status: statusType,
    };
    RacesService.withdraw(raw).then((response) => {
      props.loadData(props.Id);
    });
    handleClose();
  };
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
    console.log(item);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedItem(null);
  };
  const handelEdit = () => {
    setOpenDialogModel(true);
    setAnchorEl(null);
  };
  const handleDialogClose = () => {
    setOpenDialogModel(false);
  };
  const handleDialog = (index, selectedItem) => {
    if (selectedItem) {
      setHorseIndex(index);
      setSelectedHorse(selectedItem);
      setOpenDialog(!openDialog);
    } else {
      setHorseIndex("");
      setSelectedHorse({});
      setOpenDialog(!openDialog);
    }
  };
  return (
    <>
      <DevRaceOwnerHorsesTrainerDriverModel
        matches={matches}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleDialog={handleDialog}
        raceHorses={raceHorses}
        updateFormik={updateFormik}
        horseIndex={horseIndex}
        selectedHorse={selectedHorse}
      />
      <TableContainer className={matches ? classes.tableMobile : " "}>
        <Table
          sx={{ minWidth: matches ? "100%" : "950", pt: 6, pb: 6 }}
          aria-label="simple table"
          size="small"
        >
          <TableHead sx={{ bgcolor: "tableHeader" }} className={classes.thead}>
            {headCells.map((headCell) => (
              <TableCell
                key={headCell.id}
                style={{ width: `${headCell.width ? headCell.width : ""}` }}
              >
                {headCell.disableSorting ? (
                  headCell.label
                ) : (
                  <>{headCell.label ? headCell.label : ""}</>
                )}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {raceHorses &&
              raceHorses.map((item, i) => {
                const Errors =
                  (errors.raceHorses?.length && errors.raceHorses[i]) || {};
                const Touched =
                  (touched.raceHorses?.length && touched.raceHorses[i]) || {};
                const ErrorsOwner =
                  (errors.item?.allOwners?.length &&
                    errors.item?.allOwners[i]) ||
                  {};
                const TouchedOwner =
                  (touched.item?.allOwners?.length &&
                    touched.item?.allOwners[i]) ||
                  {};
                return (
                  <>
                    <TableRow
                      sx={{
                        background:
                          item.status === 1 || item.status === 2
                            ? "rgba(255, 0, 0, 0.5)"
                            : "",
                      }}
                      key={i}
                    >
                      {item.status === 1 || item.status === 2 ? (
                        <>
                          {item.status === 1 && (
                            <>
                              <TableCell sx={{position:'relative'}}>
                                <Box sx={{ textAlign: "left" ,
                              position: 'absolute',
                              top: '4px',
                              left: '0'}}>
                                  <span
                                    style={{
                                      background: "#fff",
                                      padding: "3px",
                                      textAlign: "left",
                                      border: '2px solid'
                                    }}
                                  >
                                    Withdraw
                                  </span>
                                </Box>
                              </TableCell>
                              <TableCell></TableCell>
                            </>
                          )}
                          {item.status === 2 && (
                            <>
                              <TableCell>
                                <Box sx={{ textAlign: "left" }}>
                                  <span
                                    style={{
                                      background: "#fff",
                                      padding: "3px",
                                      borderRadius: "05px",
                                      textAlign: "left",
                                    }}
                                  >
                                    Disqualified
                                  </span>
                                </Box>
                              </TableCell>

                              <TableCell>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <Stack>
                                    <InputMask
                                      className={`${classes.maskInput} maskInputMobile`}
                                      mask="99:99.99"
                                      disabled={
                                        item.status === 2 ? true : false
                                      }
                                      name={`raceHorses.${i}.time`}
                                      value={raceHorses[`${i}`].time}
                                      onChange={(e) => {
                                        let event = raceHorses[`${i}`];
                                        event.time = e.target.value;
                                        updateFormik(
                                          `raceHorses.${i}`,
                                          event ? event : ""
                                        );
                                        console.log(e.target.value);
                                      }}
                                    />
                                  </Stack>
                                </LocalizationProvider>
                                <FormHelperText
                                  error={true}
                                  style={{
                                    display: "inline-block",
                                    float: "right",
                                    fontSize: "09px",
                                  }}
                                >
                                  {Errors.time && Touched.time && Errors.time}
                                </FormHelperText>
                              </TableCell>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <TableCell>{item?.rank}</TableCell>
                          <TableCell>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <Stack>
                                <InputMask
                                  className={`${classes.maskInput} maskInputMobile`}
                                  mask="99:99.99"
                                  disabled={
                                    item.status === 1 || item.status === 2
                                      ? true
                                      : false
                                  }
                                  name={`raceHorses.${i}.time`}
                                  value={raceHorses[`${i}`].time}
                                  onChange={(e) => {
                                    let event = raceHorses[`${i}`];
                                    event.time = e.target.value;
                                    updateFormik(
                                      `raceHorses.${i}`,
                                      event ? event : ""
                                    );
                                    console.log(e.target.value);
                                  }}
                                />
                              </Stack>
                            </LocalizationProvider>
                            <FormHelperText
                              error={true}
                              style={{
                                display: "inline-block",
                                float: "right",
                                fontSize: "09px",
                              }}
                            >
                              {Errors.time && Touched.time && Errors.time}
                            </FormHelperText>
                          </TableCell>
                        </>
                      )}

                      <TableCell data-label="Horse Name">
                        {item?.horseName ? item?.horseName : ""}
                      </TableCell>

                      <TableCell>
                        {item?.prize ? currency + item?.prize : ""}
                      </TableCell>
                      <TableCell>
                        <MuiTextField
                          type="number"
                          disabled={
                            item.status === 1 || item.status === 2
                              ? true
                              : false
                          }
                          sx={{
                            '& .MuiInputBase-root':{
                              width: '100%'
                            }
                          }}
                          placeholder={"Draw"}
                          onBlur={handleBlur}
                          name={`raceHorses.${i}.position`}
                          value={raceHorses[`${i}`].position}
                          onChange={(e) => {
                            let event = raceHorses[`${i}`];
                            event.position = e.target.value;
                            updateFormik(`raceHorses.${i}`, event ? event : "");
                          }}
                        />
                        <FormHelperText
                          error={true}
                          style={{
                            display: "inline-block",
                            float: "right",
                            fontSize: "09px",
                          }}
                        >
                          {Errors.position &&
                            Touched.position &&
                            Errors.position}
                        </FormHelperText>
                      </TableCell>

                      <TableCell>
                        <MuiTextField
                          disabled={
                            item.status === 1 || item.status === 2
                              ? true
                              : false
                          }
                          onBlur={handleBlur}
                          sx={{
                            '& .MuiInputBase-root':{
                              width: raceHorses[`${i}`].offGate && raceHorses[`${i}`].offGate.length <= 1  ? '58%' : ' 70%'
                            }
                          }}
                          name={`raceHorses.${i}.offGate`}
                          value={raceHorses[`${i}`].offGate}
                          onChange={(e) => {
                            let eventOffGate = raceHorses[`${i}`];
                            eventOffGate.offGate = e.target.value;
                            updateFormik(
                              `raceHorses.${i}`,
                              eventOffGate ? eventOffGate : ""
                            );
                          }}
                        />
                        <FormHelperText
                          error={true}
                          style={{
                            display: "inline-block",
                            float: "right",
                            fontSize: "09px",
                          }}
                        >
                          {Errors.offGate && Touched.offGate && Errors.offGate}
                        </FormHelperText>
                      </TableCell>
                      <TableCell
                        data-label={item?.allOwners && item.allOwners ? "Owner(s)" : ""}
                        sx={{
                          background: "#F4F5F5",
                          borderRadius: "5px 0px 0px 5px",
                        }}
                      >
                        {item?.allOwners &&  item?.allOwners.length > 0 && item.allOwners[0].name && (
                          <Box className={classes.tdStyle}>
                            {item.allOwners && item.allOwners?.length > 1 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "left",
                                  alignContent: "center",
                                }}
                              >
                                <Typography sx={{ pr: 2 }}>
                                  {" "}
                                  {item.allOwners[0].name}{" "}
                                </Typography>{" "}
                                <DevOnMousePopover
                                  source={4}
                                  count={item.allOwners.length - 1}
                                  items={item.allOwners}
                                  style={{ marginLeft: "5px" }}
                                  raceOwner={true}
                                ></DevOnMousePopover>{" "}
                              </Box>
                            )}
                            {item.allOwners?.length === 1 &&
                              item.allOwners[0].name}
                          </Box>
                        )}

                        {ErrorsOwner.name &&
                          TouchedOwner.name &&
                          ErrorsOwner.name && (
                            <FormHelperText
                              error={true}
                              style={{
                                display: "inline-block",
                                fontSize: "10px",
                              }}
                            >
                              {ErrorsOwner.name &&
                                TouchedOwner.name &&
                                ErrorsOwner.name}
                            </FormHelperText>
                          )}
                      </TableCell>

                      <TableCell
                        sx={{
                          background: "#F4F5F5",
                        }}
                        data-label="Trainer"
                      >
                        {item.trainer && (
                          <Box className={classes.tdStyle}>
                            {" "}
                            {item.trainer ? item.trainer : ""}
                          </Box>
                        )}
                        {Errors.trainer && Touched.trainer && Errors.trainer && (
                          <FormHelperText
                            error={true}
                            style={{
                              display: "inline-block",
                              fontSize: "10px",
                            }}
                          >
                            {Errors.trainer &&
                              Touched.trainer &&
                              Errors.trainer}
                          </FormHelperText>
                        )}
                      </TableCell>

                      <TableCell
                        sx={{
                          background: "#F4F5F5",
                        }}
                        data-label="Driver"
                      >
                        {item.driver && (
                          <Box className={classes.tdStyle}>
                            {item.driver ? item.driver : ""}
                          </Box>
                        )}
                        {Errors.driver && Touched.driver && Errors.driver && (
                          <FormHelperText
                            error={true}
                            style={{
                              display: "inline-block",
                              fontSize: "10px",
                            }}
                          >
                            {Errors.driver && Touched.driver && Errors.driver}
                          </FormHelperText>
                        )}
                      </TableCell>

                      <TableCell
                        data-label={item.horse ? "Edit" : ""}
                        sx={{
                          background: "#F4F5F5",
                          borderRadius: "0px 5px 5px 0px",
                        }}
                      >
                        {item?.horse && (
                          <Box
                            onClick={() => {
                              handleDialog(i, item);
                            }}
                            sx={{ cursor: "pointer" }}
                          >
                            <Tooltip title="Change Owners, Trainer and Driver">
                              <EditIcon />
                            </Tooltip>
                          </Box>
                        )}
                      </TableCell>

                      <TableCell
                        data-label={
                          item.stewardComment ? "Steward Comment" : ""
                        }
                      >
                        {item.stewardComment ? item.stewardComment : ""}
                      </TableCell>
                      <TableCell className="action">
                        <IconButton
                          id="demo-customized-button"
                          aria-controls="demo-customized-menu"
                          aria-haspopup="true"
                          aria-expanded={openAction ? "true" : undefined}
                          variant="contained"
                          onClick={(e) => {
                            handleClick(e, item);
                          }}
                        >
                          <MoreHorizIcon />
                        </IconButton>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={openAction}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={handelEdit} disableRipple>
                            <PlusIcon />
                            Add Stewards Comment
                          </MenuItem>
                          {selectedItem && selectedItem.status === 1 ? (
                            ""
                          ) : (
                            <>
                              <MenuItem
                                onClick={(e) => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: withdrawMessage,
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      handleSetStatus(withdrawStatus);
                                    },
                                  });
                                  handleClose();
                                }}
                                disableRipple
                              >
                                <CancelIcon /> Withdraw
                              </MenuItem>
                            </>
                          )}
                          {selectedItem && selectedItem.status === 2 ? (
                            ""
                          ) : (
                            <>
                              <MenuItem
                                onClick={(e) => {
                                  setConfirmDialog({
                                    isOpen: true,
                                    title: disqualifiedMessage,
                                    subTitle: "You can't undo this operation",
                                    onConfirm: () => {
                                      handleSetStatus(disqualifiedStatus);
                                    },
                                  });
                                  handleClose();
                                }}
                                disableRipple
                              >
                                <CancelIcon /> Disqualified
                              </MenuItem>
                            </>
                          )}
                        </StyledMenu>
                      </TableCell>
                    </TableRow>
                  </>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog
        maxWidth={"lg"}
        open={openDialogModel}
        onClose={handleDialogClose}
      >
        <DialogContent sx={{ maxWidth: "490px" }}>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography variant="h5" sx={{ pb: 1 }}>
                Stewards Comment
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                style={{ float: "right" }}
                onClick={handleDialogClose}
              >
                <IconButton>
                  <CloseIcon />
                </IconButton>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <StewardsCommentModel
                currentItem={selectedItem}
                loadData={props.loadData}
                Id={props.Id}
                source={2}
                handleClose={handleDialogClose}
                id={selectedItem?.id ? selectedItem?.id : 0}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
      <DevConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
}
