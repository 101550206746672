import React, { useContext, useEffect, useState } from 'react';

const AuthContext = React.createContext();

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [currentUserDetails, setCurrentUserDetails] = useState(null);
  const [token, setToken] = useState(null);
  const [signed, setSigned] = useState(false);
  const [toggle, setSideBarToggle] = useState(false);
  async function UpdateUser(user, profile) {
    if (user) {
      setUser(user);

      sessionStorage.setItem('@App:user', JSON.stringify(user));
      sessionStorage.setItem('@App:token', user.access_token);
    }
    if (profile) {
      setCurrentUserDetails(user);
    }
  }
  useEffect(() => {
    const storagedUser = sessionStorage.getItem('@App:user');
    const storagedToken = sessionStorage.getItem('@App:token');

    if (storagedToken && storagedUser) {
      const storagedUserObj = JSON.parse(storagedUser);
      setSigned(true);
      setToken(storagedToken);
      if (storagedUserObj.userData) {
        setCurrentUserDetails(JSON.parse(storagedUserObj.userData));
      }
    }
  }, [user]);
  function SearchName(data) {
      setSearchName(data);
  }
  async function SideBarToggle(data) {
    setSideBarToggle(data);
  }
  function Logout() {
    setUser(null);
    setSigned(false);
    sessionStorage.removeItem('@App:user');
    sessionStorage.removeItem('@App:token');
  }
  return (
    <AuthContext.Provider
      value={{
        signed,
        user,
        token,
        currentUserDetails,
        UpdateUser,
        Logout,
        SearchName,
        searchName,
        SideBarToggle,
        toggle
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
