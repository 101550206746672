import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  IconButton,
  Button,
  Grid,
} from "@mui/material";
import NotListedLocationIcon from "@mui/icons-material/NotListedLocation";
import { makeStyles } from "@mui/styles";
import { LoadingIcon } from "../icons/LoadingIcon";

const useStyles = makeStyles((theme) => ({
  dialog: {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  dialogTitle: {
    textAlign: "center",
  },
  dialogContent: {
    textAlign: "center",
  },
  dialogAction: {
    justifyContent: "center",
  },
  titleIcon: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      cursor: "default",
      color: "#000",
    },
    "& .MuiSvgIcon-root": {
      fontSize: "4rem",
    },
  },
}));

export default function DevConfirmDialog(props) {
  const { confirmDialog, setConfirmDialog, isLoading } = props;
  const classes = useStyles();

  return (
    <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
      <DialogTitle className={classes.dialogTitle}>
        <IconButton disableRipple className={classes.titleIcon}>
          <NotListedLocationIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <Typography variant="h6">{confirmDialog.title}</Typography>
        {confirmDialog?.subTitle && (
          <Typography variant="subtitle2">{confirmDialog.subTitle}</Typography>
        )}
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        {confirmDialog?.microchip ? (
          <Grid item xs={12} display="flex" justifyContent={"center"}>
            <Button
              color="primary"
              onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }
              sx={{
                ":hover": {
                  bgcolor: "primary.dark",
                  color: "white",
                },
              }}
            >
              OK
            </Button>
          </Grid>
        ) : (
          <>
            <Button
              onClick={() =>
                setConfirmDialog({ ...confirmDialog, isOpen: false })
              }
            >
              No
            </Button>
            {confirmDialog?.loading ? (
              <>
                {!isLoading ? (
                  <Button
                    color="primary"
                    onClick={() => confirmDialog.onConfirm()}
                    sx={{
                      ":hover": {
                        bgcolor: "primary.dark",
                        color: "white",
                      },
                    }}
                  >
                    Yes
                  </Button>
                ) : (
                  <LoadingIcon />
                )}
              </>
            ) : (
              <Button
                color="primary"
                onClick={() => confirmDialog.onConfirm()}
                sx={{
                  ":hover": {
                    bgcolor: "primary.dark",
                    color: "white",
                  },
                }}
              >
                Yes
              </Button>
            )}
          </>
        )}
      </DialogActions>
    </Dialog>
  );
}
