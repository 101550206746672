import React, { useEffect } from 'react';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateRangePicker from '@mui/lab/DateRangePicker';
import MuiTextField from './MuiTextField';
import { FormHelperText, Grid, Typography } from '@mui/material';
import { dateFormat } from '../helpers/util';
export default function CustomDateRangeInputs(props) {
  const {
    setFieldValue,
    errors,
    touched,
    startDateLabel,
    endDateLabel,
    inputFormat,
    startDate,
    endDate
  } = props;
  const [value, setValue] = React.useState([startDate, endDate]);
  useEffect(() => {
           setFieldValue('startDate', value ? value[0] : null);
           setFieldValue('endDate', value ? value[1] : null);
  }, [value]);

 
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateRangePicker
          label='Advanced keyboard'
          value={value}
          inputFormat={inputFormat}
          onChange={(e) => {
            setValue(e);
          }}
          renderInput={(startProps, endProps) => (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography>{startDateLabel} *</Typography>
                  <MuiTextField
                    ref={startProps.inputRef}
                    {...startProps.inputProps}
                    placeholder={startDateLabel}
                    value={startDate?dateFormat(startDate):''}
                  />
                  <FormHelperText
                    error={true}
                    style={{ display: 'inline-block', float: 'right' }}
                  >
                    {errors.startDate && touched.startDate && errors.startDate}
                  </FormHelperText>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{endDateLabel} *</Typography>
                  <MuiTextField
                    ref={endProps.inputRef}
                    {...endProps.inputProps}
                    placeholder={endDateLabel}
                    value={endDate?dateFormat(endDate):''}
                  />
                  <FormHelperText
                    error={true}
                    style={{ display: 'inline-block', float: 'right' }}
                  >
                    {errors.endDate && touched.endDate && errors.endDate}
                  </FormHelperText>
                </Grid>
              </Grid>
            </React.Fragment>
          )}
        />
      </LocalizationProvider>
    </>
  );
}
