import React from "react";
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import { AuthProvider } from "./context/UserContext";
import 'react-toastify/dist/ReactToastify.css';
import MainRouter from "./routing/MainRouter";
import { irtheme, theme } from "./theme";

function App() {



  return (

    <ThemeProvider theme={process.env.REACT_APP_ISO === "IE" ? irtheme : theme}>
      <AuthProvider>
        <>
          <MainRouter />
          <ToastContainer position="top-center"
            autoClose={4000}
            hideProgressBar
            newestOnTop
            closeOnClick
            rtl={false}
            pauseOnVisibilityChange
            draggable
            pauseOnHover
            limit={1}
          />
        </>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
