import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Button, CardContent, Grid, Typography } from '@mui/material';
import MuiTextField from './MuiTextField';
import { RacesService } from '../services/RecesServices';
import ToastConfig from './toast/toast';
const StewardsCommentModel = (props) => {
  const { currentItem } = props;
  const validationSchema = yup.object({
    stewardComment: yup
      .string('Enter  Comment')
      .required(' Comment is required')
  });
  const { handleSubmit, handleChange, touched, values, errors } = useFormik({
    initialValues: {
      id: 0,
      stewardComment: '',
      horseId: '',
      raceId: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = values;
      data.horseId = currentItem.horseId;
      data.raceId = currentItem.raceId;

      RacesService.saveStewardsComment(data).then((response) => {
        if (response.success) {
          props.loadData(props.Id);
          ToastConfig.success('Stewards Comment  successfully saved');
          props.handleClose();
        } else ToastConfig.error(response.statusCode);
      });
    }
  });
  return (
    <>
      <form onSubmit={handleSubmit} id='MeetingAddEditForm'>
        <CardContent>
          <Grid container spacing={2}>
            <Grid container item xs={12}>
              <Typography>Comment *</Typography>
            </Grid>
            <Grid item xs={12}>
              <MuiTextField
                name='stewardComment'
                multiline
                minRows={4}
                placeholder='GS L 1 SWAB'
                maxRows={4}
                value={values.stewardComment}
                onChange={handleChange}
                error={touched.stewardComment && Boolean(errors.stewardComment)}
                helperText={touched.stewardComment && errors.stewardComment}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid
              item
              container
              justifyContent={'end'}
              alignContent={'center'}
              md={12}
            >
              {props.source === 2 && (
                <Button
                  sx={{
                    bgcolor: 'primary.main',
                    color: '#fff',
                    float: 'right',
                    marginTop: '30px',
                    ':hover': {
                      bgcolor: 'primary.dark',
                      color: 'white'
                    }
                  }}
                  type='submit'
                >
                  Save
                </Button>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </form>
    </>
  );
};

export default StewardsCommentModel;
