import React from 'react';
import { View as PdfView } from '@react-pdf/renderer';

const View = ({ className, pdfMode, children }) => {
  return (
    <>
      {pdfMode ? (
        <PdfView style={className} wrap={false}>{children}</PdfView>
      ) : (
        <div 
        style={className}>{children}</div>
      )}
    </>
  );
};

export default View;
