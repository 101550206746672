import { Avatar, Badge } from "@mui/material";
import { ProfileEditIcon } from "./icons/ProfileEditIcon";
import IconButton from '@mui/material/IconButton';
import { useEffect, useRef, useState } from "react";
import { useTheme } from "@emotion/react";
import t from "prop-types";
import endpoints from "../constants/endpoints";
import ToastConfig from "./toast/toast";
import { allowedProfileExtensions } from "../helpers/util";

import("screw-filereader");

function AvatarPicker(props) {
  const updatevalue= props.updatevalue;
  const [file, setFile] = useState("");
  const imageRef = useRef();
  const { handleChangeImage, avatarImage } = props;
  useEffect(() => {
    if (!file && avatarImage) {
      setFile(URL.createObjectURL(avatarImage));
    }

    return () => {
      if (file) URL.revokeObjectURL(file);
    };
  }, [file, avatarImage]);

  const renderImage = (fileObject) => {
    fileObject.image().then((img) => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const maxWidth = 256;
      const maxHeight = 256;

      const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
      const width = (img.width * ratio + 0.5) | 0;
      const height = (img.height * ratio + 0.5) | 0;

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        const resizedFile = new File([blob], file.name, fileObject);
        setFile(URL.createObjectURL(resizedFile));
        handleChangeImage(resizedFile);
      });
    });
  };

  const showOpenFileDialog = () => {
    imageRef.current.click();
  };

  const handleChange = (event) => {

    const fileObject = event.target.files[0];
    if (!fileObject) return;
    renderImage(fileObject);
    uploadFile(event);
  };

  const uploadFile = (e) => { 
    if (!allowedProfileExtensions.exec(e.target.value)) {
      return ToastConfig.error("Selected file type not supported")
  }
    
    if (e.target.files[0]) {
        let data = new FormData();
        data.append("fileName", e.target.files[0].name);
        data.append("FormFile", e.target.files[0]);
        data.append("fileType", 0);

        fetch(endpoints.UPLOAD.SAVE, {
            method: 'post',
            body: data,
        }).then(response => response.text()).then(result => { updatevalue("profilePictureUrl", result) })
            .catch(error => console.log('error', error));
    }

}

  return (<>
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      badgeContent={
        <IconButton
          onClick={showOpenFileDialog}
        >
          <ProfileEditIcon />
        </IconButton>
      }
    >
      <Avatar
        alt={"avatar"} src={file ? file : props.url}
        sx={{ width: 114, height: 108 }}
      />
    </Badge>
    <input
      ref={imageRef}
      type="file"
      style={{ display: "none" }}
      accept="image/png,image/jpeg"
      onChange={handleChange}
    />

  </>);
}
AvatarPicker.propTypes = {
  handleChangeImage: t.func.isRequired,
  avatarImage: t.object
};

export default AvatarPicker;