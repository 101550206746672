import { Box } from '@mui/material';
import React from 'react';

export const SearchIcon = () => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M2.1221 10.758C-0.262704 8.37318 -0.262704 4.50666 2.1221 2.12185C4.5069 -0.262948 8.37343 -0.262948 10.7582 2.12185C12.9381 4.30176 13.1254 7.71973 11.3201 10.1124L13.7293 12.5216L13.7292 12.5217C14.3576 13.1502 13.4149 14.0929 12.7863 13.4644L10.406 11.084C8.00671 13.1373 4.39276 13.0286 2.1221 10.758ZM9.81529 3.06467C7.95119 1.20056 4.92888 1.20056 3.06478 3.06467C1.20067 4.92877 1.20067 7.95108 3.06478 9.81518C4.92888 11.6793 7.95119 11.6793 9.81529 9.81518C11.6794 7.95108 11.6794 4.92877 9.81529 3.06467Z'
        fill='#979797'
      />
    </svg>
  );
};
