import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

export const CancelIcon = () => {
  const theme = useTheme();

  return (
    <Box sx={{ mr: '12px' }}>
      <svg
        width={18}
        height={14}
        viewBox='0 0 8 8'
        fill={theme.palette.primary.main}
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M6.27262 0.211928L3.99978 2.48477L1.72694 0.211928C1.44804 -0.0669689 0.99568 -0.0669688 0.716783 0.211928L0.211707 0.717004C-0.0671902 0.995901 -0.0671901 1.44826 0.211707 1.72716L2.48455 4L0.211707 6.27284C-0.0671901 6.55174 -0.0671902 7.0041 0.211707 7.283L0.716783 7.78807C0.99568 8.06697 1.44804 8.06697 1.72694 7.78807L3.99978 5.51523L6.27262 7.78807C6.55152 8.06697 7.00388 8.06697 7.28277 7.78807L7.78785 7.283C8.06675 7.0041 8.06675 6.55174 7.78785 6.27284L5.51501 4L7.78785 1.72716C8.06675 1.44826 8.06675 0.995901 7.78785 0.717004L7.28277 0.211928C7.00388 -0.0669688 6.55152 -0.0669687 6.27262 0.211928Z'
          fill='#012169'
        />
      </svg>
    </Box>
  );
};
