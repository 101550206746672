import {
  CardContent,
  Dialog,
  Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function DevUserHorsesModel(props) {
  const handleClose = () => {
    onClose();
  };

  const { onClose, open, horses } = props;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='title'
      open={open}
      maxWidth={'lg'}
    >
      <CardContent>
        <Grid container>
          <Grid item xs={6}>
            <Typography variant='h5' sx={{ pb: 6 }}>
              Own Horses
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <IconButton style={{ float: 'right' }} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>

          {horses &&
            horses.map((horse) => (
              <Grid Item button key={horse.id} lg={6} md={6} xs={12}>
                <Grid
                  container
                  direction={'row'}
                  justifyItems={'center'}
                  alignItems={'stretch'}
                  xs={12}
                  spacing={3}
                >
                  <Grid item xs={9}>
                    {' '}
                    <Typography>{horse.name}</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    {' '}
                    <Typography>{horse.procent + '%'} </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Dialog>
  );
}

export default DevUserHorsesModel;
