export default {
  DEFAULT: '/',
  SIGNIN: '/signin',
  FORGOT_PASSWORD: '/forgot-password',
  USER_LIST: '/users',
  USER_DETAILS: '/users/:id',
  HORSE_LIST: '/horses',
  HORSE_DETAILS: '/horses/:id',
  SYNDICATE_LIST: '/syndicates',
  SYNDICATE_DETAILS: '/syndicates/:id',
  TRACK_LIST: '/tracks',
  TRACK_DETAILS: '/tracks/:id',
  MEETING_LIST: '/meetings',
  MEETING_DETAILS: '/meetings/:id',
  RACE_LIST: '/race/:id',
  RACE_DETAILS: '/race/detail/:id',
  RACE_RESULTS: '/race/:id/:meetingId',
  HISTORY: '/history',
  MICROCHIP: '/microchip',
  REPORT: '/reports'
};
