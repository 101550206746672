import { useTheme } from "@mui/styles";
import React from "react";

export const ProfileEditIcon = () => {
    const theme = useTheme();

    return (
        <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M23 13C23 19.1498 18.2258 24 12.5 24C6.77422 24 2 19.1498 2 13C2 6.85021 6.77422 2 12.5 2C18.2258 2 23 6.85021 23 13Z" fill="#C4C4C4" stroke="white" strokeWidth="4" />
            <path d="M8 15.1253V17H9.87474L15.404 11.4708L13.5292 9.59603L8 15.1253ZM16.8538 10.021C17.0487 9.826 17.0487 9.51104 16.8538 9.31607L15.6839 8.14623C15.489 7.95126 15.174 7.95126 14.979 8.14623L14.0642 9.0611L15.9389 10.9358L16.8538 10.021Z" fill={theme.palette.primary.main} />
        </svg>


    );
};