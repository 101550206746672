import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import MuiTextField from './MuiTextField';
import { FormHelperText } from '@mui/material';

export default function DevAutoComplete(props) {
  const { setFieldValue, options, placeholder, Id, role } = props;
  const [value, setValue] = React.useState('');
  React.useEffect(() => {
    if (Id && Id?.id > 0) {
      setValue(Id);
      setFieldValue(`${role}`, Id.id ? Id.id : '');
    }
  }, [Id]);
  return (
    <>
      <Autocomplete
        size='small'
        sx={{ minWidth: 250, width: '100%' }}
        options={options}
        value={value ? value : ''}
        onChange={(e, value) => {
          setValue(value ? value : '');
          setFieldValue(`${role}`, value && value.id ? value.id : '');
        }}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.name
        }
        autoComplete
        includeInputInList
        filterSelectedOptions
        renderInput={(params) => (
          <MuiTextField {...params} fullWidth placeholder={placeholder} />
        )}
      />
    </>
  );
}
