import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  bigScreen: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
      paddingLeft: '8px',
      width: '30px',
      height: '20px'
    }
  },
  smallScreen: {
    display: 'flex',
    flexWrap: 'wrap',
    '& > :not(style)': {
      padding: '5px',
      width: '16px',
      height: '15px'
    }
  }
}));
export default function DevOnMousePopover(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down('sm') ?? '600'
  );
  const open = Boolean(anchorEl);

  return (
    <>
      <Box
      

      className={matches ? classes.smallScreen : classes.bigScreen}>
        <Paper
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup='true'
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          elevation={6}
        >
          +{props.count ? props.count : 2}
        </Paper>
      </Box>
      <Popover
        id='mouse-over-popover'
        sx={{
          pointerEvents: 'none'
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <List>
          {props.items.map((item, i) => (
            <ListItemText key={i}>
              {props.source === 1 && (
                <Typography sx={{ p: 1 }}>
                  <b>{item.userType}</b> / License - {item.licenceStatus}
                </Typography>
              )}
              {props.source === 2 && <></>}
              {props.source === 3 && (
                <Typography sx={{ p: 1 }}>
                  {item.fullName} - {item.procent}
                  {'%'}{' '}
                </Typography>
              )}
              {props.source === 4 && <Typography sx={{ p: 1 }}>
                  {item.name} 
                </Typography>}
            </ListItemText>
          ))}
        </List>
      </Popover>
    </>
  );
}
