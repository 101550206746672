import React from 'react';

const ForgotPassword = (props) => { 
    return (
        <>
            <p>Forgot Password Page</p>
        </>
    );
}

export default ForgotPassword;