import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Button,
  Typography,
  Menu,
  useMediaQuery,
} from "@mui/material";
import { NoData } from "../../components/icons/NoData";
import DevConfirmDialog from "../../components/dialog/DevConfirmDialog";
import MuiTextField from "../../components/MuiTextField";
import { alpha, styled } from "@mui/system";
import Moment from "react-moment";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";
import DevPagination from "../../components/DevPagination";
import DevOnMousePopover from "../../components/DevOnMousePopover";
import { HorseService } from "../../services/HorseServices";
import { makeStyles } from "@mui/styles";
import { PageLoading } from "../../components/icons/PageLoading";
import Checkbox from "@mui/material/Checkbox";
import endpoints from "../../constants/endpoints";
const headCells = [
  { id: "checkbox", label: "" },
  { id: "name", label: "NAME" },
  { id: "dateofbirth", label: "DATE OF BIRTH" },
  { id: "currentOwner", label: "OWNER(S)" },
  { id: "currentTrainer", label: "CURRENT TRAINER" },
  { id: "gait", label: "GAIT" },
  { id: "Handicap", label: "HANDICAP" },
  { id: "sex", label: "SEX" },
];
const useStyles = makeStyles((theme) => ({
  thead: {
    "& th:first-child": {
      borderRadius: "5px 0 0 5px",
    },
    "& th:last-child": {
      borderRadius: "0 5px 5px 0",
    },
    "& .MuiTableCell-root": {
      padding: "8px 16px",
    },
    "& th": {
      borderBottom: "0px",
    },
  },
  tableRow: {
    "&  td": {
      cursor: "pointer",
    },
  },
  tableMobile: {
    "& table": {
      border: 0,
    },
    " & table caption": {
      fontSize: "1.3em",
    },
    "& table thead": {
      border: "none",
      clip: "rect(0 0 0 0)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
    },

    "& table tr": {
      borderBottom: "3px solid #ddd",
      display: "block",
      marginBottom: ".625em",
      border: "1px solid #ddd",
      borderRadius: "04px",
    },

    "& table td": {
      borderBottom: "1px solid #ddd",
      display: "block",
      fontSize: ".8em",
      textAlign: "right",
    },

    "& table td::before": {
      content: "attr(data-label)",
      float: "left",
      fontWeight: 500,
    },

    "& table td:last-child": {
      borderBottom: 0,
    },
    "& .avatar": {
      display: "flex",
      justifyContent: "end",
    },
    "& .roles": {
      display: "flex",
      justifyContent: "end",
    },
    "& .action": {
      textAlign: "left",
      padding: " 0px 0px 0px 5px",
    },
  },
  topButton: {
    "& .fullWidth": {
      maxWidth: "100% !important",
      minWidth: "100%",
      width: "100% !important",
    },
  },
}));
const StyledMenu = styled((props) => (
  <Menu
    disableScrollLock={true}
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const Microchip = (props) => {
  const [checkbox, setCheckbox] = useState(false);
  const [isLoadingSubmit, setIsHandleSubmit] = useState(false);
  const [recordIdArray, setRecordIdArray] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState("");
  const [term, setTerm] = useState("");
  const classes = useStyles();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageElements, setTotalPageElements] = useState(0);
  const [takeCount, setTakeCount] = useState(10);
  const [page, setPage] = useState(1);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );

  const handleChange = (event, value) => {
    setPage(value);
    setTakeCount(10);
  };

  const downloadMicrochipReports = async (filterIds) => {
    setIsHandleSubmit(true);
    let filterProperty =
      filterIds &&
      filterIds.map((item) => {
        return item?.id;
      });
    let data = {
      horseIdList: filterProperty,
    };

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    await fetch(endpoints.REPORTS.GET_MICROCHIP, requestOptions)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        let dateTime = new Date();
        a.href = url;
        a.download = `microchip.${dateTime.toString()}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setConfirmDialog({
          ...confirmDialog,
          isOpen: false,
        });
        setRecordIdArray([]);
        setCheckbox(false);
        setRecords(
          records.map((item) => {
            return {
              ...item,
              check: false,
            };
          })
        );
        setIsHandleSubmit(false);
      });
  };

  const loadData = () => {
    setIsLoading(true);
    var raw = {
      Page: page - 1,
      TakeCount: takeCount,
      ActiveIndex: 0,
      UserId: 0,
      Term: term,
    };
    HorseService.getPage(raw).then((response) => {
      if (response.success) {
        let horseData = response?.data?.data ? response?.data?.data : [];
        setRecords(
          horseData.map((item) => {
            return {
              ...item,
              check: false,
            };
          })
        );
        var records = horseData.map((item) => {
          return {
            ...item,
            check: false,
          };
        });
        if (records && records.length > 0) {
          if (
            records.every((element) =>
              recordIdArray.some((x) => x.id === element?.id)
            )
          ) {
            setCheckbox(true);
          } else {
            setCheckbox(false);
          }
        }
        setTotalPages(response.data.totalPages);
        setCurrentPage(response.data.currentPage);
        setTotalPageElements(response.data.totalElementsCount);
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    loadData();
    return () => {};
  }, [page, term, takeCount]);
  const handleSelectAll = (e) => {
    let pageRecordIds = records.map((item, index) => {
      return {
        id: item?.id,
      };
    });
    if (pageRecordIds && pageRecordIds.length > 0) {
      setCheckbox(e.target.checked);
      if (e.target.checked) {
        setRecords(
          records.map((item) => {
            return {
              ...item,
              check: true,
            };
          })
        );

        setRecordIdArray((nums) => {
          let data = new Set([...nums, ...pageRecordIds?.map((o) => o)]);
          return [...new Set([...data])];
        });
      } else {
        setRecords(
          records.map((item) => {
            return {
              ...item,
              check: false,
            };
          })
        );

        let pageRecordIds = records.map((item, index) => {
          return {
            id: item?.id,
          };
        });
        let filterarry = recordIdArray.filter(
          (i) => !pageRecordIds?.find((f) => f.id === i.id)
        );
        setRecordIdArray([...filterarry]);
      }
    }
  };

  const handleSelectedItem = (e, updatedIndex, item, record) => {
    let pId = item;
    if (e.target.checked) {
      setRecords(
        records.map((item, index) => {
          if (updatedIndex === index) {
            return {
              ...item,
              check: true,
            };
          } else {
            return { ...item };
          }
        })
      );
      let recordObject = {
        id: pId,
      };

      const _recordIdArray = [...(recordIdArray ?? []), recordObject];
      setRecordIdArray(_recordIdArray);
      if (
        records.every((element) =>
          _recordIdArray.some((x) => x.id === element?.id)
        )
      ) {
        setCheckbox(true);
      } else {
        setCheckbox(false);
      }
    } else {
      setCheckbox(false);
      setRecords(
        records.map((item, index) => {
          if (updatedIndex === index) {
            return {
              ...item,
              check: false,
            };
          } else {
            return { ...item };
          }
        })
      );
      let _recordIdArray = recordIdArray.filter((item) => item.id !== pId);
      setRecordIdArray(_recordIdArray);
    }
  };

  const handleMicrochipReports = () => {
    if (recordIdArray.length === 0) {
      setConfirmDialog({
        isOpen: true,
        title: "Download microchip report",
        subTitle: "You must select one or more record to download the report.",
        microchip: true,
      });
    } else {
      let filterUniq = recordIdArray.filter(
        (v, i, a) => a.findIndex((v2) => v2.id === v.id) === i
      );
      setRecordIdArray(filterUniq);
      if (filterUniq.length > 0) {
        setConfirmDialog({
          isOpen: true,
          title: "Download microchip report",
          subTitle: `Are you sure you want to download the report for ${
            filterUniq.length
          } ${filterUniq.length > 1 ? "records" : "record"}?
          `,
          loading: true,
          onConfirm: () => {
            downloadMicrochipReports(filterUniq);
          },
        });
      }
    }
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ pt: 6, pb: 6 }}>
          <b>Microchip report</b>
        </Typography>
      </Grid>
      <Grid container background="#E5E5E5" spacing={6}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            className={matches ? classes.topButton : " "}
          >
            <Grid item xs={5} className="fullWidth">
              <MuiTextField
                placeholder="Search"
                search={true}
                id="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    setTerm(search);
                  }
                }}
              />
            </Grid>
            <Grid item xs={1} className="fullWidth">
              <Button
                sx={{
                  bgcolor: "primary.main",
                  textTransform: "capitalize",
                  color: "#fff",
                  width: "100%",
                  ":hover": {
                    bgcolor: "primary.dark",
                    color: "white",
                  },
                }}
                onClick={(e) => setTerm(search)}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={1} className="fullWidth">
              <Button
                sx={{
                  width: "100%",
                  background: "#FFF",
                  border: "1px solid #F1F1F5",
                  textTransform: "capitalize",
                }}
                onClick={(e) => {
                  setSearch("");
                  setTerm("");
                }}
                variant="outlined"
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2} className="fullWidth">
              <Button
                sx={{
                  bgcolor: "primary.main",
                  textTransform: "capitalize",
                  color: "#fff",
                  float: "right",
                  ":hover": {
                    bgcolor: "primary.dark",
                    color: "white",
                  },
                }}
                onClick={(e) => {
                  handleMicrochipReports();
                }}
              >
                <VerticalAlignBottomIcon /> Download
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ borderRadius: "15px", boxShadow: "none" }}>
            <Box
              borderColor="#D7DEEC"
              sx={{
                background: "#FFFFFF",
                border: "1px solid #FFFFFF",
                boxSizing: "borderBox",
                borderRadius: "15px",
              }}
            >
              <Box sx={{ m: 1 }}>
                {!isLoading ? (
                  <>
                    {records.length > 0 ? (
                      <>
                        <TableContainer
                          className={matches ? classes.tableMobile : " "}
                        >
                          <Table
                            sx={{
                              minWidth: matches ? "100%" : "650",
                              pt: 6,
                              pb: 6,
                            }}
                            aria-label="simple table"
                            size="small"
                          >
                            <TableHead
                              sx={{ bgcolor: "tableHeader" }}
                              className={classes.thead}
                            >
                              {headCells.map((headCell) => (
                                <TableCell key={headCell.id}>
                                  {headCell.disableSorting ? (
                                    headCell.label
                                  ) : (
                                    <>{headCell.label}</>
                                  )}
                                  {headCell.id === "checkbox" && (
                                    <>
                                      <Checkbox
                                        checked={checkbox}
                                        onChange={(e) => {
                                          handleSelectAll(e);
                                        }}
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        size="small"
                                      />
                                    </>
                                  )}
                                </TableCell>
                              ))}
                            </TableHead>
                            <TableBody>
                              {records &&
                                records.map((item, i) => (
                                  <TableRow
                                    key={i}
                                    className={classes.tableRow}
                                  >
                                    <TableCell>
                                      <Checkbox
                                        size="small"
                                        checked={
                                          recordIdArray &&
                                          recordIdArray.some(
                                            (x) => x.id === item?.id
                                          )
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        onChange={(e) => {
                                          handleSelectedItem(
                                            e,
                                            i,
                                            item?.id,
                                            item
                                          );
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell data-label="Name">
                                      {item.name}
                                    </TableCell>
                                    <TableCell data-label="Date Of Birth">
                                      <Moment format="DD/MM/yyyy">
                                        {item.dateOfBirth}
                                      </Moment>
                                    </TableCell>
                                    <TableCell data-label="Owner(S)">
                                      {item.owners?.length > 1 && (
                                        <Box
                                          className="roles"
                                          sx={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "left",
                                            alignContent: "center",
                                          }}
                                        >
                                          <Typography sx={{ pr: 2 }}>
                                            {" "}
                                            {item.owners[0].fullName}{" "}
                                          </Typography>{" "}
                                          <DevOnMousePopover
                                            source={3}
                                            count={item.owners.length - 1}
                                            items={item.owners}
                                            style={{ marginLeft: "5px" }}
                                          ></DevOnMousePopover>{" "}
                                        </Box>
                                      )}
                                      {item.owners?.length === 1 &&
                                        item.owners[0].fullName}
                                    </TableCell>

                                    <TableCell data-label="Current Trainer">
                                      {item.tranienrFullName}
                                    </TableCell>

                                    <TableCell data-label="Gait">
                                      {item.gait}
                                    </TableCell>
                                    <TableCell data-label="Handicap">
                                      {item.handicap}
                                    </TableCell>
                                    <TableCell data-label="Sex">
                                      {item.sex}
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <DevPagination
                          count={totalPages}
                          currentPage={currentPage}
                          pageElements={totalPageElements}
                          takeCount={takeCount}
                          setTakeCount={setTakeCount}
                          page={page}
                          setPage={setPage}
                          onChange={handleChange}
                          shape="rounded"
                        />
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                ) : (
                  <PageLoading />
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <DevConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        isLoading={isLoadingSubmit}
      />
    </>
  );
};

export default Microchip;
