import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Grid, Typography } from "@mui/material";
import throttle from "lodash/throttle";
import MuiTextField from "./MuiTextField";
import { RacesService } from "../services/RecesServices";
import { UserService } from "../services/UserServices";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  disabled: {
    backgroundColor: "rgba(255, 0, 0, 0.5)",
    color: "#FFF",
    pointerEvents: "none",
    fontStyle: "italic",
  },
  enable: {
    cursor: "pointer",
  },
}));
function DevAutoCompleteHorse(props) {
  const { placeholder, raceHorses, horseIndex, selected, role, updateFormik } =
    props;
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        role !== "horse"
          ? UserService.getUserListByName(request, callback)
          : RacesService.getHorseByName(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    if (props.horse && props.horse?.id > 0) {
      setValue(props.horse);
    }
  }, [props.horse]);

  React.useEffect(() => {
    let active = true;
    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }
    fetch(
      role !== "horse"
        ? "?term=" + inputValue + "&role=" + role
        : "?name=" + inputValue,
      (results) => {
        if (active) {
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results.success) {
            let allName = results && results.data;

            allName = allName.map((eachResult, index) => {
              return {
                ...eachResult,
                name:
                  eachResult.horse && eachResult.horse
                    ? eachResult.horse.name
                    : "",
                horseName:
                  eachResult.horse && eachResult.horse
                    ? eachResult.horse.name
                    : "",
                id:
                  eachResult.horse && eachResult.horse
                    ? eachResult.horse.id
                    : "",
                horseId:
                  eachResult.horse && eachResult.horse
                    ? eachResult.horse.id
                    : "",
                trainerName:
                  eachResult.trainer && eachResult.trainer
                    ? eachResult.trainer.name
                    : "",

                driverName:
                  eachResult.driver && eachResult.driver
                    ? eachResult.driver.name
                    : "",
                position: index + 1,
              };
            });
            let filterarry = allName.filter(
              (i) => !raceHorses?.find((f) => f.horseId === i.id)
            );
            newOptions = [...newOptions, ...filterarry];
          }

          setOptions(newOptions);
        }
      }
    );

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  const onChangeHorse = (selectedOption, updateIndex, updateFormik) => {
    if (selectedOption) {
      console.log(selectedOption);
      let horse = raceHorses.map((item, index) => {
        if (updateIndex === index) {
          return {
            ...item,
            name: selectedOption.name ? selectedOption.name : "",
            horseName: selectedOption.horseName ? selectedOption.horseName : "",
            horse: selectedOption,
            mare: selectedOption.mare === true ? "Yes" : "No",
            horseId: selectedOption.horseId,
            id: selectedOption.id,
            raceId: selectedOption.raceId,
            trainer: selectedOption.trainerName,
            trainerDetails: selectedOption.trainer
              ? selectedOption.trainer
              : {},
            driver: selectedOption.driverName,
            driverDetails: selectedOption.driver ? selectedOption.driver : {},
            hcp: selectedOption.handicap,
            allOwners: selectedOption.owners ? selectedOption.owners : [],
          };
        } else {
          return { ...item };
        }
      });

      const _raceHorses = [...(horse ?? [])];
      updateFormik("raceHorses", _raceHorses);
    } else {
      let horse = raceHorses.map((item, index) => {
        if (updateIndex === index) {
          return {
            ...item,
            value: "",
            name: "",
            horseName: "",
            horse: "",
            mare: "",
            horseId: "",
            id: "",
            trainer: "",
            trainerDetails: {},
            driver: "",
            driverDetails: {},
            hcp: "",
            position: "",
            raceId: "",
            allOwners:[{name:''},{name:''}]
          };
        } else {
          return { ...item };
        }
      });
      const _raceHorses = [...(horse ?? [])];
      updateFormik("raceHorses", _raceHorses);
    }
  };
  return (
    <>
      <Autocomplete
        size="small"
        id="UsersAutocomplete"
        sx={{ minWidth: 250, width: "100%" }}
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={selected ? selected : value}
        onChange={(event, newValue) => {
          if (role === "horse") {
            setOptions(newValue ? [newValue, ...options] : options);
            setValue(newValue);
            onChangeHorse(newValue, horseIndex, updateFormik);
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <MuiTextField {...params} fullWidth placeholder={placeholder} />
        )}
        renderOption={(props, option) => {
          return (
            <li
              {...props}
              className={
                (option.driverName && option.driverName.trim()) ||
                (option.trainerName && option.trainerName.trim())
                  ? classes.enable
                  : classes.disabled
              }
            >
              <Grid alignItems="center">
                <Grid item xs sx={{ m: 1 }}>
                  <Typography variant="body2">
                    {option.name}{" "}
                    {option.driverName && option.driverName.trim()
                      ? ""
                      : "- No Driver"}{" "}
                    {option.trainerName && option.trainerName.trim()
                      ? ""
                      : "- No Trainer"}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </>
  );
}
export default DevAutoCompleteHorse;
