import React, { useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Grid,
  Button,
  FormHelperText,
  useMediaQuery,
  Box,
  Typography,
  Tooltip,
} from "@mui/material";
import { DragIcon } from "../components/icons/DragIcon";
import EditIcon from "@mui/icons-material/Edit";

import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import DevAutoCompleteHorse from "./DevAutoCompleteHorse";
import MuiTextField from "./MuiTextField";
import { makeStyles } from "@mui/styles";
import DevRaceOwnerHorsesTrainerDriverModel from "./DevRaceOwnerHorsesTrainerDriverModel";
import DevOnMousePopover from "./DevOnMousePopover";

const headCells = [
  { id: "drag", label: "", width: "3%" },
  { id: "position", label: "Draw", width: "5%" },
  { id: "offGate", label: "Trail", width: "13%" },
  { id: "horse ", label: "Horse", width: "18%" },
  { id: "mare", label: "MARE", width: "3%" },
  { id: "Owner", label: "Owner(s)", width: "18%" },
  { id: "trainer", label: "Trainer", width: "18%" },
  { id: "driver", label: "Driver", width: "18%" },
  { id: "Edit", label: "Edit", width: "3%" },
  { id: "hcp", label: "HCP", width: "3%" },
  { id: "delete", label: "", width: "3%" },
];
const useStyles = makeStyles((theme) => ({
  thead: {
    "& th:first-child": {
      borderRadius: "5px 0 0 5px",
    },
    "& th:last-child": {
      borderRadius: "0 5px 5px 0",
    },
    "& th": {
      borderBottom: "0px",
      textTransform: "uppercase",
    },
  },
  tdStyle: {
    background: "#fff",
    padding: "5px 10px 5px 10px",
    borderRadius: "5px",
  },
  tableMobile: {
    "& table": {
      border: 0,
      minWidth: "100%",
    },
    " & table caption": {
      fontSize: "1.3em",
    },
    "& table thead": {
      border: "none",
      clip: "rect(0 0 0 0)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
    },

    "& table tr": {
      borderBottom: "3px solid #ddd",
      display: "block",
      marginBottom: ".625em",
      border: "1px solid #ddd",
      borderRadius: "04px",
    },

    "& table td": {
      borderBottom: "1px solid #ddd",
      display: "block",
      fontSize: ".8em",
      textAlign: "right",
    },

    "& table td::before": {
      content: "attr(data-label)",
      float: "left",
      fontWeight: 500,
    },

    "& table td:last-child": {
      borderBottom: 0,
    },
    "& .avatar": {
      display: "flex",
      justifyContent: "end",
    },
    "& .roles": {
      display: "flex",
      justifyContent: "end",
    },
    "& .action": {
      textAlign: "left",
      padding: " 0px 0px 0px 5px",
    },
    "& .icons": {
      padding: "5px 0px 0px 5px",
    },
  },
}));

const addItem = (updateFormik, raceHorses, setError) => {
  let tempIdgenrated = JSON.stringify(Math.floor(Math.random() * 1000000));
  let horses = {
    value: "",
    tempId: tempIdgenrated,
    offGate: "",
    horseName: "",
    trainer: "",
    driver: "",
    allOwners:[{name:''},{name:''}]
  };

  const _raceHorses = [...(raceHorses ?? []), horses];
  updateFormik("raceHorses", _raceHorses);
  setError("");
};
export default function RacesDragDropList(props) {
  const [openDialog, setOpenDialog] = React.useState(false);
  const [horseIndex, setHorseIndex] = React.useState("");
  const [selectedHorse, setSelectedHorse] = React.useState({});

  const classes = useStyles();
  const {
    horse,
    raceHorses,
    setRecesList,
    setError,
    errprMessage,
    onBlur,
    errors,
    touched,
    updateFormik,
  } = props;
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const remove = (index, updateFormik, raceHorses) => {
    let horse = raceHorses[index];
    let _raceHorses = raceHorses.filter((item) => item !== horse);
    if (raceHorses && raceHorses.length > 2) {
      updateFormik("raceHorses", _raceHorses);
      setError("");
    } else {
      setError(
        "Note: A race needs to have at least 2 horses. You cannot remove the last 2 entries"
      );
    }
  };
  const handleDragEnd = (e) => {
    if (!e.destination) return;
    let tempData = Array.from(raceHorses);
    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);
    updateFormik("raceHorses", tempData);
  };
  const handleDialog = (index, selectedItem) => {
    if (selectedItem) {
      setHorseIndex(index);
      setSelectedHorse(selectedItem);
      setOpenDialog(!openDialog);
    } else {
      setHorseIndex("");
      setSelectedHorse({});
      setOpenDialog(!openDialog);
    }
  };
  return (
    <>
      <DevRaceOwnerHorsesTrainerDriverModel
        matches={matches}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        handleDialog={handleDialog}
        raceHorses={raceHorses}
        updateFormik={updateFormik}
        horseIndex={horseIndex}
        selectedHorse={selectedHorse}
      />
      <TableContainer className={matches ? classes.tableMobile : " "}>
        <DragDropContext onDragEnd={handleDragEnd} sx={{ overflowY: "auto" }}>
          <Table
            sx={{ minWidth: matches ? "100%" : "650", pt: 6, pb: 6 }}
            aria-label="simple table"
            size="small"
          >
            <TableHead
              sx={{ bgcolor: "tableHeader" }}
              className={classes.thead}
            >
              {headCells.map((headCell) => (
                <TableCell
                  key={headCell.id}
                  style={{ width: `${headCell.width ? headCell.width : ""}` }}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <>{headCell.label ? headCell.label : ""}</>
                  )}
                </TableCell>
              ))}
            </TableHead>
            <Droppable droppableId="droppable-1">
              {(provider) => (
                <TableBody ref={provider.innerRef} {...provider.droppableProps}>
                  {raceHorses &&
                    raceHorses.map((item, i) => {
                      const Errors =
                        (errors.raceHorses?.length && errors.raceHorses[i]) ||
                        {};
                      const Touched =
                        (touched.raceHorses?.length && touched.raceHorses[i]) ||
                        {};
                      const ErrorsOwner =
                        (errors.item?.allOwners?.length &&
                          errors.item?.allOwners[i]) ||
                        {};
                      const TouchedOwner =
                        (touched.item?.allOwners?.length &&
                          touched.item?.allOwners[i]) ||
                        {};
                      return (
                        <>
                          <Draggable
                            key={item.tempId}
                            draggableId={item.tempId}
                            index={i}
                          >
                            {(provider) => (
                              <TableRow
                                {...provider.draggableProps}
                                ref={provider.innerRef}
                              >
                                <TableCell
                                  {...provider.dragHandleProps}
                                  className="icons"
                                >
                                  <DragIcon />
                                </TableCell>
                                <TableCell>{i + 1}</TableCell>
                                <TableCell>
                                  <MuiTextField
                                    name={`raceHorses.${i}.offGate`}
                                    value={raceHorses[`${i}`].offGate}
                                    onChange={(e) => {
                                      let eventOffGate = raceHorses[`${i}`];
                                      eventOffGate.offGate = e.target.value;
                                      updateFormik(
                                        `raceHorses.${i}`,
                                        eventOffGate
                                      );
                                    }}
                                  />
                                  <FormHelperText
                                    error={true}
                                    style={{
                                      display: "inline-block",
                                      float: "right",
                                      fontSize: "10px",
                                    }}
                                  >
                                    {Errors.offGate &&
                                      Touched.offGate &&
                                      Errors.offGate}
                                  </FormHelperText>
                                </TableCell>
                                <TableCell>
                                  <DevAutoCompleteHorse
                                    placeholder="Please start typing"
                                    horse={horse}
                                    raceHorses={raceHorses}
                                    setRecesList={setRecesList}
                                    horseIndex={i}
                                    selected={item?.horse ? item?.horse : ""}
                                    role="horse"
                                    updateFormik={updateFormik}
                                  />
                                  {Errors.horseName &&
                                    Touched.horseName &&
                                    Errors.horseName && (
                                      <FormHelperText
                                        error={true}
                                        style={{
                                          display: "inline-block",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {ErrorsOwner.name &&
                                          TouchedOwner.name &&
                                          ErrorsOwner.name}
                                      </FormHelperText>
                                    )}
                                </TableCell>
                                <TableCell data-label={item.mare ? "Mare" : ""}>
                                  {item.mare ? item.mare : ""}
                                </TableCell>
                                <TableCell
                                  data-label={item.allOwners && item.allOwners ? "Owner(s)" : ""}
                                  sx={{
                                    background: "#F4F5F5",
                                    borderRadius: "5px 0px 0px 5px",
                                  }}
                                >
                                  {item?.allOwners &&  item?.allOwners.length > 0 && item.allOwners[0].name && (
                                    <Box className={classes.tdStyle}>
                                      {item.allOwners &&
                                        item.allOwners?.length > 1 && (
                                          <Box
                                            // className="roles"
                                            sx={{
                                              display: "flex",
                                              flexWrap: "wrap",
                                              justifyContent: "left",
                                              alignContent: "center",
                                            }}
                                          >
                                            <Typography sx={{ pr: 2 }}>
                                              {" "}
                                              {item.allOwners[0].name}{" "}
                                            </Typography>{" "}
                                            <DevOnMousePopover
                                              source={4}
                                              count={item.allOwners.length - 1}
                                              items={item.allOwners}
                                              style={{ marginLeft: "5px" }}
                                              raceOwner={true}
                                            ></DevOnMousePopover>{" "}
                                          </Box>
                                        )}
                                      {item.allOwners?.length === 1 &&
                                        item.allOwners[0].name}
                                    </Box>
                                  )}
                                  {ErrorsOwner.name &&
                                    TouchedOwner.name &&
                                    ErrorsOwner.name && (
                                      <FormHelperText
                                        error={true}
                                        style={{
                                          display: "inline-block",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {ErrorsOwner.name &&
                                          TouchedOwner.name &&
                                          ErrorsOwner.name}
                                      </FormHelperText>
                                    )}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    background: "#F4F5F5",
                                  }}
                                >
                                  {item.trainer && (
                                    <Box className={classes.tdStyle}>
                                      {" "}
                                      {item.trainer ? item.trainer : ""}
                                    </Box>
                                  )}
                                  {Errors.trainer &&
                                    Touched.trainer &&
                                    Errors.trainer && (
                                      <FormHelperText
                                        error={true}
                                        style={{
                                          display: "inline-block",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {Errors.trainer &&
                                          Touched.trainer &&
                                          Errors.trainer}
                                      </FormHelperText>
                                    )}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    background: "#F4F5F5",
                                  }}
                                >
                                  {item.driver && (
                                    <Box className={classes.tdStyle}>
                                      {item.driver ? item.driver : ""}
                                    </Box>
                                  )}
                                  {Errors.driver &&
                                    Touched.driver &&
                                    Errors.driver && (
                                      <FormHelperText
                                        error={true}
                                        style={{
                                          display: "inline-block",
                                          fontSize: "10px",
                                        }}
                                      >
                                        {Errors.driver &&
                                          Touched.driver &&
                                          Errors.driver}
                                      </FormHelperText>
                                    )}
                                </TableCell>

                                <TableCell
                                  data-label={item.horse ? "Edit" : ""}
                                  sx={{
                                    background: "#F4F5F5",
                                    borderRadius: "0px 5px 5px 0px",
                                  }}
                                >
                                  {item?.horse && (
                                    <Box
                                      onClick={() => {
                                        handleDialog(i, item);
                                      }}
                                      sx={{ cursor: "pointer" }}
                                    >
                                      <Tooltip title="Change Owners, Trainer and Driver">
                                        <EditIcon />
                                      </Tooltip>
                                    </Box>
                                  )}
                                </TableCell>
                                <TableCell data-label={item.hcp ? "HCP" : ""}>
                                  {item.hcp ? item.hcp : ""}{" "}
                                </TableCell>
                                <TableCell className="icons">
                                  <IconButton
                                    onClick={() =>
                                      remove(i, updateFormik, raceHorses)
                                    }
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            )}
                          </Draggable>
                        </>
                      );
                    })}
                  {provider.placeholder}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
      </TableContainer>
      {errprMessage && (
        <FormHelperText sx={{ my: 1 }} error={true}>
          {errprMessage}
        </FormHelperText>
      )}
      <Grid item sx={{ my: 3 }}>
        <Button
          sx={{
            float: "left",
            ":hover": {
              color: "main",
            },
          }}
          onClick={() => addItem(updateFormik, raceHorses, setError)}
        >
          <AddIcon /> Add
        </Button>
      </Grid>
    </>
  );
}
