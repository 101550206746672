import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

export const DragIcon = () => {
  const theme = useTheme();

  return (
    <Box>
      <svg
        width={24}
        height={24}
        viewBox='0 0 24 24'
        fill={theme.palette.primary.main}
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
      >
        <rect width={24} height={24} fill='url(#pattern0)' />
        <defs>
          <pattern
            id='pattern0'
            patternContentUnits='objectBoundingBox'
            width={1}
            height={1}
          >
            <use xlinkHref='#image0_1326_17228' transform='scale(0.0416667)' />
          </pattern>
          <image
            id='image0_1326_17228'
            width={24}
            height={24}
            xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAGKADAAQAAAABAAAAGAAAAADiNXWtAAABiklEQVRIDeWVMUvDQBTH7wWpgzg46GIHl9pgExz8Am5uuukodBMsKH4HwVkHJ8HNSXF1EDcHnS6xtE1RumXMZFNi8nxHDYQmueTA4mAgXPK/9/6/u0t4j7EpX1DW33GcRd8P7wHg2TDqJ2XzSgGE+XAYPpKpIYw1DS4I0ioD0YqCEuY6rT6iexRFeGjb3fOiXDEvBbiuO/ezcjLX9hjDT0R2wxhcCwjnvbMiiBTged4SAJsX5qa5eivMADCk5ybpl7Sh9SLAjCxA1/UPml+ZjBFHRdrBpJ71Lt1BVoKqNnWA9IjSqwWfvsIorecrSgBEbbtSmR3k2/37Gcvqntq2s6VyEIp/EbYQw90pAlSsx7GKO/hlACIC5/0NGlML4Xyw0Om814uQqcRkQrvdX2Ps69WyeldJCJXwKoD/EgTBXTI+61kKaDRqb6K5UJneH0OYaFBV34+eaFyman+UZZrUSnU00VxE/Y8TqZpSydB2TLP2EGt5YymASI4hKuZ50FydIMeW5WzmBvzFxDd795KL4D+teQAAAABJRU5ErkJggg=='
          />
        </defs>
      </svg>
    </Box>
  );
};
