import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export const UploadIcon = () => {
  const theme = useTheme();

  return (
    <Box sx={{ mr: '12px' }} >

      <svg width="18" height="13" viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.12 5.47312C15.2353 5.17219 15.3 4.84312 15.3 4.5C15.3 3.00937 14.0906 1.8 12.6 1.8C12.0459 1.8 11.5284 1.96875 11.1009 2.25562C10.3219 0.905625 8.86781 0 7.2 0C4.71375 0 2.7 2.01375 2.7 4.5C2.7 4.57594 2.70281 4.65188 2.70562 4.72781C1.13062 5.28188 0 6.78375 0 8.55C0 10.7859 1.81406 12.6 4.05 12.6H14.4C16.3884 12.6 18 10.9884 18 9C18 7.25906 16.7625 5.805 15.12 5.47312ZM11.0644 7.2H9.225V10.35C9.225 10.5975 9.0225 10.8 8.775 10.8H7.425C7.1775 10.8 6.975 10.5975 6.975 10.35V7.2H5.13562C4.73344 7.2 4.53375 6.71625 4.81781 6.43219L7.78219 3.46781C7.95656 3.29344 8.24344 3.29344 8.41781 3.46781L11.3822 6.43219C11.6663 6.71625 11.4637 7.2 11.0644 7.2Z" fill={theme.palette.primary.main} />
      </svg>
    </Box>
  );
};
