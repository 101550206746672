import React from "react";
import { Text, StyleSheet, Font, Image, PDFViewer } from "@react-pdf/renderer";
import Document from "./Document";
import Page from "./Page";
import View from "./View";
import { View as PdfView } from "@react-pdf/renderer";

import TextView from "./TextView";
import font from "../../../components/fonts/Poppins/Poppins-Regular.ttf";
import BoldFont from "../../../components/fonts/Poppins/Poppins-Bold.ttf";

import { dateTimeFormat } from "../../../helpers/util";
import moment from "moment";
import { PageLoading } from "../../../components/icons/PageLoading";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@mui/material";
const currency = process.env.REACT_APP_ISO === "IE" ? "€" : "£";

const headCells = [
  { id: "driver ", label: "DRIVER", width: "15%" },
  { id: "No", label: "NO", width: "5%" },
  { id: "horse ", label: "HORSE", width: "25%" },
  { id: "handicap", label: "RATING", width: "15%" },
  { id: "form ", label: "FORM", width: "30%" },
  { id: "gate", label: "GATE", width: "5%" },
];
const useStyles = makeStyles((theme) => ({
  thead: {
    "& th": {
      borderBottom: "0px",
      padding: "08px 20px",
      fontWeight: 500,
    },
  },
  tableMobile: {
    "& table": {
      border: 0,
      minWidth: "100%",
    },
    " & table caption": {
      fontSize: "1.3em",
    },
    "& table thead": {
      border: "none",
      clip: "rect(0 0 0 0)",
      height: "1px",
      margin: "-1px",
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      width: "1px",
    },

    "& table tr": {
      borderBottom: "3px solid #ddd",
      display: "block",
      marginBottom: ".625em",
      border: "1px solid #ddd",
      borderRadius: "04px",
    },

    "& table td": {
      borderBottom: "1px solid #ddd",
      display: "block",
      fontSize: ".8em",
      textAlign: "right",
    },

    "& table td::before": {
      content: "attr(data-label)",
      float: "left",
      fontWeight: 500,
    },

    "& table td:last-child": {
      borderBottom: 0,
    },
    "& .avatar": {
      display: "flex",
      justifyContent: "end",
    },
    "& .roles": {
      display: "flex",
      justifyContent: "end",
    },
    "& .action": {
      textAlign: "left",
      padding: " 0px 0px 0px 5px",
    },
    "& .maskInputMobile": {
      width: "100% !important",
    },
  },
}));

Font.register({
  family: "Poppins",
  format: "truetype",
  fontWeight: 600,
  src: font,
});
Font.register({
  family: "Poppins-bold",
  format: "truetype",
  fontWeight: 900,
  src: BoldFont,
});
const styles = StyleSheet.create({
  body: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  PDFbody: {
    paddingTop: 35,
    paddingBottom: 65,
    fontFamily: "Poppins",
    fontSize: 12,
  },
  topHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: process.env.REACT_APP_ISO === "IE" ? "#029A47" : "#012169",
    padding: "20px",
    color: "#FFF",
    fontFamily: "Poppins-bold",
    fontWeight: 900,
  },
  boldFontPDF: {
    fontFamily: "Poppins-bold",
    fontWeight: 900,
    fontSize: 15,
  },
  boldFont: {
    fontFamily: "Poppins-bold",
    fontWeight: 900,
    fontSize: 18,
  },
  fontBig: {
    fontSize: 20,
  },
  fontBigPDF: {
    fontSize: 14,
  },
  sectionPDF: {
    padding: "10px 20px 0px 40px",
    fontSize: 12,
  },

  gridSpaceRank: {
    marginRight: "15px",
    fontSize: 12,
  },
  flexSectionSpace: {
    paddingTop: "5px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  topHeaderLeft: {
    fontWeight: 600,
  },
  topHeaderRight: {
    fontWeight: 600,
  },
  width_50: {
    width: "50%",
  },
  width_20: {
    width: "20%",
  },
  text: {
    marginBottom: 8,
    fontFamily: "Poppins-bold",
    fontWeight: 900,
  },
  flex: {
    padding: "08px 20px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  grid: {
    marginRight: "25px",
    fontFamily: "Poppins-bold",
    fontWeight: 900,
  },
  space: {
    padding: "0px 20px",
  },
  flexSpace: {
    padding: "03px 0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  flexSpaceResponsive: {
    padding: "03px 0px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "end",
  },
  gridSpace: {
    marginRight: "15px",
  },
  tableHeader: {
    backgroundColor: process.env.REACT_APP_ISO === "IE" ? "#E8F0EC" : "#E5E7ED",
    display: "flex",
    flexDirection: "row",
    marginTop: "10px",
    fontSize: 11,
  },
  tableBody: {
    borderBottom: "1px solid rgba(224, 224, 224, 1)",
    display: "flex",
    flexDirection: "row",
    fontSize: 11,
  },
  theadWidth5: {
    width: "05%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  theadWidthRating: {
    width: "100%",
    padding: "08px 05px",
    fontWeight: 500,
  },
  theadWidth10: {
    width: "10%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  theadWidth15: {
    width: "15%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  theadWidthRatingPDF: {
    width: "15%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  theadWidth20: {
    width: "20%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  theadWidth25: {
    width: "25%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  theadWidth30: {
    width: "30%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  theadWidth33: {
    width: "33%",
    padding: "08px 20px",
    fontWeight: 500,
  },
  image: {
    objectFit: "cover",
    height: "45px",
    width: "45px",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
});

const RaceCard = (props) => {
  const classes = useStyles();
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );
  const { racesDetails, pdfMode, isLoading } = props;
  const raceHorses =
    racesDetails &&
    racesDetails.raceHorses &&
    racesDetails.raceHorses.sort((a, b) => a.position - b.position);
  return (
    <>
      {!isLoading ? (
        <>
          <Document pdfMode={pdfMode}>
            <Page
              className={pdfMode ? styles.PDFbody : styles.body}
              pdfMode={pdfMode}
            >
              <View className={styles.topHeader} pdfMode={pdfMode}>
                <View pdfMode={pdfMode}>
                  <TextView className={styles.topHeaderLeft} pdfMode={pdfMode}>
                    {racesDetails.name}
                  </TextView>
                </View>
                <View pdfMode={pdfMode}>
                  <TextView className={styles.topHeaderRight} pdfMode={pdfMode}>
                    {racesDetails.startDate
                      ? dateTimeFormat(racesDetails.startDate)
                      : ""}
                  </TextView>
                </View>
              </View>

              <View className={styles.flex} pdfMode={pdfMode}>
                <View pdfMode={pdfMode}>
                  <TextView className={styles.grid} pdfMode={pdfMode}>
                    {racesDetails.distance} {racesDetails.units}
                  </TextView>
                </View>
                <View pdfMode={pdfMode}>
                  <TextView className={styles.grid} pdfMode={pdfMode}>
                    Total Purse: {currency} {racesDetails.totalPurse}
                  </TextView>
                </View>
                <View pdfMode={pdfMode}>
                  <TextView className={styles.grid} pdfMode={pdfMode}>
                    {racesDetails.information}
                  </TextView>
                </View>
              </View>
              <View className={styles.flex} pdfMode={pdfMode}>
                {racesDetails &&
                  racesDetails.racePrize &&
                  racesDetails.racePrize.map((item, i) => {
                    return (
                      <View key={i} pdfMode={pdfMode}>
                        <TextView className={styles.grid} pdfMode={pdfMode}>
                          {item.rank} - {currency + item.prize}
                        </TextView>
                      </View>
                    );
                  })}
              </View>
              <View className={styles.space} pdfMode={pdfMode}>
                <TextView className={styles.text} pdfMode={pdfMode}>
                  Entry Fee: {currency + racesDetails.entryFee}
                </TextView>
              </View>

              {pdfMode ? (
                // PdfView Race Card Table Start
                <>
                  <View className={styles.tableHeader} pdfMode={pdfMode}>
                    <View className={styles.theadWidth25} pdfMode={pdfMode}>
                      <TextView pdfMode={pdfMode}>DRIVER</TextView>
                    </View>
                    <View className={styles.theadWidth5} pdfMode={pdfMode}>
                      <TextView pdfMode={pdfMode}>NO</TextView>
                    </View>

                    <View className={styles.theadWidth30} pdfMode={pdfMode}>
                      <TextView pdfMode={pdfMode}>HORSE</TextView>
                    </View>
                    <View
                      className={styles.theadWidthRatingPDF}
                      pdfMode={pdfMode}
                    >
                      <TextView pdfMode={pdfMode}>Rating</TextView>
                    </View>

                    <View className={styles.theadWidth33} pdfMode={pdfMode}>
                      <TextView pdfMode={pdfMode}>FORM</TextView>
                    </View>

                    <View className={styles.theadWidth10} pdfMode={pdfMode}>
                      <TextView pdfMode={pdfMode}>GATE</TextView>
                    </View>
                  </View>

                  {raceHorses &&
                    raceHorses.map((item, i) => {
                      let fullName =
                        item.driver && item.driver.name
                          ? item?.driver?.name.split(" ")
                          : [];
                      return (
                        <View
                          key={i}
                          className={styles.tableBody}
                          pdfMode={pdfMode}
                        >
                          <View
                            className={styles.theadWidth25}
                            pdfMode={pdfMode}
                          >
                            {item.driver && item.driver.coloursUrl && (
                              <>
                                {pdfMode ? (
                                  <Image
                                    style={styles.image}
                                    src={item.driver.coloursUrl}
                                  />
                                ) : (
                                  <img
                                    src={item.driver.coloursUrl}
                                    style={styles.image}
                                    alt={"driver"}
                                  />
                                )}
                              </>
                            )}
                            {fullName &&
                              fullName.map((item, i) => {
                                return (
                                  <TextView
                                    pdfMode={pdfMode}
                                    className={styles.boldFontPDF}
                                  >
                                    {item}
                                  </TextView>
                                );
                              })}
                          </View>
                          <View
                            className={styles.theadWidth5}
                            pdfMode={pdfMode}
                          >
                            <TextView pdfMode={pdfMode}>
                              {item.position && item.position}
                            </TextView>
                          </View>

                          <View
                            className={styles.theadWidth30}
                            pdfMode={pdfMode}
                          >
                            <TextView
                              pdfMode={pdfMode}
                              className={styles.boldFontPDF}
                            >
                              {item.horse && item.horse.name
                                ? item.horse.name
                                : ""}
                            </TextView>
                            <TextView pdfMode={pdfMode}>
                              {item.dateOfBirth
                                ? moment(item.dateOfBirth).format("YYYY") +
                                  " - "
                                : ""}{" "}
                              <TextView
                                pdfMode={pdfMode}
                                className={styles.fontBigPDF}
                              >
                                {item.color ? item.color + " - " : ""}{" "}
                              </TextView>
                              {item.sex ? item.sex[0] + " - " : ""}{" "}
                              {item.pedigree && item.pedigree.sireName
                                ? item.pedigree.sireName
                                : ""}{" "}
                              {""}
                              {item.pedigree && item.pedigree.dameSIreName
                                ? item.pedigree.dameSIreName
                                : ""}{" "}
                              {""}
                              {item.pedigree && item.pedigree.dameName
                                ? item.pedigree.dameName
                                : ""}
                            </TextView>
                            <TextView pdfMode={pdfMode}>
                              Owner(s):{" "}
                              {item &&
                                item.allOwners &&
                                item.allOwners.map((itemChild, j) => {
                                  return (
                                    <React.Fragment key={j}>
                                      {itemChild.name}
                                      {j < item.allOwners.length - 1 ? "," : ""}
                                    </React.Fragment>
                                  );
                                })}
                            </TextView>

                            <TextView pdfMode={pdfMode}>
                              Trainer:{" "}
                              {item.trainer && item.trainer.name
                                ? item.trainer.name
                                : ""}
                            </TextView>
                          </View>
                          <View
                            className={styles.theadWidthRatingPDF}
                            pdfMode={pdfMode}
                          >
                            <TextView
                              pdfMode={pdfMode}
                              className={styles.boldFontPDF}
                            >
                              {item.handicap ? item.handicap : ""}
                            </TextView>
                          </View>
                          <View
                            className={styles.theadWidth33}
                            pdfMode={pdfMode}
                          >
                            <View
                              className={styles.flexSpace}
                              pdfMode={pdfMode}
                            >
                              <View
                                className={styles.width_50}
                                pdfMode={pdfMode}
                              >
                                <TextView
                                  className={styles.gridSpace}
                                  pdfMode={pdfMode}
                                >
                                  Last Five
                                </TextView>
                              </View>
                              <View
                                className={styles.width_50}
                                pdfMode={pdfMode}
                              >
                                <TextView
                                  className={styles.gridSpace}
                                  pdfMode={pdfMode}
                                >
                                  Lifetime
                                </TextView>
                              </View>
                            </View>

                            <View
                              className={styles.flexSpace}
                              pdfMode={pdfMode}
                            >
                              <View
                                className={styles.width_50}
                                pdfMode={pdfMode}
                              >
                                <TextView
                                  className={styles.gridSpace}
                                  pdfMode={pdfMode}
                                >
                                  {item?.prizeOfLast5
                                    ? currency + item?.prizeOfLast5
                                    : currency + 0}
                                </TextView>
                              </View>
                              <View
                                className={styles.width_50}
                                pdfMode={pdfMode}
                              >
                                <TextView
                                  className={styles.gridSpace}
                                  pdfMode={pdfMode}
                                >
                                  {item?.prizeOfLifeTime
                                    ? currency + item?.prizeOfLifeTime
                                    : currency + 0}
                                </TextView>
                              </View>
                            </View>

                            <View
                              className={styles.flexSpace}
                              pdfMode={pdfMode}
                            >
                              <View
                                className={styles.width_50}
                                pdfMode={pdfMode}
                              >
                                <TextView
                                  className={styles.gridSpace}
                                  pdfMode={pdfMode}
                                >
                                  {item?.bestofLast5
                                    ? item?.bestofLast5
                                    : "00:00:00"}
                                </TextView>
                              </View>
                              <View
                                className={styles.width_50}
                                pdfMode={pdfMode}
                              >
                                <TextView
                                  className={styles.gridSpace}
                                  pdfMode={pdfMode}
                                >
                                  {item?.bestofLifeTime
                                    ? item?.bestofLifeTime
                                    : "00:00:00"}
                                </TextView>
                              </View>
                            </View>

                            <View
                              className={styles.flexSpace}
                              pdfMode={pdfMode}
                            >
                              {item &&
                                item.last5HorseWinings &&
                                item.last5HorseWinings.map((itemChild, i) => {
                                  return (
                                    <React.Fragment key={i}>
                                      <View key={i} pdfMode={pdfMode}>
                                        <TextView pdfMode={pdfMode}>
                                          {itemChild.abbrevitation}(
                                          {itemChild.rank})
                                          {i < item.last5HorseWinings.length - 1
                                            ? "," + "  "
                                            : " "}
                                        </TextView>
                                      </View>
                                    </React.Fragment>
                                  );
                                })}
                            </View>
                          </View>
                          <View
                            className={styles.theadWidth10}
                            pdfMode={pdfMode}
                          >
                            <TextView pdfMode={pdfMode}>
                              {item?.offGate ? item?.offGate : ""}
                            </TextView>
                          </View>
                        </View>
                      );
                    })}
                </>
              ) : (
                // PdfView Race Card Table End

                // MIUI  Race Card Table Start
                <>
                  <TableContainer
                    className={matches ? classes.tableMobile : " "}
                  >
                    <Table
                      sx={{ pt: 6, pb: 6 }}
                      aria-label="simple table"
                      size="small"
                    >
                      <TableHead
                        sx={{ bgcolor: "tableHeader" }}
                        className={classes.thead}
                      >
                        {headCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            style={{
                              width: `${headCell.width ? headCell.width : ""}`,
                            }}
                          >
                            <>{headCell.label ? headCell.label : ""}</>
                          </TableCell>
                        ))}
                      </TableHead>
                      <TableBody>
                        {raceHorses &&
                          raceHorses.map((item, i) => {
                            let fullName =
                              item.driver && item.driver.name
                                ? item?.driver?.name.split(" ")
                                : [];
                            return (
                              <>
                                <TableRow>
                                  <TableCell data-label="Driver">
                                    {item.driver && item.driver.coloursUrl && (
                                      <>
                                        {pdfMode ? (
                                          <Image
                                            style={styles.image}
                                            src={item.driver.coloursUrl}
                                          />
                                        ) : (
                                          <img
                                            src={item.driver.coloursUrl}
                                            style={styles.image}
                                            alt={"driver"}
                                          />
                                        )}
                                      </>
                                    )}
                                    {fullName &&
                                      fullName.map((item, i) => {
                                        return (
                                          <TextView
                                            pdfMode={pdfMode}
                                            className={styles.boldFont}
                                          >
                                            {item}
                                          </TextView>
                                        );
                                      })}
                                  </TableCell>

                                  <TableCell data-label="No">
                                    <TextView pdfMode={pdfMode}>
                                      {item.position && item.position}
                                    </TextView>
                                  </TableCell>

                                  <TableCell data-label="Horse">
                                    <TextView
                                      pdfMode={pdfMode}
                                      className={styles.boldFont}
                                    >
                                      {item.horse && item.horse.name
                                        ? item.horse.name
                                        : ""}
                                    </TextView>
                                    <TextView pdfMode={pdfMode}>
                                      {item.dateOfBirth
                                        ? moment(item.dateOfBirth).format(
                                            "YYYY"
                                          ) + " - "
                                        : ""}{" "}
                                      <TextView
                                        pdfMode={pdfMode}
                                        className={styles.fontBig}
                                        span={true}
                                      >
                                        {item.color ? item.color + " - " : ""}{" "}
                                      </TextView>
                                      {item.sex ? item.sex[0] + " - " : ""}{" "}
                                      {item.pedigree && item.pedigree.sireName
                                        ? item.pedigree.sireName
                                        : ""}{" "}
                                      {""}
                                      {item.pedigree &&
                                      item.pedigree.dameSIreName
                                        ? item.pedigree.dameSIreName
                                        : ""}{" "}
                                      {""}
                                      {item.pedigree && item.pedigree.dameName
                                        ? item.pedigree.dameName
                                        : ""}
                                    </TextView>

                                    <TextView pdfMode={pdfMode}>
                                      Owner(s):{" "}
                                      {item &&
                                        item.allOwners &&
                                        item.allOwners.map((itemChild, j) => {
                                          return (
                                            <React.Fragment key={j}>
                                              {itemChild.name}
                                              {j < item.allOwners.length - 1
                                                ? ","
                                                : ""}
                                            </React.Fragment>
                                          );
                                        })}
                                    </TextView>
                                    <TextView pdfMode={pdfMode}>
                                      Trainer:{" "}
                                      {item.trainer && item.trainer.name
                                        ? item.trainer.name
                                        : ""}
                                    </TextView>
                                  </TableCell>
                                  <TableCell data-label="Handcap">
                                    <View
                                      className={styles.theadWidthRating}
                                      pdfMode={pdfMode}
                                    >
                                      <TextView
                                        pdfMode={pdfMode}
                                        className={styles.boldFont}
                                      >
                                        {item.handicap ? item.handicap : ""}
                                      </TextView>
                                    </View>
                                  </TableCell>
                                  <TableCell data-label="Form">
                                    <View
                                      className={styles.flexSpace}
                                      pdfMode={pdfMode}
                                    >
                                      <View
                                        className={styles.width_50}
                                        pdfMode={pdfMode}
                                      >
                                        <TextView
                                          className={styles.gridSpace}
                                          pdfMode={pdfMode}
                                        >
                                          Last Five
                                        </TextView>
                                      </View>
                                      <View
                                        className={styles.width_50}
                                        pdfMode={pdfMode}
                                      >
                                        <TextView
                                          className={styles.gridSpace}
                                          pdfMode={pdfMode}
                                        >
                                          Lifetime
                                        </TextView>
                                      </View>
                                    </View>

                                    <View
                                      className={styles.flexSpace}
                                      pdfMode={pdfMode}
                                    >
                                      <View
                                        className={styles.width_50}
                                        pdfMode={pdfMode}
                                      >
                                        <TextView
                                          className={styles.gridSpace}
                                          pdfMode={pdfMode}
                                        >
                                          {item?.prizeOfLast5
                                            ? currency + item?.prizeOfLast5
                                            : currency + 0}
                                        </TextView>
                                      </View>
                                      <View
                                        className={styles.width_50}
                                        pdfMode={pdfMode}
                                      >
                                        <TextView
                                          className={styles.gridSpace}
                                          pdfMode={pdfMode}
                                        >
                                          {item?.prizeOfLifeTime
                                            ? currency + item?.prizeOfLifeTime
                                            : currency + 0}
                                        </TextView>
                                      </View>
                                    </View>

                                    <View
                                      className={styles.flexSpace}
                                      pdfMode={pdfMode}
                                    >
                                      <View
                                        className={styles.width_50}
                                        pdfMode={pdfMode}
                                      >
                                        <TextView
                                          className={styles.gridSpace}
                                          pdfMode={pdfMode}
                                        >
                                          {item?.bestofLast5
                                            ? item?.bestofLast5
                                            : "00:00:00"}
                                        </TextView>
                                      </View>
                                      <View
                                        className={styles.width_50}
                                        pdfMode={pdfMode}
                                      >
                                        <TextView
                                          className={styles.gridSpace}
                                          pdfMode={pdfMode}
                                        >
                                          {item?.bestofLifeTime
                                            ? item?.bestofLifeTime
                                            : "00:00:00"}
                                        </TextView>
                                      </View>
                                    </View>

                                    <View
                                      className={
                                        matches
                                          ? styles.flexSpaceResponsive
                                          : styles.flexSpace
                                      }
                                      pdfMode={pdfMode}
                                    >
                                      {item &&
                                        item.last5HorseWinings &&
                                        item.last5HorseWinings.map(
                                          (itemChild, i) => {
                                            return (
                                              <React.Fragment key={i}>
                                                <View key={i} pdfMode={pdfMode}>
                                                  <TextView pdfMode={pdfMode}>
                                                    {itemChild.abbrevitation}(
                                                    {itemChild.rank})
                                                    {i <
                                                    item.last5HorseWinings
                                                      .length -
                                                      1
                                                      ? "," + " "
                                                      : "  "}
                                                  </TextView>
                                                </View>
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                    </View>
                                  </TableCell>

                                  <TableCell data-label="Gate">
                                    <TextView pdfMode={pdfMode}>
                                      {item?.offGate ? item?.offGate : ""}
                                    </TextView>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
                // MIUI  Race Card Table end
              )}
              {pdfMode && (
                <PdfView style={styles.sectionPDF}>
                  <PdfView style={styles.flexSpace}>
                    <PdfView style={styles.width_20}>
                      <Text style={styles.gridSpaceRank}>1st.</Text>
                    </PdfView>
                    <PdfView style={styles.width_20}>
                      <Text style={styles.gridSpaceRank}>2nd.</Text>
                    </PdfView>
                    <PdfView style={styles.width_20}>
                      <Text style={styles.gridSpaceRank}>3rd.</Text>
                    </PdfView>
                  </PdfView>

                  <PdfView style={styles.flexSectionSpace}>
                    <PdfView style={styles.width_20}>
                      <Text style={styles.gridSpaceRank}>Time</Text>
                    </PdfView>
                    <PdfView style={styles.width_20}>
                      <Text style={styles.gridSpaceRank}>Distance</Text>
                    </PdfView>
                  </PdfView>
                </PdfView>
              )}

              {pdfMode && (
                <Text
                  style={styles.pageNumber}
                  render={({ pageNumber, totalPages }) =>
                    `${pageNumber} / ${totalPages}`
                  }
                  fixed
                />
              )}
            </Page>
          </Document>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default RaceCard;
