import {
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DevAutoCompleteUser from "../../components/DevAutoCompleteUser";
import { useTranslation } from "react-i18next";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import React, { useEffect, useState } from "react";
import MuiTextField from "../../components/MuiTextField";
import * as yup from "yup";
import { useFormik } from "formik";
import { useHistory } from "react-router";
import { UserService } from "../../services/UserServices";
import ToastConfig from "../../components/toast/toast";
import AvatarPicker from "../../components/AvatarPicker";
import DevFileOptions from "../../components/DevFileOptions";
import { calculate_age } from "../../helpers/util";
import paths from "../../routing/paths";
import endpoints from "../../constants/endpoints";
import CloseIcon from "@mui/icons-material/Close";
import SyndicateDetails from "../Syndicates/SyndicateDetails";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { PageLoading } from "../../components/icons/PageLoading";
import DevUserHorsesModel from "../../components/DevUserHorsesModel";
import { makeStyles } from "@mui/styles";
import moment from "moment";
var _ = require("lodash");

const trainerLicence = ["Expired", "OK"];
const trainerType = ["Public", "Permit", "Licence"];
const ownerLicence = ["Expired", "OK"];
const ownerType = ["Single owner", "Syndicate"];

const driverLicence = ["Expired", "OK"];
const driverLevel = ["Expired", "OK"];
const UKDriverClass = [
  "A class",
  "B class",
  "C class 4-8 wins",
  "C Class 0-3 wins",
  "Provisional",
];
const IreshDriverClass = [
  "Class A",
  "Class B",
  "Apprentice (Under 25, 0-10 Wins)",
  "Amateur (0-10 Wins)",
];
const driverClass =
  process.env.REACT_APP_ISO === "IE" ? IreshDriverClass : UKDriverClass;
const useStyles = makeStyles((theme) => ({
  section: {
    "& .fullWidth": {
      maxWidth: "100% !important",
      minWidth: "100%",
      width: "100% !important",
    },
  },
}));
const UserDetails = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [avatarImage, setAvatarImage] = useState();
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const [openSyndicateDialog, setopenSyndicateDialog] = useState(false);
  const [checkBox, setCheckBox] = useState(false);

  const [openHorseDialog, setopenHorseDialog] = useState(false);
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down("sm") ?? "600"
  );

  const handleImageChange = (imageFile) => {
    setAvatarImage(imageFile);
  };
  const handleSyndicateDialogClose = () => {
    setopenSyndicateDialog(false);
  };
  const handelCheckBox = (value, e, setFieldValue) => {
    setFieldValue(value, e);

    setCheckBox(e);

    console.log(e);
  };
  useEffect(() => {
    loadData(props.id);
    return () => {};
  }, [props.id]);

  const cancelFn = () => {
    history.push(paths.USER_LIST);
    props.handleClose();
  };
  const loadData = (id) => {
    setIsLoading(true);
    UserService.getById(id).then((response) => {
      if (response.success) {
        setUser(response.data);
      } else {
        props.id !== 0 && ToastConfig.error("Could Not get User Details");
      }
      setIsLoading(false);
    });
  };
  useEffect(() => {
    setFieldValue("title", user?.title ? user?.title : "");
    setFieldValue("firstName", user?.firstName ? user?.firstName : "");
    setFieldValue("lastName", user?.lastName ? user?.lastName : "");
    setFieldValue("email", user?.email ? user?.email : "");
    setFieldValue("phoneNumber", user?.phoneNumber ? user?.phoneNumber : "");
    setFieldValue("landline", user?.landline ? user?.landline : "");
    setFieldValue(
      "dateOfBirth",
      user?.dateOfBirth ? user?.dateOfBirth : new Date()
    );
    setFieldValue(
      "age",
      user?.dateOfBirth ? calculate_age(user?.dateOfBirth) : 0
    );
    setFieldValue(
      "saddleTrotLicence",
      user?.saddleTrotLicence ? user?.saddleTrotLicence : false
    );
    setFieldValue("printedCalendar", user?.printedCalendar ? user?.printedCalendar : false);
    setFieldValue("onlineCalendar", user?.onlineCalendar ? user?.onlineCalendar : false);
    setFieldValue(
      "profilePictureUrl",
      user?.profilePictureUrl ? user?.profilePictureUrl : ""
    );
    setFieldValue("idProofUrl", user?.idProofUrl ? user?.idProofUrl : "");
    setFieldValue("id", user?.id ? user?.id : 0);

    setFieldValue(
      "address.zipCode",
      user?.address?.zipCode ? user?.address?.zipCode : ""
    );
    setFieldValue(
      "address.country",
      user?.address?.country ? user?.address?.country : ""
    );
    setFieldValue(
      "address.city",
      user?.address?.city ? user?.address?.city : ""
    );
    setFieldValue(
      "address.homeAddress",
      user?.address?.homeAddress ? user?.address?.homeAddress : ""
    );
    setFieldValue("address.Id", user?.address?.id ? user?.address?.id : 0);

    let Owner =
      user &&
      user?.userLicences?.find((x) => x.userType === "owner" && x.status === 0);
    let Driver =
      user &&
      user?.userLicences?.find(
        (x) => x.userType === "driver" && x.status === 0
      );
    let Trainer =
      user &&
      user?.userLicences?.find(
        (x) => x.userType === "trainer" && x.status === 0
      );
    let AssistantTrainer =
      user &&
      user?.userLicences?.find(
        (x) => x.userType === "assistantTrainer" && x.status === 0
      );

    setFieldValue("owner.isowner", Owner ? true : false);
    setFieldValue("driver.isdriver", Driver ? true : false);
    setFieldValue("trainer.istrainer", Trainer ? true : false);
    setFieldValue(
      "assistantTrainer.isassistantTrainer",
      AssistantTrainer ? true : false
    );

    if (Owner) {
      setFieldValue("owner.licenceStatus", Owner.licenceStatus);
      setFieldValue("owner.type", Owner.type);
      setFieldValue("owner.id", Owner.id);
      setFieldValue("owner.licenseUrl", Owner.licenseUrl);
    }
    if (Driver) {
      setFieldValue("driver.licenceStatus", Driver.licenceStatus);
      setFieldValue("driver.type", Driver.type);
      setFieldValue("driver.class", Driver.class);
      setFieldValue("driver.id", Driver.id);
      setFieldValue("driver.licenseUrl", Driver.licenseUrl);
      setFieldValue(
        "driver.coloursUrl",
        Driver.coloursUrl ? Driver.coloursUrl : ""
      );
    }
    if (Trainer) {
      setFieldValue("trainer.licenceStatus", Trainer.licenceStatus);
      setFieldValue("trainer.type", Trainer.type);
      setFieldValue("trainer.id", Trainer.id);
      setFieldValue("trainer.licenseUrl", Trainer.licenseUrl);
    }
    if (AssistantTrainer) {
      setFieldValue(
        "assistantTrainer.licenceStatus",
        AssistantTrainer.licenceStatus
      );
      setFieldValue("assistantTrainer.parentUser", AssistantTrainer.parentUser);
      setFieldValue("assistantTrainer.id", AssistantTrainer.id);
      setFieldValue("assistantTrainer.licenseUrl", AssistantTrainer.licenseUrl);
    }
    setFieldValue("owner.userType", "assistantTrainer");
    setFieldValue("owner.userType", "trainer");
    setFieldValue("owner.userType", "driver");
    setFieldValue("owner.userType", "owner");

    let syndicates = user && user?.syndicates ? user?.syndicates : [];
    setFieldValue("syndicates", syndicates);

    let horses = user && user?.horses ? user?.horses : [];
    setFieldValue("horses", horses);

    let initialVlues = values;
    return () => {
      if (_.isEqual(initialVlues, values)) {
        console.log("same");
      } else console.log("diffrent");
    };
  }, [user]);
  const validationSchema = yup.object({
    title: yup.string().matches("[A-Za-z. ]+$", "Please enter a valid Title"),
    address: yup.object({
      country: yup
        .string()
        .matches("[A-Za-z ]+$", "Please enter a valid Country"),
      city: yup.string().matches("[A-Za-z. ]+$", "Please enter a valid City"),
    }),
    email: yup
      .string("Enter your email address")
      .email("Please enter a valid email")
      .required("Email is required"),
    firstName: yup
      .string("Enter Forename")
      .min(3, "Forename must be at least 3 characters long")
      .required("Forename is required"),
    lastName: yup
      .string("Enter Surname")
      .min(3, "Surname must be at least 3 characters long")
      .required("Surname is required"),
    phoneNumber: yup
      .string("Enter Phone Number")
      .required("Phone Number is required")
      .matches(
        "^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{2,6}$",
        "Please enter a valid phone number"
      ),

    dateOfBirth: yup
      .date()
      .max(new Date(), "Date of Birth of new born can't be add "),
    printedCalendar: yup.boolean(),
    onlineCalendar:yup.boolean(),
    saddleTrotLicence: yup.boolean(),
    owner: yup.object({
      isowner: yup.boolean(),
      licenceStatus: yup.string().when("isowner", {
        is: true,
        then: yup.string("Enter Licence").required("Licence is required"),
      }),
      type: yup.string().when("isowner", {
        is: true,
        then: yup.string("Enter Type").required("Type is required"),
      }),
    }),
    type: "",
    class: "",
    driver: yup.object({
      isdriver: yup.boolean(),
      licenceStatus: yup.string().when("isdriver", {
        is: true,
        then: yup.string("Enter Licence").required("Licence is required"),
      }),
      coloursUrl: yup.string().when("isdriver", {
        is: true,
        then: yup
          .string("Please select driver color image")
          .required("Please select driver color image"),
      }),
      type: yup.string().when("isdriver", {
        is: true,
        then: yup.string("Enter Level").required("Level is required"),
      }),
      class: yup.string().when("isdriver", {
        is: true,
        then: yup.string("Enter Class").required("Class is required"),
      }),
    }),
    trainer: yup.object({
      istrainer: yup.boolean(),
      licenceStatus: yup.string().when("istrainer", {
        is: true,
        then: yup.string("Enter Licence").required("Licence is required"),
      }),
      type: yup.string().when("istrainer", {
        is: true,
        then: yup.string("Enter Type").required("Type is required"),
      }),
    }),
    assistantTrainer: yup.object({
      isassistantTrainer: yup.boolean(),
      licenceStatus: yup.string().when("isassistantTrainer", {
        is: true,
        then: yup.string("Enter Licence").required("Licence is required"),
      }),
      parentUser: yup.object().when("isassistantTrainer", {
        is: true,
        then: yup.object({
          id: yup.number().required("Trainer is required"),
        }),
      }),
    }),
  });

  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    setFieldValue,
    validateField,
  } = useFormik({
    initialValues: {
      id: user?.id ? user?.id : 0,
      userLoggedId: 1,
      printedCalendar: false,
      onlineCalendar:false,
      phoneNumberPrefix: "+44",
      saddleTrotLicence: false,
      owner: {
        id: 0,
        isowner: false,
        userType: "owner",
        licenceStatus: "",
        licenseUrl: "",
        type: "",
        status: 1,
      },
      driver: {
        id: 0,
        isdriver: false,
        userType: "driver",
        licenceStatus: "",
        coloursUrl: "",
        licenseUrl: "",
        type: "",
        class: "",
        status: 1,
      },
      trainer: {
        id: 0,
        istrainer: false,
        userType: "trainer",
        licenceStatus: "",
        licenseUrl: "",
        type: "",
        status: 1,
      },
      assistantTrainer: {
        id: 0,
        isassistantTrainer: false,
        userType: "assistantTrainer",
        licenceStatus: "",
        status: 1,
        parentUser: { id: "" },
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = values;

      if (data.owner?.isowner) data.owner.status = 0;
      else data.owner.status = 1;
      if (data.driver?.isdriver) data.driver.status = 0;
      else data.driver.status = 1;
      if (data.trainer?.istrainer) data.trainer.status = 0;
      else data.trainer.status = 1;
      if (data.assistantTrainer?.isassistantTrainer) {
        data.assistantTrainer.status = 0;
        data.assistantTrainer.type = "assistantTrainer";
      } else data.assistantTrainer.status = 1;
      let UserLicences = [
        data.owner,
        data.driver,
        data.trainer,
        data.assistantTrainer,
      ];

      data.IdDocumentUrl = values.idProofUrl;
      data.UserLicences = UserLicences;
      console.log(data);
      UserService.save(data)
        .then((response) => {
          if (response.success) {
            ToastConfig.success("User successfully saved");
            history.push(paths.USER_LIST);
            props.handleClose();
          } else {
            let statuscode = response.statusCode;
            ToastConfig.error(statuscode);
          }
        })
        .catch((error) => {
          console.error(error.message);
          ToastConfig.error(error);
        });
    },
  });
  return (
    <>
      {!isLoading ? (
        <>
          <form onSubmit={handleSubmit} id="UserAddEditForm">
            <Grid
              container
              spacing={2}
              className={matches ? classes.section : " "}
            >
              <Grid container item xs={12} sx={{ display: "inline-block" }}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Grid container spacing={2}>
                        <Grid container item xs={12}>
                          <Typography>
                            <b> {t("PROFILEDETAILS")} </b>
                          </Typography>
                        </Grid>

                        <Grid container item xs={12}>
                          <Grid item md={2}>
                            <AvatarPicker
                              handleChangeImage={handleImageChange}
                              avatarImage={avatarImage}
                              url={values?.profilePictureUrl}
                              updatevalue={setFieldValue}
                            />
                          </Grid>
                          <Grid container item md={10} spacing={2}>
                            <Grid container item xs={12} spacing={2}>
                              <Grid item xs={1} className="fullWidth">
                                <Typography> {t("Title")}</Typography>
                                <MuiTextField
                                  name="title"
                                  id="title"
                                  placeholder={t("Mr")}
                                  value={values.title}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={touched.title && Boolean(errors.title)}
                                  helperText={touched.title && errors.title}
                                />
                              </Grid>
                              <Grid item xs={3} className="fullWidth">
                                <Typography> {t("Forename")}*</Typography>
                                <MuiTextField
                                  name="firstName"
                                  id="firstName"
                                  placeholder={t("Austin")}
                                  value={values.firstName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.firstName &&
                                    Boolean(errors.firstName)
                                  }
                                  helperText={
                                    touched.firstName && errors.firstName
                                  }
                                />
                              </Grid>

                              <Grid item xs={3} className="fullWidth">
                                <Typography>{"Surname *"}</Typography>
                                <MuiTextField
                                  id="lastName"
                                  placeholder={"Robinson"}
                                  value={values.lastName}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  error={
                                    touched.lastName && Boolean(errors.lastName)
                                  }
                                  helperText={
                                    touched.lastName && errors.lastName
                                  }
                                />
                              </Grid>
                              <Grid item xs={3} className="fullWidth">
                                <Typography>{"Email*"}</Typography>
                                <MuiTextField
                                  id="email"
                                  name="email"
                                  placeholder="ex. jhon@example.com"
                                  value={values.email}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  error={touched.email && Boolean(errors.email)}
                                  helperText={touched.email && errors.email}
                                />
                              </Grid>
                            </Grid>
                            <Grid item xs={3} className="fullWidth">
                              <Typography>{"Date of Birth *"}</Typography>
                              <DatePicker
                                name="dateOfBirth"
                                id="dateOfBirth"
                                inputFormat="dd/MM/yyyy"
                                openTo="day"
                                views={["day", "month", "year"]}
                                value={values.dateOfBirth}
                                maxDate={new Date()}
                                onChange={(newValue) => {
                                  setFieldValue("dateOfBirth", newValue);
                                  let year = moment().diff(newValue, "years");
                                  console.log(year);
                                  setFieldValue("age", calculate_age(newValue));
                                }}
                                renderInput={(params) => (
                                  <MuiTextField
                                    {...params}
                                    error={
                                      touched.dateOfBirth &&
                                      Boolean(errors.dateOfBirth)
                                    }
                                    helperText={
                                      touched.dateOfBirth && errors.dateOfBirth
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={1} className="fullWidth">
                              <Typography>{"Age"}</Typography>
                              <MuiTextField
                                id="age"
                                name="age"
                                disabled={true}
                                placeholder="0"
                                value={values.age}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={touched.age && Boolean(errors.age)}
                                helperText={touched.age && errors.age}
                              />
                            </Grid>
                            <Grid item xs={3} className="fullWidth">
                              <Typography>{"Mobile*"}</Typography>

                              <MuiTextField
                                id="phoneNumber"
                                placeholder="0777777777"
                                type={"number"}
                                value={values.phoneNumber}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                error={
                                  touched.phoneNumber &&
                                  Boolean(errors.phoneNumber)
                                }
                                helperText={
                                  touched.phoneNumber && errors.phoneNumber
                                }
                              />
                            </Grid>
                            <Grid item xs={3} className="fullWidth">
                              <Typography>{"Landline  "}</Typography>
                              <MuiTextField
                                id="landline"
                                placeholder={"(+33)7 55 55 33 70"}
                                value={values.landline}
                                onChange={handleChange}
                              />
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item container xs={12} spacing={2}>
                          <Grid item xs={12}>
                            {user && user.id > 0 && (
                              <Typography>
                                {user && user?.syndicates.length > 0
                                  ? "Assigned syndicates"
                                  : "No Assigned Syndicates"}
                              </Typography>
                            )}
                          </Grid>
                          <Grid item container xs={12} spacing={2}>
                            {values.syndicates &&
                              values.syndicates.map((syndicate) => (
                                <Grid
                                  item
                                  container
                                  xl={3}
                                  lg={4}
                                  md={6}
                                  sm={6}
                                  xs={12}
                                  spacing={2}
                                  alignItems={"center"}
                                >
                                  <Grid item>
                                    {" "}
                                    <Button
                                      disableRipple
                                      sx={{ bgcolor: "tableHeader" }}
                                      onClick={(e) => {
                                        setSelectedItem(syndicate);
                                        setopenSyndicateDialog(true);
                                      }}
                                    >
                                      {syndicate.name}
                                    </Button>
                                  </Grid>
                                  <Grid item>
                                    <Typography>
                                      {syndicate.procent + "%"}
                                    </Typography>{" "}
                                  </Grid>

                                  <Grid item>
                                    {" "}
                                    <IconButton
                                      disableRipple
                                      onClick={(e) => {
                                        setSelectedItem(syndicate);
                                        setopenSyndicateDialog(true);
                                      }}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ))}
                          </Grid>
                          <Dialog
                            maxWidth={"lg"}
                            open={openSyndicateDialog}
                            onClose={handleSyndicateDialogClose}
                            PaperProps={{
                              sx: { minWidth: matches ? "100%" : "" },
                            }}
                          >
                            <DialogContent>
                              <Grid container spacing={2}>
                                <Grid item xs={6}>
                                  <Typography variant="h5" sx={{ pb: 6 }}>
                                    Syndicate details
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <IconButton
                                    style={{ float: "right" }}
                                    onClick={handleSyndicateDialogClose}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </Grid>
                                <Grid item xs={12}>
                                  <SyndicateDetails
                                    id={selectedItem?.id ? selectedItem?.id : 0}
                                    currentItem={selectedItem}
                                    source={1}
                                    handleClose={handleSyndicateDialogClose}
                                  />
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions></DialogActions>
                          </Dialog>
                        </Grid>
                        <Grid
                          item
                          container
                          xs={12}
                          spacing={2}
                          alignItems={"center"}
                        >
                          {user && user.id > 0 && (
                            <Grid item>
                              <Typography>
                                {user && user?.horses.length > 0
                                  ? "See Horses Owned"
                                  : "No Horses Owned"}{" "}
                              </Typography>
                            </Grid>
                          )}
                          {user && user?.horses.length > 0 && (
                            <Grid item>
                              <IconButton
                                disableRipple
                                sx={{ bgcolor: "tableHeader" }}
                                onClick={(e) => {
                                  setopenHorseDialog(true);
                                }}
                              >
                                <VisibilityIcon />
                              </IconButton>
                              <DevUserHorsesModel
                                horses={values.horses}
                                open={openHorseDialog}
                                onClose={setopenHorseDialog}
                              />
                            </Grid>
                          )}
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent="flex-start"
                          alignItems="center"
                          xs={12}
                        >
                          <Grid item xs={3} className="fullWidth">
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.printedCalendar}
                                  onChange={handleChange}
                                  name="printedCalendar"
                                  color="primary"
                                />
                              }
                              label="Printed Calendar"
                            />
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={values.onlineCalendar}
                                  onChange={handleChange}
                                  name="onlineCalendar"
                                  color="primary"
                                />
                              }
                              label="Online Calendar"
                            />
                          </Grid>
                          <Grid item xs={3} className="fullWidth">
                            <DevFileOptions
                              updateFormik={setFieldValue}
                              item={values.idProofUrl}
                              fieldValue={"idProofUrl"}
                              fileType={3}
                              lableLeftRight={false}
                              labelText={"Proof of ID document"}
                              textRight={true}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </LocalizationProvider>
                  </CardContent>
                </Card>
              </Grid>
              <Grid container item xs={12} sx={{ display: "inline-block" }}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid container item xs={12}>
                        <Typography>
                          <b>ROLES</b>
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        justifyItems="center"
                        item
                        xs={12}
                        spacing={2}
                      >
                        {" "}
                        <Grid item xs={1} className="fullWidth">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.owner?.isowner}
                                onChange={handleChange}
                                name="owner.isowner"
                                color="primary"
                              />
                            }
                            label="Owner"
                          />
                        </Grid>
                        <Grid
                          item
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          xs={4}
                          spacing={2}
                        >
                          <Grid item className="fullWidth">
                            <Typography sx={{ width: 125 }}>
                              {"License Status*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                displayEmpty
                                size="small"
                                id="owner.licenceStatus"
                                disabled={!values.owner?.isowner}
                                value={values.owner?.licenceStatus}
                                onChange={(e) => {
                                  setFieldValue(
                                    "owner.licenceStatus",
                                    e.target.value
                                  );
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {ownerLicence.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.owner?.licenceStatus &&
                                  touched.owner?.licenceStatus &&
                                  errors.owner?.licenceStatus}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            <Typography sx={{ width: 50 }}>
                              {"License Type*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                autoWidth={false}
                                displayEmpty
                                size="small"
                                id="owner.type"
                                disabled={!values.owner?.isowner}
                                value={values.owner?.type}
                                onChange={(e) => {
                                  setFieldValue("owner.type", e.target.value);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {ownerType.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.owner?.type &&
                                  touched.owner?.type &&
                                  errors.owner?.type}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={3} className="fullWidth">
                          <DevFileOptions
                            updateFormik={setFieldValue}
                            item={values.owner}
                            fileType={1}
                            lableLeftRight={true}
                            labelText={""}
                            disabled={!values.owner?.isowner}
                          />
                          <FormHelperText error={true}>
                            {errors.owner?.licenseUrl &&
                              touched.owner?.licenseUrl &&
                              errors.owner?.licenseUrl}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        justifyItems="center"
                        item
                        xs={12}
                        spacing={2}
                        className="fullWidth"
                      >
                        <Grid item xs={1} className="fullWidth">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.trainer?.istrainer}
                                onChange={handleChange}
                                name="trainer.istrainer"
                                color="primary"
                              />
                            }
                            label="Trainer"
                          />
                        </Grid>

                        <Grid
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          item
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            <Typography sx={{ width: 125 }}>
                              {"License Status*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                displayEmpty
                                size="small"
                                id="trainer.licenceStatus"
                                disabled={!values.trainer?.istrainer}
                                value={values.trainer?.licenceStatus}
                                onChange={(e) => {
                                  setFieldValue(
                                    "trainer.licenceStatus",
                                    e.target.value
                                  );
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {trainerLicence.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.trainer?.licenceStatus &&
                                  touched.trainer?.licenceStatus &&
                                  errors.trainer?.licenceStatus}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            {" "}
                            <Typography sx={{ width: 50 }}>
                              {"License Type*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                displayEmpty
                                size="small"
                                id="trainer.type"
                                disabled={!values.trainer?.istrainer}
                                value={values.trainer?.type}
                                onChange={(e) => {
                                  setFieldValue("trainer.type", e.target.value);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {trainerType.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.trainer?.type &&
                                  touched.trainer?.type &&
                                  errors.trainer?.type}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={3} className="fullWidth">
                          <DevFileOptions
                            updateFormik={setFieldValue}
                            item={values.trainer}
                            fileType={1}
                            lableLeftRight={true}
                            labelText={""}
                            disabled={!values.trainer?.istrainer}
                          />
                          <FormHelperText error={true}>
                            {errors.trainer?.licenseUrl &&
                              touched.trainer?.licenseUrl &&
                              errors.trainer?.licenseUrl}
                          </FormHelperText>
                        </Grid>
                      </Grid>

                      {/* assitant trainer start  */}
                      <Grid
                        container
                        alignItems="center"
                        justifyItems="center"
                        item
                        xs={12}
                        spacing={2}
                        className="fullWidth"
                      >
                        <Grid item xs={1} className="fullWidth">
                          <FormControlLabel
                            control={
                              <Checkbox
                                color="primary"
                                checked={
                                  values.assistantTrainer?.isassistantTrainer
                                }
                              />
                            }
                            name="assistantTrainer.isassistantTrainer"
                            value={values.assistantTrainer?.isassistantTrainer}
                            onChange={(e) => {
                              handelCheckBox(
                                "assistantTrainer.isassistantTrainer",
                                e.target.checked,
                                setFieldValue
                              );
                            }}
                            label="Assistant trainer"
                          />
                        </Grid>

                        <Grid
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          item
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            <Typography sx={{ width: 125 }}>
                              {"License Status*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                displayEmpty
                                size="small"
                                id="assistantTrainer.licenceStatus"
                                disabled={
                                  !values.assistantTrainer?.isassistantTrainer
                                }
                                value={values.assistantTrainer?.licenceStatus}
                                onChange={(e) => {
                                  setFieldValue(
                                    "assistantTrainer.licenceStatus",
                                    e.target.value
                                  );
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {trainerLicence.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.assistantTrainer?.licenceStatus &&
                                  touched.assistantTrainer?.licenceStatus &&
                                  errors.assistantTrainer?.licenceStatus}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            {" "}
                            <Typography sx={{ width: 50 }}>
                              {"Select trainer*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <DevAutoCompleteUser
                                role="trainer"
                                width={"200px"}
                                assistantTrainer={"assistantTrainer.parentUser"}
                                updateformik={setFieldValue}
                                validateField={validateField}
                                placeholder="Please Start Typing"
                                trainer={values.assistantTrainer.parentUser}
                                disabled={
                                  !values.assistantTrainer?.isassistantTrainer
                                }
                              />
                              <FormHelperText
                                error={true}
                                style={{
                                  display: "inline-block",
                                  float: "right",
                                }}
                              >
                                {errors.assistantTrainer?.parentUser?.id &&
                                  errors.assistantTrainer?.parentUser?.id}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      {/* assitant trainer end  */}

                      <Grid
                        container
                        alignItems="center"
                        justifyItems="center"
                        item
                        xs={12}
                        spacing={2}
                        className="fullWidth"
                      >
                        <Grid item xs={1} className="fullWidth">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.driver?.isdriver}
                                onChange={handleChange}
                                name="driver.isdriver"
                                color="primary"
                              />
                            }
                            label="Driver"
                          />
                        </Grid>
                        <Grid
                          item
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            {" "}
                            <Typography sx={{ width: 125 }}>
                              {"License Status*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                id="driver.licenceStatus"
                                disabled={!values.driver?.isdriver}
                                displayEmpty
                                size="small"
                                value={values.driver?.licenceStatus}
                                onChange={(e) => {
                                  setFieldValue(
                                    "driver.licenceStatus",
                                    e.target.value
                                  );
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {driverLicence.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.driver?.licenceStatus &&
                                  touched.driver?.licenceStatus &&
                                  errors.driver?.licenceStatus}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            {" "}
                            <Typography sx={{ width: 50 }}>
                              {"Level*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                id="driver.type"
                                disabled={!values.driver?.isdriver}
                                displayEmpty
                                size="small"
                                value={values.driver?.type}
                                onChange={(e) => {
                                  setFieldValue("driver.type", e.target.value);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {driverLevel.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.driver?.type &&
                                  touched.driver?.type &&
                                  errors.driver?.type}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid item xs={3} className="fullWidth">
                          <DevFileOptions
                            updateFormik={setFieldValue}
                            item={values.driver}
                            drivre={true}
                            fileType={2}
                            lableLeftRight={true}
                            labelText={""}
                            disabled={!values.driver?.isdriver}
                          />

                          <FormHelperText error={true}>
                            {errors.driver?.coloursUrl &&
                              touched.driver?.coloursUrl &&
                              errors.driver?.coloursUrl}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        justifyItems="center"
                        item
                        xs={12}
                        spacing={2}
                        className="fullWidth"
                      >
                        <Grid item xs={1}></Grid>
                        <Grid
                          item
                          direction="row"
                          container
                          alignItems="center"
                          justifyContent="flex-start"
                          xs={4}
                          spacing={2}
                          className="fullWidth"
                        >
                          <Grid item className="fullWidth">
                            {" "}
                            <Typography sx={{ width: 120 }}>
                              {"Class*"}
                            </Typography>
                          </Grid>
                          <Grid item className="fullWidth">
                            <FormControl sx={{ m: 1, minWidth: 200 }}>
                              <Select
                                id="driver.class"
                                disabled={!values.driver?.isdriver}
                                displayEmpty
                                size="small"
                                value={values.driver?.class}
                                onChange={(e) => {
                                  setFieldValue("driver.class", e.target.value);
                                }}
                                input={<OutlinedInput />}
                                renderValue={(selected) => {
                                  return selected ? selected : "Please Select";
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem value="">
                                  <em>Please Select</em>
                                </MenuItem>

                                {driverClass.map((item, i) => (
                                  <MenuItem key={i} value={item}>
                                    {item}
                                  </MenuItem>
                                ))}
                              </Select>
                              <FormHelperText error={true}>
                                {errors.driver?.class &&
                                  touched.driver?.class &&
                                  errors.driver?.class}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>

                        <Grid item xs={4} className="fullWidth">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={values.saddleTrotLicence}
                                onChange={handleChange}
                                name="saddleTrotLicence"
                                color="primary"
                              />
                            }
                            label="Saddle trot licence"
                          />
                        </Grid>

                        <Grid item xs={3} className="fullWidth">
                          <DevFileOptions
                            updateFormik={setFieldValue}
                            item={values.driver}
                            fileType={1}
                            lableLeftRight={true}
                            labelText={""}
                            disabled={!values.driver?.isdriver}
                          />
                          <FormHelperText error={true}>
                            {errors.driver?.licenseUrl &&
                              touched.driver?.licenseUrl &&
                              errors.driver?.licenseUrl}
                          </FormHelperText>
                        </Grid>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>

              <Grid container item xs={12} sx={{ display: "inline-block" }}>
                <Card
                  sx={{
                    background: "#FFFFFF",
                    borderRadius: "15px",
                    boxShadow: "none",
                  }}
                >
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography>
                          <b>ADDRESS</b>
                        </Typography>
                      </Grid>
                      <Grid item xs={4} className="fullWidth">
                        <Typography>{"Country"}</Typography>

                        <MuiTextField
                          name="address.country"
                          value={values.address?.country}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.address?.country &&
                            Boolean(errors.address?.country)
                          }
                          helperText={
                            touched.address?.country && errors.address?.country
                          }
                        />
                      </Grid>
                      <Grid item md={4} xs={4} className="fullWidth">
                        <Typography>{"City"}</Typography>

                        <MuiTextField
                          name="address.city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address?.city}
                          error={
                            touched.address?.city &&
                            Boolean(errors.address?.city)
                          }
                          helperText={
                            touched.address?.city && errors.address?.city
                          }
                        />
                      </Grid>
                      <Grid item md={4} xs={4} className="fullWidth">
                        <Typography>{"Post Code"}</Typography>
                        <MuiTextField
                          name="address.zipCode"
                          value={values.address?.zipCode}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.address?.zipCode &&
                            Boolean(errors.address?.zipCode)
                          }
                          helperText={
                            touched.address?.zipCode && errors.address?.zipCode
                          }
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography>{"Home Address"}</Typography>

                        <MuiTextField
                          name="address.homeAddress"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.address?.homeAddress}
                          error={
                            touched.address?.homeAddress &&
                            Boolean(errors.address?.homeAddress)
                          }
                          helperText={
                            touched.address?.homeAddress &&
                            errors.address?.homeAddress
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                xs={12}
                sx={{ pt: 6 }}
              >
                <Grid item sx={{ pr: 2 }}>
                  <Button
                    sx={{
                      bgcolor: "#F8F8FA",
                      color: "primary.main",
                      textTransform: "capitalize",
                    }}
                    onClick={cancelFn}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  {" "}
                  <Button
                    sx={{
                      bgcolor: "primary.main",
                      textTransform: "capitalize",
                      color: "#fff",
                      float: "right",
                      ":hover": {
                        bgcolor: "primary.dark",
                        color: "white",
                      },
                    }}
                    type="submit"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </>
      ) : (
        <PageLoading />
      )}
    </>
  );
};

export default UserDetails;
