import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import paths from './paths';
import { isSignin } from '../helpers/util';
import Header from '../components/Header';
import { Grid, Box, Dialog, DialogContent, DialogActions } from '@mui/material';
import SideBar from '../components/SideBar';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import { useAuth } from '../context/UserContext';

const useStyles = makeStyles((theme) => ({
  sidebarDesktop: {
    width: '200px',
    float: 'left',
    position: 'relative',
    height: '100vh',
    minHeight: '100vh',
    backgroundColor: `${theme.palette.sideBar} !important`,
    paddingLeft: '8px !important'
  },
  sideContentDesktop: {
    width: 'calc(100% - 200px)',
    float: 'left',
    minHeight: '100vh'
  },

  sidebarMobileOpen: {
    position: 'fixed',
    top: '0',
    zIndex: '9999',
    height: '100vh',
    minHeight: '100vh',
    width: '240px',
    backgroundColor: `${theme.palette.sideBar} !important`,
    transform: 'translateX(0)',
    transition: 'transform 0.3s',
    padding: '0px !important'
  },
  sideContentMobile: {
    width: '100%',
    float: 'left',
    minHeight: '100vh'
  }
}));
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down('sm') ?? '600'
  );
  const toggle = useAuth();

  const classes = useStyles();

  const isLogin = isSignin();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogin ? (
          <Grid container direction='column'>
            <Grid container item xs={12}>
              <Header history={props.history} />
            </Grid>
            <Grid
              container
              item
              direction='row'
              spacing={2}
              sx={{
                height: 'calc(100vh - 2rem)',
                flexWrap: 'initial'
              }}
              pt={6}
              pb={6}
            >
              {matches ? (
                <Box>
                  <Dialog
                    maxWidth={'lg'}
                    open={toggle?.toggle}
                    PaperProps={{ sx: { minWidth: matches ? '100%' : '' } }}
                  >
                    <DialogContent className={classes.sidebarMobileOpen}>
                      <SideBar />
                    </DialogContent>
                  </Dialog>
                </Box>
              ) : (
                <Box className={classes.sidebarDesktop}>
                  <SideBar history={props.history} />
                </Box>
              )}
              <Box
                className={
                  matches
                    ? classes.sideContentMobile
                    : classes.sideContentDesktop
                }
              >
                <Grid
                  xl={12}
                  sm={12}
                  sx={{
                    overflow: 'auto',
                    p: '25px '
                  }}
                >
                  <Component {...props} />
                </Grid>
              </Box>
            </Grid>
          </Grid>
        ) : (
          <>{<Redirect to={{ pathname: paths.SIGNIN }} />}</>
        )
      }
    />
  );
};

export default PrivateRoute;
