import React, { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Grid,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Box,
  Button,
  IconButton,
  Typography,
  Menu,
  useMediaQuery,
  Drawer,
  CardContent
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { NoData } from '../../components/icons/NoData';
import { useHistory } from 'react-router';
import DevConfirmDialog from '../../components/dialog/DevConfirmDialog';
import MuiTextField from '../../components/MuiTextField';
import { alpha, styled } from '@mui/system';
import paths from '../../routing/paths';
import AddIcon from '@mui/icons-material/Add';
import DevPagination from '../../components/DevPagination';
import { InActivateIcon } from '../../components/icons/InActivateIcon';
import { ActivateIcon } from '../../components/icons/ActivateIcon';
import { useTheme } from '@emotion/react';
import CloseIcon from '@mui/icons-material/Close';
import { TrackService } from '../../services/TrackServices';
import TrackDetails from './TrackDetails';
import { SeeMeetingsIcon } from '../../components/icons/SeeMeetingsIcon';
import { PageLoading } from '../../components/icons/PageLoading';
import { useAuth } from '../../context/UserContext';
import { makeStyles } from '@mui/styles';
const headCells = [
  { id: 'name ', label: 'NAME' },
  { id: 'address', label: 'ADDRESS' },
  { id: 'country', label: 'COUNTRY' },
  { id: 'maxOnGate', label: 'MAX ON GATE' },
  { id: 'circumference', label: 'CIRCUMFERENCE' },
  { id: 'abbrevitation', label: 'ABBREVITTON' },
  { id: 'action', label: 'ACTIONS' }
];
const useStyles = makeStyles((theme) => ({
  thead: {
    '& th:first-child': {
      borderRadius: '5px 0 0 5px'
    },
    '& th:last-child': {
      borderRadius: '0 5px 5px 0'
    },
    '& th': {
      borderBottom: '0px'
    }
  },
  tableRow: {
    '&  td': {
      cursor: 'pointer'
    }
  },
  tableMobile: {
    '& table': {
      border: 0
    },
    ' & table caption': {
      fontSize: '1.3em'
    },
    '& table thead': {
      border: 'none',
      clip: 'rect(0 0 0 0)',
      height: '1px',
      margin: '-1px',
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      width: '1px'
    },

    '& table tr': {
      borderBottom: '3px solid #ddd',
      display: 'block',
      marginBottom: '.625em',
      border: '1px solid #ddd',
      borderRadius: '04px'
    },

    '& table td': {
      borderBottom: '1px solid #ddd',
      display: 'block',
      fontSize: '.8em',
      textAlign: 'right',
      cursor: 'pointer'
    },

    '& table td::before': {
      content: 'attr(data-label)',
      float: 'left',
      fontWeight: 500
    },

    '& table td:last-child': {
      borderBottom: 0
    },
    '& .avatar': {
      display: 'flex',
      justifyContent: 'end'
    },
    '& .roles': {
      display: 'flex',
      justifyContent: 'end'
    },
    '& .action': {
      textAlign: 'left',
      padding: ' 0px 0px 0px 5px'
    }
  },
  topButton: {
    '& .fullWidth': {
      maxWidth: '100% !important',
      minWidth: '100%',
      width: '100% !important'
    }
  },
  section: {
    '& .fullWidth': {
      maxWidth: '100% !important',
      minWidth: '100%',
      width: '100% !important'
    }
  }
}));
const StyledMenu = styled((props) => (
  <Menu
    disableScrollLock={true}
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right'
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light'
        ? 'rgb(55, 65, 81)'
        : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0'
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));
const TrackList = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [records, setRecords] = useState([]);
  const [search, setSearch] = useState('');
  const [term, setTerm] = useState('');
  const [topHeaderName, setTopHeaderName] = useState('');
  const classes = useStyles();
  const [tabValue, setTabValue] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPageElements, setTotalPageElements] = useState(0);
  const [takeCount, setTakeCount] = useState(10);
  const [page, setPage] = useState(1);
  const history = useHistory();
  const [selectedItem, setSelectedItem] = useState(null);
  const deleteStatus = 2;
  const activeStatus = 0;
  const inActiveStatus = 1;
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: '',
    subTitle: ''
  });
  const { SearchName } = useAuth();

  const searchName = useAuth();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openAction = Boolean(anchorEl);
  const [openDialog, setOpenDialog] = React.useState(false);

  const theme = useTheme();

  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down('sm') ?? '600'
  );
  const handleClick = (event, item) => {
    setSelectedItem(item);
    setAnchorEl(event.currentTarget);
  };
  const onClickEdit = (item) => {
    setSelectedItem(item);
    setOpenDialog(true);
  };
  const handleClose = () => {
    setSelectedItem(null);
    setAnchorEl(null);
  };

  const handleChange = (event, value) => {
    setPage(value);
    setTakeCount(10);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setPage(1);
  };

  const handelEdit = () => {
    setOpenDialog(true);
    setAnchorEl(null);
  };
  const handelAdd = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = () => {
    setOpenDialog(false);
    setSelectedItem(null);
    loadData(tabValue);
  };
  const handleSetStatus = (statusType) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false
    });
    //Delete service call
    var raw = {
      id: selectedItem.id,
      status: statusType
    };
    TrackService.delete(raw).then((response) => {
      loadData(tabValue);
    });
    handleClose();
  };
  const handelSeeMeetings = () => {
    SearchName(selectedItem.name);
    history.push(paths.MEETING_LIST);
  };

  useEffect(() => {
    if (searchName && searchName.searchName) {
      const search = searchName && searchName.searchName;
      setSearch(search.trim());
      setTerm(search.trim());
      setTopHeaderName(search.trim());
      SearchName(null);
    }
  }, []);
  useEffect(() => {
    loadData(tabValue);
  }, [page, tabValue, term, takeCount]);

  const loadData = (activeIndex) => {
    setIsLoading(true);
    var raw = {
      Page: page - 1,
      TakeCount: takeCount,
      ActiveIndex: activeIndex,
      UserId: 0,
      Term: term
    };
    TrackService.getPage(raw).then((response) => {
      if (activeIndex === tabValue) {
        if (response.success) {
          setRecords(response.data.data);
          setTotalPages(response.data.totalPages);
          setCurrentPage(response.data.currentPage);
          setTotalPageElements(response.data.totalElementsCount);
        }
      }
      setIsLoading(false);
    });
  };
  return (
    <>
      <Grid item xs={12}>
        <Typography variant='subtitle1' sx={{ pt: 6, pb: 6 }}>
          <b>Tracks</b>
        </Typography>
      </Grid>
      <Grid container background='#E5E5E5' spacing={6}>
        <Grid item xs={12}>
          <Grid
            container
            spacing={2}
            className={matches ? classes.topButton : ' '}
          >
            <Grid item xs={5} className='fullWidth'>
              <MuiTextField
                placeholder='Search'
                search={true}
                id='search'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    setTerm(search);
                  }
                }}
              />
            </Grid>
            <Grid item xs={1} className='fullWidth'>
              <Button
                sx={{
                  bgcolor: 'primary.main',
                  textTransform: 'capitalize',
                  color: '#fff',
                  width: '100%',
                  ':hover': {
                    bgcolor: 'primary.dark',
                    color: 'white'
                  }
                }}
                onClick={(e) => setTerm(search)}
              >
                Submit
              </Button>
            </Grid>
            <Grid item xs={1} className='fullWidth'>
              <Button
                sx={{
                  width: '100%',
                  background: '#FFF',
                  border: '1px solid #F1F1F5',
                  textTransform: 'capitalize'
                }}
                onClick={(e) => {
                  setSearch('');
                  setTerm('');
                  setTopHeaderName('');
                }}
                variant='outlined'
              >
                Clear
              </Button>
            </Grid>
            <Grid item xs={3}></Grid>
            <Grid item xs={2} className='fullWidth'>
              <Button
                sx={{
                  bgcolor: 'primary.main',
                  textTransform: 'capitalize',
                  color: '#fff',
                  float: 'right',
                  ':hover': {
                    bgcolor: 'primary.dark',
                    color: 'white'
                  }
                }}
                onClick={handelAdd}
              >
                <AddIcon /> Add Track
              </Button>
              <Drawer
                open={openDialog}
                onClose={handleDialogClose}
                anchor='right'
                sx={{
                  flexShrink: 0,
                  [`& .MuiDrawer-paper`]: {
                    width: matches ? '100%' : '87%',
                    boxSizing: 'border-box'
                  }
                }}
              >
                <CardContent className={matches ? classes.topButton : ' '}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant='h5' sx={{ pb: 6 }}>
                        {' '}
                        <b>
                          {selectedItem?.id > 0
                            ? 'Edit track details'
                            : 'Track details'}
                        </b>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography
                        style={{ float: 'right' }}
                        onClick={handleDialogClose}
                      >
                        <IconButton>
                          <CloseIcon />
                        </IconButton>
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TrackDetails
                        id={selectedItem?.id ? selectedItem?.id : 0}
                        currentItem={selectedItem}
                        source={2}
                        handleClose={handleDialogClose}
                        tabValue={tabValue}
                        matches={matches}
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Drawer>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Paper sx={{ borderRadius: '15px', boxShadow: 'none' }}>
            <Box
              borderColor='#D7DEEC'
              sx={{
                background: '#FFFFFF',
                border: '1px solid #FFFFFF',
                boxSizing: 'borderBox',
                borderRadius: '15px'
              }}
            >
              <Box sx={{ m: 1 }}>
                <Tabs
                  onChange={handleTabChange}
                  value={tabValue}
                  aria-label='Users Tabs'
                  selectionFollowsFocus
                  sx={{ pb: 2 }}
                >
                  <Tab
                    sx={{ letterSpacing: '0em', textTransform: 'capitalize' }}
                    label={'Active'}
                  />
                  <Tab
                    sx={{ letterSpacing: '0em', textTransform: 'capitalize' }}
                    label={'Inactive'}
                  />
                </Tabs>

                {!isLoading ? (
                  <>
                    {' '}
                    {records.length > 0 ? (
                      <>
                        <TableContainer
                          className={matches ? classes.tableMobile : ' '}
                        >
                          <Table
                            sx={{
                              minWidth: matches ? '100%' : '650',
                              pt: 6,
                              pb: 6
                            }}
                            aria-label='simple table'
                            size='small'
                          >
                            <TableHead
                              sx={{ bgcolor: 'tableHeader' }}
                              className={classes.thead}
                            >
                              {headCells.map((headCell) => (
                                <TableCell key={headCell.id}>
                                  {headCell.disableSorting ? (
                                    headCell.label
                                  ) : (
                                    <>{headCell.label}</>
                                  )}
                                </TableCell>
                              ))}
                            </TableHead>
                            <TableBody>
                              {records &&
                                records.map((item, i) => (
                                  <TableRow
                                    key={i}
                                    className={classes.tableRow}
                                  >
                                    <TableCell
                                      data-label='Name'
                                      onClick={() => onClickEdit(item)}
                                    >
                                      {item.name}
                                    </TableCell>
                                    <TableCell
                                      data-label='Address'
                                      onClick={() => onClickEdit(item)}
                                    >
                                      {item.address}
                                    </TableCell>
                                    <TableCell
                                      data-label='Country'
                                      onClick={() => onClickEdit(item)}
                                    >
                                      {item.country}
                                    </TableCell>
                                    <TableCell
                                      data-label='Max On Gate'
                                      onClick={() => onClickEdit(item)}
                                    >
                                      {item.maxOnGate}
                                    </TableCell>
                                    <TableCell
                                      data-label='Circumference'
                                      onClick={() => onClickEdit(item)}
                                    >
                                      {item.circumference}
                                    </TableCell>
                                    <TableCell
                                      data-label='Abbrevitation'
                                      onClick={() => onClickEdit(item)}
                                    >
                                      {item.abbrevitation}
                                    </TableCell>
                                    <TableCell className='action'>
                                      <IconButton
                                        id='demo-customized-button'
                                        aria-controls='demo-customized-menu'
                                        aria-haspopup='true'
                                        aria-expanded={
                                          openAction ? 'true' : undefined
                                        }
                                        variant='contained'
                                        onClick={(e) => {
                                          handleClick(e, item);
                                        }}
                                      >
                                        <MoreHorizIcon />
                                      </IconButton>
                                      <StyledMenu
                                        id='demo-customized-menu'
                                        MenuListProps={{
                                          'aria-labelledby':
                                            'demo-customized-button'
                                        }}
                                        anchorEl={anchorEl}
                                        open={openAction}
                                        onClose={handleClose}
                                      >
                                        <MenuItem
                                          onClick={handelEdit}
                                          disableRipple
                                        >
                                          <EditIcon />
                                          View / Edit
                                        </MenuItem>
                                        {tabValue === 0 && (
                                          <>
                                            <MenuItem
                                              onClick={handelSeeMeetings}
                                              disableRipple
                                            >
                                              <SeeMeetingsIcon /> See Meetings{' '}
                                            </MenuItem>
                                          </>
                                        )}
                                        <MenuItem
                                          onClick={(e) => {
                                            setConfirmDialog({
                                              isOpen: true,
                                              title:
                                                tabValue === 0
                                                  ? 'Are you sure to Inactivate this record'
                                                  : 'Are you sure to Activate this record',
                                              subTitle:
                                                'You can undo this operation',
                                              onConfirm: () => {
                                                handleSetStatus(
                                                  tabValue === 0
                                                    ? inActiveStatus
                                                    : activeStatus
                                                );
                                              }
                                            });
                                            handleClose();
                                          }}
                                          disableRipple
                                        >
                                          {tabValue === 0 ? (
                                            <InActivateIcon />
                                          ) : (
                                            <ActivateIcon />
                                          )}
                                          {tabValue === 0
                                            ? 'Inactivate'
                                            : 'Activate'}
                                        </MenuItem>

                                        {tabValue === 1 && (
                                          <MenuItem
                                            onClick={(e) => {
                                              setConfirmDialog({
                                                isOpen: true,
                                                title:
                                                  'Are you sure to delete this record?',
                                                subTitle:
                                                  "You can't undo this operation",
                                                onConfirm: () => {
                                                  handleSetStatus(deleteStatus);
                                                }
                                              });
                                              handleClose();
                                            }}
                                            disableRipple
                                          >
                                            <DeleteIcon />
                                            Delete
                                          </MenuItem>
                                        )}
                                      </StyledMenu>
                                    </TableCell>
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <DevPagination
                          count={totalPages}
                          currentPage={currentPage}
                          pageElements={totalPageElements}
                          takeCount={takeCount}
                          setTakeCount={setTakeCount}
                          page={page}
                          setPage={setPage}
                          onChange={handleChange}
                          shape='rounded'
                        />
                      </>
                    ) : (
                      <NoData />
                    )}
                  </>
                ) : (
                  <PageLoading />
                )}
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>

      <DevConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>
  );
};

export default TrackList;
