import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Button,
  CardContent,
  Grid,
  Typography,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Card,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DevAutoCompleteUser from "./DevAutoCompleteUser";

const DevRaceOwnerHorsesTrainerDriverModel = (props) => {
  const {
    openDialog,
    handleDialog,
    matches,
    raceHorses,
    updateFormik,
    horseIndex,
    selectedHorse,
  } = props;
  const [errprMessage, setError] = React.useState("");
  useEffect(() => {
    if (selectedHorse) {
      console.log(selectedHorse);
      let owners = selectedHorse?.allOwners ? selectedHorse?.allOwners : [];
      let driver = selectedHorse?.driverDetails
        ? selectedHorse?.driverDetails
        : {};
      let trainer = selectedHorse?.trainerDetails
        ? selectedHorse?.trainerDetails
        : {};
      setFieldValue("driver", driver);
      setFieldValue("trainer", trainer);
      setFieldValue("owners", owners);
    }
  }, [selectedHorse]);
  const validationSchema = yup.object({
    // name: yup.string("Enter  name").required(" Name is required"),
  });
  const {
    handleSubmit,
    touched,
    values,
    errors,
    setFieldValue,
    validateField,
  } = useFormik({
    initialValues: {
      driver: { id: 0 },
      trainer: { id: 0 },
      owners: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = values;
      let tempIdgenrated = "";
      if (data && data?.owners.length > 0) {
        let horse = raceHorses.map((item, index) => {
          tempIdgenrated = JSON.stringify(
            Math.floor(Math.random() * 1000000)
          );
          if (horseIndex === index) {
            return {
              ...item,
              tempId: tempIdgenrated,
              allOwners: data?.owners,
              trainer:
                data.trainer && data?.trainer?.name ? data?.trainer?.name : "",
              trainerDetails: data.trainer ? data.trainer : {},
              driver:
                data.driver && data?.driver?.name ? data?.driver?.name : "",
              driverDetails: data.driver ? data.driver : {},
            };
          } else {
            return { ...item };
          }
        });
        const _raceHorses = [...(horse ?? [])];
        console.log(_raceHorses);
        updateFormik("raceHorses", _raceHorses);
        setError("");
        console.log(data);
        handleDialog();
      } else {
        setError("Owner is required");
      }
    },
  });
  return (
    <>
      <Dialog
        maxWidth={"lg"}
        open={openDialog}
        onClose={() => {
          // resetForm();
          handleDialog();
        }}
        PaperProps={{ sx: { minWidth: matches ? "100%" : "" } }}
      >
        <DialogContent sx={{ maxWidth: "590px" }}>
          <form onSubmit={handleSubmit} id="add">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  style={{ float: "right" }}
                  onClick={() => {
                    handleDialog();
                  }}
                >
                  <IconButton>
                    <CloseIcon />
                  </IconButton>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h5"
                  sx={{
                    fontWeight: 600,
                    fontSize: "20px",
                    lineHeight: "36px",
                  }}
                >
                  Horse name: {selectedHorse ? selectedHorse?.horseName : ""}
                </Typography>

                <Typography
                  variant="h6"
                  sx={{
                    pb: 6,
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "36px",
                    color: "rgba(0, 0, 0, 0.5)",
                  }}
                >
                  Will change only for this race
                </Typography>
              </Grid>

              <Grid item xs={12} sx={{ display: "inline-block", mb: 5 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} spacing={2}>
                    <Grid item>
                      <Typography>
                        <b> OWNERSHIP</b>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    justifyItems={"center"}
                    alignItems={"flex-start"}
                    spacing={2}
                    className="fullWidth"
                  >
                    <DevAutoCompleteUser
                      role="owner"
                      updateformik={setFieldValue}
                      placeholder="Please start typing"
                      owners={values.owners}
                      raceOwners={true}
                      source={3}
                      ownership={"ownership"}
                    />
                  </Grid>
                  {values?.owners.length <= 0 && (
                    <Grid item xs={12} spacing={2}>
                      {errprMessage && (
                        <FormHelperText sx={{ my: 1 }} error={true}>
                          {errprMessage}
                        </FormHelperText>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  justifyItems={"center"}
                  alignContent={"center"}
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <Typography>{"Trainer"}</Typography>
                    <DevAutoCompleteUser
                      role="trainer"
                      updateformik={setFieldValue}
                      placeholder="Please start typing"
                      trainer={values.trainer}
                      validateField={validateField}
                      width={"100%"}
                      addOwner={true}
                    />
                    <FormHelperText
                      error={true}
                      style={{ display: "inline-block", float: "right" }}
                    >
                      {errors.trainer?.id &&
                        touched.trainer?.id &&
                        errors.trainer?.id}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography>{"Driver"}</Typography>
                    <DevAutoCompleteUser
                      role="driver"
                      updateformik={setFieldValue}
                      placeholder="Please start typing"
                      driver={values.driver}
                      validateField={validateField}
                      width={"100%"}
                      addOwner={true}
                    />

                    <FormHelperText
                      error={true}
                      style={{ display: "inline-block", float: "right" }}
                    >
                      {errors.driver?.id &&
                        touched.driver?.id &&
                        errors.driver?.id}
                    </FormHelperText>
                  </Grid>

                  <Grid
                    item
                    container
                    justifyContent={"center"}
                    alignContent={"center"}
                    xs={12}
                  >
                    <Button
                      sx={{
                        bgcolor: "primary.main",
                        minWidth: "170px",
                        textTransform: "capitalize",
                        color: "#fff",
                        marginTop: "30px",
                        ":hover": {
                          bgcolor: "primary.dark",
                          color: "white",
                        },
                      }}
                      type="submit"
                    >
                      OK
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default DevRaceOwnerHorsesTrainerDriverModel;
