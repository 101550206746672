import React, { useState } from "react";
import {
  Grid,
  Paper,
  Box,
  Typography,
  FormHelperText,
  FormControl,
  Button,
} from "@mui/material";
import DevAutoCompleteUser from "../../components/DevAutoCompleteUser";
import { useFormik } from "formik";
import endpoints from "../../constants/endpoints";
import { Loading } from "../../components/icons/Loading";
import VerticalAlignBottomIcon from "@mui/icons-material/VerticalAlignBottom";

const History = (props) => {
  const [isLoading, setIsHandleSubmit] = useState(false);
  const [error, setError] = useState("");

  const { values, errors, setFieldValue, validateField, handleSubmit } =
    useFormik({
      initialValues: {
        ownerSelect: { id: 0 },
        driver: { id: 0 },
        trainer: { id: 0 },
        horse: { id: 0 },
      },
      onSubmit: (values) => {
        let data = values;
        if (
          values.driver?.id ||
          values.trainer?.id ||
          values.ownerSelect?.id ||
          values.horse?.id
        ) {
          downloadHistoryReports(data);
        } else {
          setError("Please select at least one field.");
        }
      },
    });

  const downloadHistoryReports = async (values) => {
    setIsHandleSubmit(true);
    let data = {
      horseIdList:values.horse?.id === 0 ? null: [values.horse?.id],
      ownerId: values.ownerSelect?.id,
      trainerId: values.trainer?.id,
      driverId: values.driver?.id,
    };
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: "follow",
    };
    await fetch(endpoints.REPORTS.GET_HISTORY, requestOptions)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        let dateTime = new Date();
        a.href = url;
        a.download = `history.${dateTime.toString()}.csv`;
        document.body.appendChild(a);
        a.click();
        a.remove();
        setIsHandleSubmit(false);
      });
  };
  return (
    <form onSubmit={handleSubmit} id="UserAddEditForm">
      <Grid item xs={12}>
        <Typography variant="subtitle1" sx={{ pt: 6, pb: 6 }}>
          <b>History report</b>
        </Typography>
      </Grid>
      <Grid container background="#E5E5E5" spacing={6}>
        <Grid item xs={12}>
          <Paper sx={{ borderRadius: "15px", boxShadow: "none" }}>
            <Box
              borderColor="#D7DEEC"
              sx={{
                background: "#FFFFFF",
                border: "1px solid #FFFFFF",
                boxSizing: "borderBox",
                borderRadius: "15px",
              }}
            >
              <Box sx={{ m: 2 }}>
                <Grid container spacing={2} alignItems={"center"}>
                  <Grid item xs={3} className="fullWidth">
                    <Typography>{"Horse"}</Typography>
                    <FormControl sx={{ width: " 100%" }}>
                      <DevAutoCompleteUser
                        role="horse"
                        updateformik={setFieldValue}
                        placeholder="Please start typing"
                        horse={values.horse}
                      />
                    </FormControl>
                    <FormHelperText
                      error={true}
                      style={{
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      {errors.ownerSelect?.id && errors.ownerSelect?.id}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={3} className="fullWidth">
                    <Typography>{"Owner"}</Typography>
                    <FormControl sx={{ width: " 100%" }}>
                      <DevAutoCompleteUser
                        role="ownerSelect"
                        updateformik={setFieldValue}
                        placeholder="Please start typing"
                        ownerSelect={values.ownerSelect}
                      />
                    </FormControl>
                    <FormHelperText
                      error={true}
                      style={{
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      {errors.ownerSelect?.id && errors.ownerSelect?.id}
                    </FormHelperText>
                  </Grid>

                  <Grid item xs={3} className="fullWidth">
                    <Typography>{"Trainer"}</Typography>
                    <FormControl sx={{ width: " 100%" }}>
                      <DevAutoCompleteUser
                        role="trainer"
                        updateformik={setFieldValue}
                        placeholder="Please start typing"
                        trainer={values.trainer}
                        validateField={validateField}
                      />
                    </FormControl>
                    <FormHelperText
                      error={true}
                      style={{
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      {errors.trainer?.id && errors.trainer?.id}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={3} className="fullWidth">
                    <Typography>{"Driver"}</Typography>
                    <FormControl sx={{ width: " 100%" }}>
                      <DevAutoCompleteUser
                        role="driver"
                        updateformik={setFieldValue}
                        placeholder="Please start typing"
                        driver={values.driver}
                        validateField={validateField}
                      />
                    </FormControl>
                    <FormHelperText
                      error={true}
                      style={{
                        display: "inline-block",
                        float: "right",
                      }}
                    >
                      {errors.driver?.id && errors.driver?.id}
                    </FormHelperText>
                  </Grid>
                  <Grid item xs={12} className="fullWidth">
                    {error && (
                      <FormHelperText error={true} style={{ float: "left" }}>
                        {values.driver?.id ||
                        values.trainer?.id ||
                        values.ownerSelect?.id ||
                        values.horse?.id
                          ? ""
                          : error}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} className="fullWidth">
                    {!isLoading ? (
                      <Button
                        sx={{
                          mt: 2,
                          bgcolor: "primary.main",
                          textTransform: "capitalize",
                          color: "#FFF",
                          ":hover": {
                            bgcolor: "primary.dark",
                            color: "white",
                          },
                        }}
                        type="submit"
                      >
                        <VerticalAlignBottomIcon /> Download
                      </Button>
                    ) : (
                      <Loading />
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
};

export default History;
