import React, { useEffect, useState } from 'react';
import {
  Pagination,
  PaginationItem,
  Stack,
  Grid,
  Typography,
  Box,
  Select,
  MenuItem,
  OutlinedInput,
  useMediaQuery
} from '@mui/material';
import { BackPageIcon } from './icons/BackPageIcon';
import { NextPageIcon } from './icons/NextPageIcon';
import ArrowUpIcon from './icons/ArrowUpIcon.png';
import { makeStyles } from '@mui/styles';

const Counts = [10, 25, 50, 100];

const useStyles = makeStyles((theme) => ({
  select: {
    padding: '5.5px 12px !important',
    paddingRight: '32px !important',
    '& .MuiSelect-select': {
      padding: '5.5px 12px '
    },
    '& .MuiSelect-icon': {
      color: theme.palette.primary.main
    },
    '& .Mui-focused': {
      border: '1px solid #E2E2EA'
    },
    '& :after': {
      content: '',
      display: 'block',
      width: '0px',
      height: '100%',
      position: 'absolute',
      border: '1px solid black',
      background: '#191970',
      top: 0,
      left: '100px',
    }
  },
  iconUp: {
    backgroundImage: `url(${ArrowUpIcon})`,
    backgroundPosition: 'top center',
    backgroundRepeat: 'no-repeat',
    fill: `${theme.palette.primary.main} !important`,
    fontSize: '24px',
    marginTop: '4px',
    outline: 'none'
  },
  iconOpen: {
    outline: 'none',
    fontSize: '24px',
    marginTop: '-5px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top center'
  },
  dropdown: {
    justifyContent: 'end',
    display: 'flex',
    alignItems: 'center'
  },
  dropdownMobile: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center'
  }
}));
function DevPagination(props) {
  const matches = useMediaQuery(
    (_theme) => _theme?.breakpoints?.down('sm') ?? '600'
  );
  const classes = useStyles();
  const { currentPage, setPage, takeCount, setTakeCount, pageElements } = props;
  const [startElement, setStartElement] = useState(0);
  const [endElement, setEndElement] = useState(0);
  const handlechage = (e) => {
    setTakeCount(e.target.value);
    setPage(1);
  };
  useEffect(() => {
    setStartElement(currentPage * takeCount + 1);
    setEndElement(
      currentPage * takeCount + takeCount < pageElements
        ? currentPage * takeCount + takeCount
        : pageElements
    );
  }, []);

  return (
    <Stack spacing={2} sx={{ pt: 2 }}>
      <Grid container spacing={2} sx={{display: matches ?  'block' : ''}}>
        <Grid item xs={6}  sm={6}>
          <Typography sx={{ ml: 1 }}>
            {startElement}-{endElement} of {pageElements} items
          </Typography>
        </Grid>
        <Grid item xs={6} sm={6} sx={{maxWidth: matches ?  '100%' : ''}}>
          <Box
            className={matches ? classes.dropdownMobile : classes.dropdown}
          >
            <Pagination
              count={props.count}
              page={props.page}
              onChange={props.onChange}
              shape='rounded'
              renderItem={(item) => (
                <PaginationItem
                  page={props.page}
                  onChange={props.onChange}
                  components={{ previous: BackPageIcon, next: NextPageIcon }}
                  {...item}
                />
              )}
            />
            <Select
              classes={{
                select: classes.select,
                icon: classes.iconUp,
                iconOpen: classes.iconOpen
              }}
              autoWidth={true}
              displayEmpty
              size='small'
              name='takeCount'
              value={props.takeCount}
              onChange={(e) => {
                handlechage(e);
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                return selected ? selected : 'Please Select';
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              {Counts.map((item, i) => (
                <MenuItem key={i} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </Grid>
      </Grid>
    </Stack>
  );
}

export default DevPagination;
