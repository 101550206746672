
import { Box } from "@mui/material";
import { useTheme } from "@mui/styles";
import React from "react";

export const ActivateIcon = () => {
    const theme = useTheme();

    return (
        <Box sx={{ mr: '12px' }} >
            <svg width="18" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.84485 2.84211C2.84485 1.27184 4.11791 0 5.68969 0C7.26147 0 8.53454 1.27184 8.53454 2.84211C8.53454 4.41237 7.26147 5.68421 5.68969 5.68421C4.11791 5.68421 2.84485 4.41237 2.84485 2.84211ZM5.07805 11.1908L4.8718 10.6579L5.07805 10.125C5.5759 8.88158 6.4578 7.91526 7.54595 7.26158C6.96276 7.16211 6.34401 7.10526 5.68969 7.10526C2.54614 7.10526 0 8.3771 0 9.94737V11.3684H5.17051C5.14206 11.3045 5.1065 11.2476 5.07805 11.1908ZM10.6682 9.94737C10.2699 9.94737 9.95696 10.26 9.95696 10.6579C9.95696 11.0558 10.2699 11.3684 10.6682 11.3684C11.0665 11.3684 11.3794 11.0558 11.3794 10.6579C11.3794 10.26 11.0665 9.94737 10.6682 9.94737ZM14.9354 10.6579C14.2669 12.3205 12.6098 13.5 10.6682 13.5C8.72657 13.5 7.06944 12.3205 6.4009 10.6579C7.06944 8.99526 8.72657 7.81579 10.6682 7.81579C12.6098 7.81579 14.2669 8.99526 14.9354 10.6579ZM12.4462 10.6579C12.4462 9.67737 11.6496 8.88158 10.6682 8.88158C9.6867 8.88158 8.89014 9.67737 8.89014 10.6579C8.89014 11.6384 9.6867 12.4342 10.6682 12.4342C11.6496 12.4342 12.4462 11.6384 12.4462 10.6579Z" fill={theme.palette.primary.main} />
            </svg>
        </Box>
    )
}