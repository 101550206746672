import endpoints from "../constants/endpoints";
import { fetchWrapper } from "../helpers/fetchWrapper";

export const TrackService = {
  getPage,
  getById,
  save,
  delete: _delete,
};

function getById(id) {
  return fetchWrapper.get(endpoints.TRACKS.GET_BY_ID + id);
}
function getPage(params) {
  return fetchWrapper.post(endpoints.TRACKS.GET, params);
}
function save(params) {
  return fetchWrapper.post(endpoints.TRACKS.SAVE, params);
}
function _delete(params) {
  return fetchWrapper.post(endpoints.TRACKS.SET_STATUS, params);
}
