import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  CardContent,
  FormHelperText,
  Grid,
  Typography
} from '@mui/material';
import DevAutoCompleteUser from '../../components/DevAutoCompleteUser';
import MuiTextField from '../../components/MuiTextField';
import { SyndicateService } from '../../services/SyndicateServices';
import ToastConfig from '../../components/toast/toast';

const SyndicateDetails = (props) => {
  const [syndicate, setSyndicate] = useState(null);

  useEffect(() => {
    loadData(props.id);
    console.log(props.currentItem);
    return () => {};
  }, []);
  const loadData = (id) => {
    console.log(props.id);
    SyndicateService.getById(id).then((response) => {
      if (response.success) {
        setSyndicate(response.data);
      } else {
        props.id !== 0 && ToastConfig.error('Server Error');
      }
    });
  };
  useEffect(() => {
    if (syndicate) {
      let owners = syndicate?.members ? syndicate?.members : [];
      setFieldValue('owners', owners);
      setFieldValue('id', syndicate?.id);
      setFieldValue('name', syndicate?.name);
      setFieldValue('email', syndicate?.email);
      setFieldValue(
        'totalShareHolding',
        owners.reduce((a, b) => +a + +b.procent, 0)
      );
    }
  }, [syndicate]);
  const validationSchema = yup.object({
    name: yup
      .string('Enter Syndicate name')
      .required('Syndicate name is required'),
    email: yup
      .string('Enter contact email address')
      .email('Please enter a valid email')
      .required('Email is required'),
    totalShareHolding: yup
      .number()
      .max(100, 'Need to be 100 %')
      .min(100, 'Need to be 100 %')
      .required('Need to be 100%')
  });
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: 0,
      name: '',
      email: '',
      totalShareHolding: 0,
      owners: []
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let data = values;
      let members = values.owners;
      data.members = members;
      SyndicateService.save(data).then((response) => {
        if (response.success) {
          ToastConfig.success('Syndicate successfully saved');
          props.handleClose();
        } else ToastConfig.error(response.statusCode);
      });
    }
  });
  return (
    <>
      <form onSubmit={handleSubmit} id='SyndicateAddEditForm'>
        <Grid
          container
          justifyItems={'center'}
          alignContent={'center'}
          spacing={2}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <Box
                  sx={{
                    maxWidth: '50%'
                  }}
                >
                  <Typography>{'Name*'}</Typography>
                  <MuiTextField
                    id='name'
                    width='50%'
                    placeholder={'Dooley Thoroughbreds'}
                    disabled={props.source !== 2}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Box>
              </Grid>
              <Grid item lg={6} xs={12}>
                <Box
                  sx={{
                    maxWidth: '50%'
                  }}
                >
                  <Typography>{'Contact Email*'}</Typography>
                  <MuiTextField
                    id='email'
                    placeholder={'dooleythoroughbreds@gmail.com'}
                    width='50%'
                    value={values.email}
                    disabled={props.source !== 2}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Box>
              </Grid>
              <Grid container item xs={12} direction={'row'} spacing={2}>
                <Grid item>
                  <Typography variant='h6'>Shareholding*</Typography>
                </Grid>
                <Grid item>
                  <Typography variant='h6'>
                    {values.totalShareHolding + '%'}
                  </Typography>
                </Grid>
                <Grid item>
                  <FormHelperText
                    error={true}
                    style={{ display: 'inline-block' }}
                  >
                    {errors.totalShareHolding && errors.totalShareHolding}
                  </FormHelperText>
                </Grid>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyItems={'center'}
                alignItems={'flex-start'}
                spacing={2}
              >
                <Grid item>
                  <Typography variant='h6'>Add member</Typography>
                </Grid>
                <DevAutoCompleteUser
                  role='owner'
                  updateformik={setFieldValue}
                  placeholder=' Please start typing'
                  owners={values.owners}
                  source={props.source}
                  currentSyndicateId={props.id}
                />
              </Grid>
              <Grid item container xs={12} direction={'row'} spacing={2}>
                {syndicate && syndicate.id > 0 && (
                  <Grid item>
                    <Typography variant='h6'>
                      {syndicate?.horses.length > 0
                        ? 'Horses Owned'
                        : 'No Horses Owned'}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item container xs={12}>
                {syndicate?.horses &&
                  syndicate?.horses.map((horse) => (
                    <Grid
                      key={horse.id}
                      lg={6}
                      md={6}
                      xs={12}
                      container
                      direction={'row'}
                      justifyItems={'center'}
                      alignItems={'flex-start'}
                    >
                      <Grid item xs={6}>
                        {' '}
                        <Typography>{horse.name}</Typography>
                      </Grid>
                      <Grid item xs={2}>
                        {' '}
                        <Typography>{horse.procent + '%'} </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
              <Grid
                item
                container
                justifyContent={'center'}
                alignContent={'center'}
                xs={12}
              >
                {' '}
                {props.source === 2 && (
                  <Button
                    sx={{
                      bgcolor: 'primary.main',
                      textTransform: 'capitalize',
                      color: '#fff',
                      float: 'right',
                      ':hover': {
                        bgcolor: 'primary.dark',
                        color: 'white'
                      }
                    }}
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </form>
    </>
  );
};

export default SyndicateDetails;
