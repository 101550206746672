import { parse, isDate } from 'date-fns';
const moment = require('moment');

var _ = require('lodash');

export const isSignin = () => {
  const token = sessionStorage.getItem('@App:token');
  return Boolean(token);
};
export const getToken = () => {
  return sessionStorage.getItem('@App:token');
};

export const setToken = (token) => {
  return sessionStorage.setItem('@App:token', token);
};
export const Logout = () => {
  sessionStorage.removeItem('@App:token');
  sessionStorage.removeItem('@App:user');
};

export const findResult = (results, name) => {
  var result = _.find(results, function (obj) {
    return obj.types[0] === name && obj.types[1] === 'political';
  });
  return result ? result.long_name : null;
};
export const getUserDetails = () => {
  const storagedUser = sessionStorage.getItem('@App:user');
  const storagedUserObj = JSON.parse(storagedUser);
  return JSON.parse(storagedUserObj.userData);
};

export const calculate_age = (dob) => {
  var today = new Date();
  var birthDate = new Date(dob);
  var age_now = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age_now--;
  }
  return age_now;
};
export const calculate_age_by_year = (dob) => {
  var today = new Date().getFullYear();
  var birthDate = new Date(dob).getFullYear();
  var age_now = today - birthDate;
  return age_now;
};
export function parseDateString(value, originalValue) {
  const parsedDate = isDate(originalValue)
    ? originalValue
    : parse(originalValue, 'dd-MM-yyyy', new Date());

  return parsedDate;
}
export const dateFormat = (date) => {
  var dateFormat = moment(date).format('DD/MM/yyyy');
  return dateFormat;
};
export const dateTimeFormat = (date) => {
  var dateFormat = moment(date).format('MMMM D, YYYY h:mm ');

  return dateFormat;
};
export const dateTimeAMPMFormat = (date) => {
  var dateFormat = moment(date).format('MMMM D, YYYY h:mm a  ');

  return dateFormat;
};
export const dateMonthFormat = (date) => {
  var dateFormat = moment(date).format('MMMM D, YYYY  ');

  return dateFormat;
};
export const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
export const allowedProfileExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
