import paths from "./paths";
import SignIn from "../Pages/Auth/SignIn";
import ForgotPassword from "../Pages/Auth/ForgotPassword";
import UserList from "../Pages/Users/UserList";
import UserDetails from "../Pages/Users/UserDetails";
import HorseList from "../Pages/Horses/HorseList";
import HorseDetails from "../Pages/Horses/HorseDetails";
import SyndicateList from "../Pages/Syndicates/SyndicateList";
import SyndicateDetails from "../Pages/Syndicates/SyndicateDetails";
import TrackList from "../Pages/Tracks/TrackList";
import TrackDetails from "../Pages/Tracks/TrackDetails";
import MeetingList from "../Pages/Meetngs/MeetingList";
import MeetingDetails from "../Pages/Meetngs/MeetingDetails";
import RaceList from "../Pages/Races/RaceList";
import RaceDetails from "../Pages/Races/RaceDetails";
import RaceResults from "../Pages/Races/RaceResults";
import Reports from "../Pages/Reports/Reports";
import History from "../Pages/Reports/History";
import Microchip from "../Pages/Reports/Microchip";

export default {
  DEFAULT: {
    component: SignIn,
    route: paths.DEFAULT,
  },
  SIGN_IN: {
    component: SignIn,
    route: paths.SIGNIN,
  },
  FORGOT_PASSWORD: {
    component: ForgotPassword,
    route: paths.FORGOT_PASSWORD,
  },
  USER_LIST: {
    component: UserList,
    route: paths.USER_LIST,
  },
  USER_DETAILS: {
    component: UserDetails,
    route: paths.USER_DETAILS,
  },
  HORSE_LIST: {
    component: HorseList,
    route: paths.HORSE_LIST,
  },
  HORSE_DETAILS: {
    component: HorseDetails,
    route: paths.HORSE_DETAILS,
  },
  SYNDICATE_LIST: {
    component: SyndicateList,
    route: paths.SYNDICATE_LISTING,
  },
  SYNDICATE_DETAILS: {
    component: SyndicateDetails,
    route: paths.SYNDICATE_DETAILS,
  },
  TRACK_LIST: {
    component: TrackList,
    route: paths.TRACK_LIST,
  },
  TRACK_DETAILS: {
    component: TrackDetails,
    route: paths.TRACK_DETAILS,
  },
  MEETING_LIST: {
    component: MeetingList,
    route: paths.MEETING_LIST,
  },
  MEETING_DETAILS: {
    component: MeetingDetails,
    route: paths.MEETING_DETAILS,
  },
  RACE_LIST: {
    component: RaceList,
    route: paths.RACE_LIST,
  },
  RACE_DETAILS: {
    component: RaceDetails,
    route: paths.RACE_DETAILS,
  },
  RACE_RESULTS: {
    component: RaceResults,
    route: paths.RACE_RESULTS,
  },
  REPORT: {
    component: Reports,
    route: paths.REPORT,
  },
  HISTORY: {
    component: History,
    route: paths.HISTORY,
  },
  MICROCHIP: {
    component: Microchip,
    route: paths.MICROCHIP,
  },
};
