import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  CardContent,
  Grid,
  Typography,
  FormHelperText
} from '@mui/material';
import MuiTextField from '../../components/MuiTextField';
import CustomDateRangeInputs from '../../components/DateRangePicker';
import { MeetingService } from '../../services/MeetingService';
import ToastConfig from '../../components/toast/toast';
import { TrackService } from '../../services/TrackServices';
import DevAutoComplete from '../../components/DevAutoComplete';
import moment from 'moment';
const MeetingDetails = (props) => {
  const [meeting, setMeeting] = useState(null);
  const [isLoading, setIsLoading] = useState(null);

  const [records, setRecords] = useState([]);
  useEffect(() => {
    trackData();
  }, []);

  const trackData = (activeIndex) => {
    var raw = {
      TakeCount: 200,
      ActiveIndex: activeIndex,
      UserId: 0
    };
    TrackService.getPage(raw).then((response) => {
      if (response.success) {
        setRecords(response.data.data);
      }
    });
  };

  useEffect(() => {
    if (props.id) {
      loadData(props.id);
    }
  }, [props.id]);
  const loadData = (id) => {
    MeetingService.getById(id).then((response) => {
      if (response.success) {
        setMeeting(response.data);
      } else {
        props.id !== 0 && ToastConfig.error('Server Error');
      }
    });
  };
  useEffect(() => {
    if (meeting) {
      let track = {
        name: meeting?.trackName ? meeting?.trackName : '',
        id: meeting?.trackId ? meeting?.trackId : 0
      };
      setFieldValue('id', meeting?.id ? meeting?.id : 0);
      setFieldValue('name', meeting?.name ? meeting?.name : '');
      setFieldValue('trackId', track);
      setFieldValue('startDate', meeting?.startDate ? meeting.startDate : null);
      setFieldValue('endDate', meeting?.endDate ? meeting.endDate : null);
    }
  }, [meeting]);
  const validationSchema = yup.object({
    name: yup.string('Enter  name').required(' Name is required'),
    trackId: yup.string().nullable().required('Track is required'),
    startDate: yup.string().nullable().required('Select Start Date'),
    endDate: yup.string().nullable().required('Select End Date')
  });
  const {
    handleSubmit,
    handleBlur,
    handleChange,
    touched,
    values,
    errors,
    setFieldValue
  } = useFormik({
    initialValues: {
      id: 0,
      name: '',
      trackId: '',
      startDate: '',
      endDate: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setIsLoading(true)
      let data = values;
      let endDate = moment(values.endDate);
      endDate.set({ h: 11, m: 59 });
      data.endDate = endDate._d;
      MeetingService.save(data).then((response) => {
        if (response.success) {
          ToastConfig.success('Meeting successfully saved');
          props.handleClose();
          if (props.meetingUpdate) {
            props.meetingData(props.id);
          }
          setIsLoading(false)
        } else ToastConfig.error(response.statusCode);
        setIsLoading(false)
      });
    }
  });
  return (
    <>
      <form onSubmit={handleSubmit} id='MeetingAddEditForm'>
        <Grid
          container
          justifyItems={'center'}
          alignContent={'center'}
          spacing={2}
        >
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography>{'Name *'}</Typography>
                <MuiTextField
                  id='name'
                  width='50%'
                  placeholder={'Maryborough'}
                  disabled={props.source !== 2}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography>{'Track *'}</Typography>
                <DevAutoComplete
                  placeholder='Track Name'
                  options={records}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  Id={values.trackId}
                  role={'trackId'}
                />
                <FormHelperText
                  error={true}
                  style={{ display: 'inline-block', float: 'right' }}
                >
                  {errors.trackId && touched.trackId && errors.trackId}
                </FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <CustomDateRangeInputs
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  startDateLabel={'Start Date'}
                  endDateLabel={'End Date'}
                  dateData={meeting}
                  startDate={values.startDate}
                  endDate={values.endDate}
                  inputFormat='dd/MM/yyyy'
                />
              </Grid>
              <Grid
                item
                container
                justifyContent={'end'}
                alignContent={'center'}
                xs={12}
              >
                {props.source === 2 && (
                  <Button
                    sx={{
                      bgcolor: 'primary.main',
                      minWidth: '170px',
                      textTransform: 'capitalize',
                      color: '#fff',
                      float: 'right',
                      marginTop: '30px',
                      ':hover': {
                        bgcolor: 'primary.dark',
                        color: 'white'
                      }
                    }}
                    disabled={isLoading}
                    type='submit'
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Grid>
      </form>
    </>
  );
};

export default MeetingDetails;
